import * as React from "react";
import type { SVGProps } from "react";
const SvgWallet2 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={18}
    fill="none"
    {...props}
  >
    <path fill="currentColor" d="M18 9a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8.944.25h3.112c1.838 0 3.294 0 4.433.153 1.172.158 2.121.49 2.87 1.238.924.925 1.219 2.163 1.326 3.77.577.253 1.013.79 1.06 1.47.005.061.005.126.005.186v3.866c0 .06 0 .125-.004.185-.048.68-.484 1.218-1.061 1.472-.107 1.606-.402 2.844-1.326 3.769-.749.748-1.698 1.08-2.87 1.238-1.14.153-2.595.153-4.433.153H8.944c-1.838 0-3.294 0-4.433-.153-1.172-.158-2.121-.49-2.87-1.238-.748-.749-1.08-1.698-1.238-2.87C.25 12.349.25 10.894.25 9.056v-.112c0-1.838 0-3.294.153-4.433.158-1.172.49-2.121 1.238-2.87C2.39.893 3.34.561 4.511.403 5.651.25 7.106.25 8.944.25m10.224 12.5H17.23c-2.145 0-3.981-1.628-3.981-3.75s1.836-3.75 3.98-3.75h1.938c-.114-1.341-.371-2.05-.87-2.548-.423-.423-1.003-.677-2.009-.812-1.028-.138-2.382-.14-4.289-.14H9c-1.907 0-3.261.002-4.29.14-1.005.135-1.585.389-2.008.812S2.025 3.705 1.89 4.71C1.752 5.739 1.75 7.093 1.75 9s.002 3.262.14 4.29c.135 1.005.389 1.585.812 2.008s1.003.677 2.009.812c1.028.138 2.382.14 4.289.14h3c1.907 0 3.262-.002 4.29-.14 1.005-.135 1.585-.389 2.008-.812.499-.498.756-1.207.87-2.548m.756-6H17.23c-1.424 0-2.481 1.059-2.481 2.25s1.057 2.25 2.48 2.25h2.718c.206-.013.295-.152.302-.236V6.986c-.007-.084-.096-.223-.302-.235zM6 5.25a.75.75 0 0 1 .75.75v6a.75.75 0 0 1-1.5 0V6A.75.75 0 0 1 6 5.25"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgWallet2;
