const faqData = [
  {
    id: 1,
    title: "1. What is PCO Meta Earth?",
    content:
      " PCO Meta Earth (PME) is a pioneering metaverse platform built on the Polygon blockchain. It enables users to own, customize, and monetize virtual land as non-fungible tokens (NFTs). By leveraging advanced technologies such as virtual reality (VR), augmented reality (AR), artificial intelligence (AI), and blockchain, PME creates an immersive and secure digital ecosystem.",
    show: "show",
  },
  {
    id: 2,
    title: "2. How can I own virtual land in PME?",
    content:
      " Owning virtual land in PCO Meta Earth involves acquiring land parcels represented as NFTs. You can purchase these NFTs through direct transactions, auctions, or the decentralized marketplace within the platform. Once acquired, you have complete ownership and control over your virtual land.",
  },
  {
    id: 3,
    title: "3. What technologies are used in PME?",
    content: ` PCO Meta Earth integrates several cutting-edge technologies to provide a seamless and immersive experience. These include:
     <ul>
          <li>
            Blockchain (Polygon): Ensures secure and transparent transactions.
          </li>
          <li>
            Virtual Reality (VR): Offers fully immersive digital environments.
          </li>
          <li>
            Augmented Reality (AR): Enhances real-world experiences with digital
            overlays.
          </li>
          <li>
            Artificial Intelligence (AI): Powers personalized content and
            intelligent interactions.
          </li>
          <li>
            Spatial Computing: Enables natural interaction with digital objects.
          </li>
        </ul>
`,
  },
  {
    id: 4,
    title: "4. How does DAO governance work?",
    content:
      "PCO Meta Earth utilizes a decentralized autonomous organization (DAO) governance model. This empowers PME token holders to propose and vote on platform changes and enhancements. Proposals necessitate a minimum quorum of 15% of the total token supply, and submitting a proposal requires holding at least 1% of the total token supply. The standard voting period spans seven days, with significant decisions possibly requiring a supermajority for approval.",
  },
  {
    id: 5,
    title: "5. What are the monetization opportunities in PME?",
    content:
      " PME offers numerous monetization opportunities for virtual landowners. You can host events, rent out spaces, sell digital goods, and provide services within your virtual property. Additionally, PME integrates decentralized finance (DeFi) activities such as staking and yield farming, allowing users to earn rewards and generate income within the metaverse.",
  },
  {
    id: 6,
    title: "6. How do I get started with PCO Meta Earth?",
    content:
      " To get started with PCO Meta Earth, visit our website and create an account. Once registered, you can explore available virtual land, participate in auctions, and engage with the community. Stay updated by subscribing to our newsletter and following us on social media for the latest news and developments.",
  },
  {
    id: 7,
    title: "7. What are the benefits of using the Polygon blockchain?",
    content: ` The Polygon blockchain offers several benefits for PCO Meta Earth, including:

Scalability: High throughput and low latency for seamless user experiences.
Low Transaction Costs: Affordable transactions compared to other blockchains.
Security: Robust security features to protect user data and transactions.
Interoperability: Compatibility with Ethereum and other blockchains for broader reach and functionality.
`,
  },
  {
    id: 8,
    title: "8. Can I customize my virtual land in PME?",
    content:
      " Yes, you can fully customize your virtual land in PCO Meta Earth. Use a variety of tools and templates to personalize your property, create unique experiences, and attract other users. Customization options include building structures, designing landscapes, and adding interactive elements to enhance the virtual environment.",
  },
  {
    id: 9,
    title:
      "9. How does PCO Meta Earth ensure the security of my digital assets?",
    content:
      " Security is a top priority for PCO Meta Earth. By leveraging the Polygon blockchain, we ensure that all transactions are secure, transparent, and immutable. Additionally, our platform employs advanced security measures to protect user data and digital assets from unauthorized access and cyber threats.",
  },
  {
    id: 10,
    title: "10. What is the PME token, and how is it used?",
    content:
      "The PME token is the native cryptocurrency of PCO Meta Earth, used for transactions, governance, and rewards within the platform. PME tokens can be used to purchase virtual land, participate in governance votes, and earn rewards through various activities such as staking and yield farming. Holding PME tokens also grants you voting power in the DAO governance model.",
  },
];

export default faqData;
