import React, { useEffect, useRef } from "react";
import roadMapData from "../../consts/roadMapData";
import timeline from "@/scss/images/Timelinebackground.png";

const RoadMap = () => {
  const starsRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const starsCount = 1200;
    const radius = 800;
    const starsElement = starsRef.current;

    if (starsElement) {
      for (let i = 0; i < starsCount; i++) {
        const star = document.createElement("div");
        star.className = "bg-star";

        const scale = 0.2 + Math.random();
        const curR = radius + Math.random() * 300;
        const rotationY = Math.random() * 360; // Full circle horizontally
        const rotationX = Math.random() * 60 - 30; // Smaller vertical spread

        star.style.transformOrigin = `0 0 ${curR}px`;
        star.style.transform = `translate3d(0, 0, -${curR}px) rotateY(${rotationY}deg) rotateX(${rotationX}deg) scale(${scale}, ${scale})`;

        starsElement.appendChild(star);
      }
    }
  }, []);

  return (
    <section className="relative desktop:mb-[10rem] overflow-hidden" id="roadmap">
      <div ref={starsRef} className="bg-stars h-[100px]"></div>{" "}
      <span
        data-aos="zoom-in"
        data-aos-duration="900"
        className="bg-gold-700 w-[1px] absolute -translate-x-1/2 left-1/2 top-[16rem] bottom-20 desktop:bottom-[5rem] desktop:top-[25rem]"
      ></span>
      <div className="container max-w-[1300px]">
        <div className="pt-[120px] desktop:pt-[214px] text-center">
          <div data-aos="fade-up" data-aos-duration="800">
            <h3 className="text-label-lg desktop:text-subheading-md text-blackshade-200 mb-3">
              RoadMap
            </h3>
            <h4 className="text-headline-md desktop:text-display-sm text-white">
              <span className="text-gradiant">PCO</span> Time Line
            </h4>
          </div>

          <div className="relative mt-[100px]">
            <div className="flex flex-wrap items-center justify-between gap-10 desktop:gap-0">
              {roadMapData.map((item) => (
                <div
                  key={item.id}
                  className={`w-full desktop:w-[50%]  text-left last:ml-auto ${
                    item.positon === "left"
                      ? "desktop:mt-[13rem] desktop:pr-[5rem]"
                      : "desktop:mb-[13rem] desktop:pl-[5rem]"
                  }`}
                  data-aos="zoom-in"
                  data-aos-duration="800"
                >
                  <span
                    data-aos="zoom-in"
                    data-aos-duration="900"
                    className={`w-3 h-3 rounded-full bg-gold-500 shadow-dot flex mx-auto desktop:absolute desktop:top-[60%] z-10 ${
                      item.positon === "left"
                        ? "desktop:-right-[7px]"
                        : "desktop:-left-[6px]"
                    }`}
                  />
                  <div className={` ${item.style}`}>
                    <h5
                      className={`text-gold-800 text-subheading-sm desktop:text-display-sm mb-2 ${
                        item.positon === "left" ? "desktop:text-right" : ""
                      }`}
                    >
                      {item.time}
                    </h5>
                    <ul className="bg-dashboard-frame !p-4 desktop:!p-6 rounded-3xl frame-effects">
                      {item.list.map((li, idx) => (
                        <li key={idx}>
                          <h5 className="text-white text-subheading-md mb-2 desktop:mb-3">
                            {item.title}
                          </h5>
                          <div className="text-blackshade-200 text-label-md desktop:text-title-md">
                            {li.text}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))}
            </div>{" "}
          </div>
        </div>
      </div>
      <div className="mt-20 h-[280px]">
        {" "}
        <div className="drop"></div>
        <div className="drop"></div>
        <div className="drop"></div>
        <div className="drop"></div>
      </div>
      {/* <img
        src={timeline}
        alt="timeline"
        className="absolute -z-10 h-[316px] mx-auto  desktop:h-[686px] right-0 left-0 -bottom-[23rem]"
      /> */}
    </section>
  );
};

export default RoadMap;
