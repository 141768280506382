import React from "react";
import {
  Earth,
  UserRounded,
  Nft,
  WalletMoney,
  UsersGroupRounded,
  History,
  PurchasedLands,
} from "@/components/Icons";
import { MenuItem } from "./types";

const Menu: MenuItem[] = [
  { path: "/dashboard/profile", icon: <UserRounded />, title: "Profile" },
  {
    path: "/dashboard/purchased-lands",
    icon: <PurchasedLands />,
    title: "Purchased lands",
  },
  {
    path: "/dashboard/my-NFTs",
    icon: <Nft />,
    title: "My NFTs",
  },
  {
    path: "/dashboard/my-stakings",
    icon: <WalletMoney />,
    title: "My Stakings",
  },
  {
    path: "/dashboard/claimed-history",
    icon: <History />,
    title: "Claimed History",
  },
  {
    path: "/dashboard/my-referrals",
    icon: <UsersGroupRounded />,
    title: "My Referrals",
  },
];

export default Menu;
