import { useQuery } from "@tanstack/react-query";
import { api } from "@/utils/axiosInstance";
import { ENDPOINTS } from "../endpoints";
import { HexAddress } from "@/types/address";
interface NFTData {
  user_tokens: Record<string, any>;
}

const fetchNFTData = async (address: HexAddress): Promise<NFTData> => {
  const response = await api.post<NFTData>(ENDPOINTS.DASHBOARD_NFTS, {
    user_address: address,
  });
  return response.data;
};

export const useNFTDataQuery = (address: HexAddress) => {
  return useQuery<NFTData>({
    queryKey: ["nftData", address],
    queryFn: () => fetchNFTData(address),
    enabled: !!address,
  });
};
