import React from "react";
import stake from "@/scss/images/stake-money.png";
const Card: React.FC = () => {
  return (
    <div className="bg-dashboard-frame text-center shadow-frame rounded-3xl py-6 px-4 max-w-[363px] mx-auto">
      <img src={stake} alt="stake" className="mx-auto" />
      <div className="text-white text-subheading-md mb-2 -mt-5">
        Transaction is being Processed
      </div>
      <div className="text-label-md text-blackshade-200">
        Your transaction is being processed. please wait for confirmation. you
        will be noticed once the transaction is successful.
      </div>
    </div>
  );
};

export default Card;
