export const privacyPolicy = [
  {
    title: "1-Introduction PCO Meta Earth",
    items: [
      "our values your privacy and is committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and safeguard your data when you use our website and services.",
    ],
  },
  {
    title: "2-Information We Collect",
    items: [
      "<h3>Personal Information:</h3> Name, email address, contact details, and any other information you provide during registration or through our services.",
      "<h3>Usage Data:</h3> Information about how you use our website and services, including your IP address, browser type, and pages visited.",
      "<h3>Cookies:</h3> Small data files stored on your device to enhance your experience on our site. You can manage cookie preferences through your browser settings.",
    ],
  },
  {
    title: "3-How We Use Your Information",
    items: [
      "<h3>To Provide Services:</h3> To manage your account, process transactions, and offer customer support.",
      "<h3>To Improve Our Services:</h3> To analyze usage data and improve our website and offerings.",
      "<h3>To Communicate:</h3> To send you updates, newsletters, and promotional materials, with your consent.",
      "<h3>For Security:</h3> To detect and prevent fraud and ensure the security of our platform.",
    ],
  },
  {
    title: "4-Sharing Your Information",
    items: [
      "We do not sell or rent your personal information to third parties. We may share your information with:",
      "<h3>Service Providers:</h3> Trusted third parties who assist us in operating our website and services. Legal Requirements: When required by law or to protect our rights and safety.",
    ],
  },
  {
    title: "5-Data Security",
    items: [
      "We implement appropriate technical and organizational measures to protect your personal information from unauthorized access, alteration, or disclosure.",
    ],
  },
  {
    title: "6-Your Rights",
    items: [
      "You have the right to:",
      "<h3>Access:</h3> Request a copy of your personal information.",
      "<h3>Rectify:</h3> Correct any inaccurate or incomplete data.",
      "<h3>Erase:</h3> Request the deletion of your personal information.",
      "<h3>Restrict:</h3> Limit the processing of your data under certain conditions.",
      "<h3>Object:</h3> Object to the processing of your data for specific purposes.",
    ],
  },
  {
    title: "7-Changes to This Policy",
    items: [
      "We may update this Privacy Policy from time to time. We will notify you of any significant changes by posting the new policy on our website.",
    ],
  },
  {
    title: "8. Contact Us",
    items: [
      "If you have any questions or concerns about this Privacy Policy, please contact us at <a href='mailto:Info@pco.land' class='text-gold-500'>Info@pco.land.</a>",
    ],
  },
];
