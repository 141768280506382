import React from "react";
import TableMobileCard from "@/components/Common/TableMobileCard";
import { Earth, TagHorizontal } from "@/components/Icons";
import Button from "@/components/Common/Button";

interface Stake {
  stake_id: string;
  tiles_coordination: any;
  claim: any;
  image: string;
  stake_tile: string;
  remaining_time: string;
  contract_duration: string;
  claimed_count: string;
  claim_count_limit: string;
  stake_value_usd: string;
  withdrawn_amount_usd: String;
  withdrawable_final_usd: String;
}

interface MobileTableProps {
  stake?: Stake;
  claimHandler: () => void;
  isLoading?: boolean;
}
const MobileTable: React.FC<MobileTableProps> = ({
  stake,
  claimHandler,
  isLoading,
}) => {
  return (
    <TableMobileCard
      header={
        <>
          <div className="flex gap-1 items-center pl-1">
            <TagHorizontal />
            <div className="text-body-lg">ID:</div>
            {isLoading ? (
              <div className="bg-blackshade-600 rounded w-12 h-3 animate-pulse"></div>
            ) : (
              <span className="text-label-sm"> {stake?.stake_id}</span>
            )}
          </div>
          <Button
            href={
              stake?.tiles_coordination
                ? `/lands?lat=${stake.tiles_coordination[0].latitude}&lng=${stake.tiles_coordination[0].longitude}`
                : "/"
            }
            model="tonal"
            size="sm"
            disabled={isLoading}
          >
            <Earth className="mr-1 h-5 w-5" />
            Open in map
          </Button>
        </>
      }
      action={
        <Button
          model="success"
          size="sm"
          onClick={claimHandler}
          disabled={!stake?.claim || isLoading}
          className="w-full"
        >
          Claim
        </Button>
      }
    >
      <div className="flex gap-3 flex-wrap">
        {isLoading ? (
          <div className="bg-blackshade-600 rounded w-[8rem] h-16 animate-pulse"></div>
        ) : (
          <img
            src={`data:image/png;base64,${stake?.image}`}
            alt="Stake"
            className="object-fit-cover d-block rounded-2xl w-full xs:w-[45%]"
          />
        )}

        <div className="flex-1">
          <div className="flex justify-between items-center mb-2">
            <div className="text-blackshade-200 text-body-md">Type</div>
            {isLoading ? (
              <div className="bg-blackshade-600 rounded w-12 h-3 animate-pulse"></div>
            ) : (
              <div className="text-white text-body-lg">{stake?.stake_tile}</div>
            )}
          </div>
          <div className="flex justify-between items-center mb-2">
            <div className="text-blackshade-200 text-body-md">
              Remaining/Duration
            </div>
            {isLoading ? (
              <div className="bg-blackshade-600 rounded w-12 h-3 animate-pulse"></div>
            ) : (
              <div className="text-white text-body-lg">
                {stake?.remaining_time}/{stake?.contract_duration}
              </div>
            )}
          </div>
          <div className="flex justify-between items-center mb-2">
            <div className="text-blackshade-200 text-body-md">Claim Status</div>
            {isLoading ? (
              <div className="bg-blackshade-600 rounded w-12 h-3 animate-pulse"></div>
            ) : (
              <div className="text-white text-body-lg">
                {stake?.claimed_count}/{stake?.claim_count_limit}
              </div>
            )}
          </div>
          <div className="flex justify-between items-center mb-2">
            <div className="text-blackshade-200 text-body-md">Value</div>{" "}
            {isLoading ? (
              <div className="bg-blackshade-600 rounded w-12 h-3 animate-pulse"></div>
            ) : (
              <div className="text-white text-body-lg">
                {stake?.stake_value_usd}
              </div>
            )}
          </div>
          <div className="flex justify-between items-center mb-2">
            <div className="text-blackshade-200 text-body-md">Withrawn</div>{" "}
            {isLoading ? (
              <div className="bg-blackshade-600 rounded w-12 h-3 animate-pulse"></div>
            ) : (
              <div className="text-white text-body-lg">
                {stake?.withdrawn_amount_usd}
              </div>
            )}
          </div>
          <div className="flex justify-between items-center">
            <div className="text-blackshade-200 text-body-md">Withrawnable</div>{" "}
            {isLoading ? (
              <div className="bg-blackshade-600 rounded w-12 h-3 animate-pulse"></div>
            ) : (
              <div className="text-white text-body-lg">
                {stake?.withdrawable_final_usd}
              </div>
            )}
          </div>
        </div>
      </div>
    </TableMobileCard>
  );
};

export default MobileTable;
