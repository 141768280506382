import { useQuery } from "@tanstack/react-query";
import { api } from "@/utils/axiosInstance";
import { ENDPOINTS } from "../endpoints";
import { HexAddress } from "@/types/address";

interface RefereesData {
  user_subsets: string[];
}

const fetchRefereesData = async (address: HexAddress): Promise<string[]> => {
  const response = await api.post<RefereesData>(ENDPOINTS.REFEREES, {
    user_address: address,
  });
  return response.data.user_subsets;
};

export const useRefereesDataQuery = (address: HexAddress) => {
  return useQuery<string[]>({
    queryKey: ["refereesData", address],
    queryFn: () => fetchRefereesData(address),
    enabled: !!address,
  });
};
