import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, useRoutes, useLocation } from "react-router-dom";
import AppRoute from "./config/app-route";

// bootstrap
import "bootstrap";

// css
import "bootstrap-icons/font/bootstrap-icons.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "./index.css";
import "./scss/styles.scss";
// import "./index.css";
// import "./scss/main.scss";

const container = document.getElementById("root");
const root = createRoot(container);
function App() {
  let element = useRoutes(AppRoute);

  return element;
}

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
