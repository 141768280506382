import * as React from "react";
import type { SVGProps } from "react";
const SvgUncheckedBox = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    fill="none"
    {...props}
  >
    <rect
      width={18.5}
      height={18.5}
      x={10.75}
      y={10.75}
      stroke="#7D7D7D"
      strokeWidth={1.5}
      rx={7.25}
    />
  </svg>
);
export default SvgUncheckedBox;
