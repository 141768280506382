import { useEffect, useState, useRef } from "react";
import {
  Viewer,
  SceneMode,
  ScreenSpaceEventHandler,
  ScreenSpaceEventType,
  Ion,
  Math as CesiumMath,
  Cartesian3,
  ImageryLayer,
  ArcGisMapServerImageryProvider,
  ArcGisBaseMapType,
  ArcGisMapService,
  NavigationHelpButton,
} from "cesium";
import {
  checkCameraHeight,
  onGlobeLoaded,
  stopFlight,
  fillCellHandler,
  updateGrid,
  removeSelectedPolygons,
  formatCellArray,
  removeUnselectedPolygons,
  updateBounds,
} from "../helpers";

import { useLocation } from "react-router-dom";

const useMapViewer = ({
  viewerRef,
  handlerRef,
  setSelectedCells,
  selectedCells,
  clickCount,
  setClickCount,
  allTiles,
  userTiles,
  isPurchased,
  setIsPurchased,
  userOwnedTiles,
  setUserOwnedTiles,
  setCoordinates,
  isLoading,
  setSelectedCellsHistory,
  helpButtonRef,
}) => {
  const [isFilledTiledLoaded, setIsFieldTiledLoaded] = useState(false);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const lat = params.get("lat");
  const lng = params.get("lng");

  Ion.defaultAccessToken = process.env.REACT_APP_CESIUM_KEY;
  ArcGisMapService.defaultAccessToken = process.env.REACT_APP_ARCGIS_KEY;
  const arcGisImagery = ArcGisMapServerImageryProvider.fromBasemapType(
    ArcGisBaseMapType.SATELLITE
  );

  useEffect(() => {
    const viewer = new Viewer("cesiumContainer", {
      sceneModePicker: false,
      baseLayerPicker: false,
      geocoder: false,
      timeline: false,
      fullscreenButton: false,
      infoBox: false,
      selectionIndicator: false,
      contextOptions: {
        requestWebgl2: true,
      },
      imageryProvider: false,
      sceneMode: SceneMode.SCENE3D,
      baseLayer: ImageryLayer.fromProviderAsync(arcGisImagery),
      navigationHelpButton: true,
    });
    const startLatitude = -49.402488;
    const endLatitude = -49.392488;
    const startLongitude = 69.556083;
    const endLongitude = 69.566083;
    const newHomePosition = {
      destination: Cartesian3.fromDegrees(
        (startLongitude + endLongitude) / 2,
        (startLatitude + endLatitude) / 2,
        100000
      ), // Specify the destination with altitude
      // duration: 8.0, // Duration of the flight animation in seconds
      orientation: {
        heading: 0.0,
        pitch: CesiumMath.toRadians(-90.0),
        roll: 0.0,
      },
    };
    viewerRef.current = viewer;
    handlerRef.current = new ScreenSpaceEventHandler(viewer.scene.canvas);
    // Set the new home button command
    viewer.homeButton.viewModel.command.beforeExecute.addEventListener(
      function (commandInfo) {
        viewer.scene.camera.flyTo(newHomePosition);
        commandInfo.cancel = true; // Cancel the default behavior
      }
    );
    viewer.scene.verticalExaggeration = 3.0;
    viewer.camera.moveEnd.addEventListener(() => {
      checkCameraHeight(viewer);
    });
    viewer.scene.postProcessStages.fxaa.enabled = true;

    viewerRef.current = viewer;
    handlerRef.current = new ScreenSpaceEventHandler(viewer.scene.canvas);

    const handler = handlerRef.current;

    handler.setInputAction(() => {
      stopFlight(viewer);
    }, ScreenSpaceEventType.LEFT_DOWN);

    handler.setInputAction(() => {
      stopFlight(viewer);
    }, ScreenSpaceEventType.MIDDLE_DOWN);

    handler.setInputAction(() => {
      stopFlight(viewer);
    }, ScreenSpaceEventType.RIGHT_DOWN);

    handler.setInputAction(() => {
      stopFlight(viewer);
    }, ScreenSpaceEventType.WHEEL);

    viewer.scene.canvas.addEventListener(
      "touchstart",
      (event) => {
        event.preventDefault();
      },
      { passive: false }
    );

    // Disable rotation but allow zooming with two-finger pinc
    // viewer.scene.screenSpaceCameraController.enableTilt = false; // Disable tilt if not needed

    return () => {
      if (viewerRef.current && !viewerRef.current.isDestroyed()) {
        viewerRef.current.destroy();
      }
    };
  }, []);

  useEffect(() => {
    if (viewerRef.current) {
      const viewer = viewerRef.current;
      const handler = handlerRef.current;
      if (isLoading || !isFilledTiledLoaded) {
        handler.removeInputAction(ScreenSpaceEventType.LEFT_CLICK);
      } else {
        handler.setInputAction((movement) => {
          fillCellHandler({
            position: movement.position,
            viewer: viewerRef.current,
            selectedCells,
            setSelectedCells,
            userOwnedTiles,
            setSelectedCellsHistory,
            clickCount,
            setClickCount,
          });
        }, ScreenSpaceEventType.LEFT_CLICK);
      }

      if (selectedCells.length === 0) {
        removeSelectedPolygons(viewer, selectedCells);
      }
    }
  }, [selectedCells, userOwnedTiles, isLoading, isFilledTiledLoaded]);
  useEffect(() => {
    if (viewerRef.current) {
      const viewer = viewerRef.current;
      viewer.camera.changed.addEventListener(() => {
        updateGrid({
          viewer,
        });
      });
    }
  }, [selectedCells, userOwnedTiles]);

  useEffect(() => {
    if (viewerRef.current) {
      const viewer = viewerRef.current;
      viewer.camera.changed.addEventListener(() => {
        if (viewer.camera.positionCartographic.height < 2000) {
          const cameraExtent = viewer.camera.computeViewRectangle();
          setCoordinates(updateBounds(cameraExtent));
        }
      });
    }
  }, [allTiles]);

  const isInitialLoad = useRef(true);
  const isProcessing = useRef(false);

  // Custom debounce function
  function debounce(fn, delay) {
    let timeoutId;
    return function (...args) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        fn.apply(this, args);
      }, delay);
    };
  }

  useEffect(() => {
    const viewer = viewerRef.current;

    if (!viewer) return; // Early exit if viewer is not initialized

    // Define the debounced handler
    const handleCameraMoveEnd = debounce(() => {
      if (isProcessing.current) {
        return;
      }
      isProcessing.current = true;

      try {
        const cameraHeight = viewer.camera.positionCartographic.height;

        if (cameraHeight > 10000) {
          viewer.entities.removeAll();
          // viewer.scene.primitives.removeAll();
          setUserOwnedTiles([]);
        }
      } catch (error) {
        console.error("Error in handleCameraMoveEnd:", error);
      } finally {
        isProcessing.current = false;
      }
    }, 300); // debounce with 300ms delay

    // Add event listener for camera move end
    viewer.camera.moveEnd.addEventListener(handleCameraMoveEnd);

    // Initial load handling
    if (isInitialLoad.current && allTiles) {
      try {
        const cameraHeight = viewer.camera.positionCartographic.height;
        if (cameraHeight < 10000) {
          removeUnselectedPolygons(viewer, selectedCells);
          // formatCellArray({
          //   viewer,
          //   tilesData: { userTiles, allTiles },
          //   setUserOwnedTiles,
          //   setSelectedCells,
          //   selectedCells,
          //   userOwnedTiles,
          // });
        }
      } catch (error) {
        console.error("Error during initial load:", error);
      } finally {
        isInitialLoad.current = false;
      }
    }
  }, [allTiles, userTiles, selectedCells, userOwnedTiles]);
  useEffect(() => {
    const viewer = viewerRef.current;

    if (viewer && viewer.canvas) {
      if (isLoading) {
        viewer.canvas.style.cursor = "wait";
      } else {
        viewer.canvas.style.cursor = "grab";
      }
    }
  }, [isLoading]);
  useEffect(() => {
    const viewer = viewerRef.current;

    if (!viewer || !allTiles) return; // Early exit if viewer is not initialized or allTiles is empty

    // Ensure this function is debounced to avoid performance issues
    const handleTilesUpdate = debounce(() => {
      setIsFieldTiledLoaded(false);

      formatCellArray({
        viewer,
        tilesData: { userTiles, allTiles },
        setUserOwnedTiles,
        setSelectedCells,
        selectedCells,
        userOwnedTiles,
        setIsFieldTiledLoaded,
      });
      // updateGrid({
      //   viewer,
      //   selectedCells,
      //   setSelectedCells,
      //   userOwnedTiles,
      //   setUserOwnedTiles,
      //   tilesData: { userTiles, allTiles },
      // });
    }, 300); // Adjust debounce delay as needed

    handleTilesUpdate(); // Call the debounced function
  }, [allTiles]);
  useEffect(() => {
    if (viewerRef.current) {
      const viewer = viewerRef.current;
      let initialLoadComplete = false;
      viewer.scene.globe?.tileLoadProgressEvent.addEventListener(function (
        remainingTiles
      ) {
        if (!initialLoadComplete && remainingTiles === 0) {
          initialLoadComplete = true;
          if (lat && lng) {
            viewer.camera.flyTo({
              destination: Cartesian3.fromDegrees(
                parseFloat(lng),
                parseFloat(lat),
                700
              ), // Adjust altitude as needed
              orientation: {
                heading: 0.0,
                pitch: CesiumMath.toRadians(-90.0),
                roll: 0.0,
              },
              duration: 3.0, // Duration of the flight
            });
          } else {
            onGlobeLoaded(viewer);
          }
        }
      });
    }
  }, [lat, lng]);

  useEffect(() => {
    if (isPurchased) {
      if (viewerRef.current) {
        const viewer = viewerRef.current;
        if (!viewer.isDestroyed()) {
          removeSelectedPolygons(viewer, selectedCells);
          setSelectedCells([]);
          setIsPurchased(false);
        }
      }
    }
  }, [isPurchased]);

  // useEffect(() => {
  //   if (coordinates) getAllTileData(coordinates);
  // }, [location.search, coordinates]);

  useEffect(() => {
    if (viewerRef.current) {
      const viewer = viewerRef.current;

      // viewer.entities.removeAll();
      // viewer.scene.primitives.removeAll();
      if (!viewer.isDestroyed()) {
        setUserOwnedTiles([]);
        setSelectedCells([]);
        viewer.entities.removeAll();
        // viewer.scene.primitives.removeAll();
      }
    }
  }, [location.search]);
};

export default useMapViewer;
