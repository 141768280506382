import * as React from "react";
import type { SVGProps } from "react";
const SvgPlate = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={19}
    fill="none"
    {...props}
  >
    <path fill="currentColor" d="M11.75 1a.75.75 0 0 0-1.5 0v2a.75.75 0 0 0 1.5 0z" />
    <path
      fill="currentColor"
      d="M8.002 2.75a.75.75 0 0 0-.004-1.5c-1.547.005-2.8.03-3.807.202-1.028.174-1.872.511-2.55 1.19C.893 3.39.561 4.338.403 5.51.25 6.651.25 8.106.25 9.944v.112c0 1.838 0 3.294.153 4.433.158 1.172.49 2.121 1.238 2.87.749.748 1.698 1.08 2.87 1.238 1.14.153 2.595.153 4.432.153h4.114c1.837 0 3.293 0 4.432-.153 1.172-.158 2.121-.49 2.87-1.238.748-.749 1.08-1.698 1.238-2.87.153-1.14.153-2.595.153-4.433v-.112c0-1.838 0-3.294-.153-4.433-.158-1.172-.49-2.121-1.238-2.87-.678-.678-1.522-1.015-2.55-1.19-1.006-.17-2.26-.196-3.807-.2a.75.75 0 0 0-.004 1.5c1.567.005 2.696.033 3.56.18.842.142 1.355.386 1.74.77.423.424.677 1.004.812 2.01.138 1.028.14 2.382.14 4.289s-.002 3.262-.14 4.29c-.135 1.005-.389 1.585-.812 2.008s-1.003.677-2.009.812c-1.027.138-2.382.14-4.289.14H9c-1.907 0-3.261-.002-4.29-.14-1.005-.135-1.585-.389-2.008-.812s-.677-1.003-.812-2.009c-.138-1.027-.14-2.382-.14-4.289s.002-3.261.14-4.29c.135-1.005.389-1.585.812-2.008.385-.385.898-.629 1.74-.772.864-.146 1.993-.174 3.56-.18"
    />
    <path
      fill="currentColor"
      d="M6.25 8.5A.75.75 0 0 1 7 7.75h8a.75.75 0 0 1 0 1.5H7a.75.75 0 0 1-.75-.75M7 11.25a.75.75 0 0 0 0 1.5h5.5a.75.75 0 0 0 0-1.5z"
    />
  </svg>
);
export default SvgPlate;
