import * as React from "react";
import type { SVGProps } from "react";
const SvgOrbit = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="285"
    height="285"
    viewBox="0 0 285 285"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      cx="145.438"
      cy="145.438"
      r="97.9375"
      stroke="url(#paint0_linear_428_4159)"
    />
    <circle
      cx="145.438"
      cy="145.438"
      r="60.3125"
      stroke="url(#paint1_linear_428_4159)"
    />
    <circle cx="145" cy="145" r="139.5" stroke="url(#paint2_linear_428_4159)" />
    <g filter="url(#filter0_d_428_4159)">
      <circle cx="269.113" cy="82.4468" r="1.98582" fill="#5C5C5C" />
    </g>
    <g filter="url(#filter1_d_428_4159)">
      <circle cx="153.937" cy="47.6948" r="1.98582" fill="#5C5C5C" />
    </g>
    <g filter="url(#filter2_d_428_4159)">
      <circle cx="41.1108" cy="51.6108" r="1.98582" fill="#5C5C5C" />
    </g>
    <g filter="url(#filter3_d_428_4159)" className="flashing">
      <circle cx="209.25" cy="22" r="2" fill="currentColor" />
    </g>
    <g filter="url(#filter4_d_428_4159)" className="flashing">
      <circle cx="244.25" cy="137.5" r="2" fill="currentColor" />
    </g>
    <g filter="url(#filter5_d_428_4159)" className="flashing">
      <circle cx="110.375" cy="98.125" r="2" fill="currentColor" />
    </g>
    <g filter="url(#filter6_d_428_4159)" className="flashing">
      <circle cx="117.375" cy="8" r="2" fill="currentColor" />
    </g>
    <g filter="url(#filter7_d_428_4159)" className="flashing">
      <circle cx="8" cy="121.75" r="2" fill="currentColor" />
    </g>
    {/* <path
          d="M155.744 139.744C156.525 138.963 156.525 137.697 155.744 136.916C154.963 136.135 153.697 136.135 152.916 136.916L140.997 148.835L137.078 144.916C136.297 144.135 135.03 144.135 134.249 144.916C133.468 145.697 133.468 146.963 134.249 147.744L139.583 153.078C140.364 153.859 141.63 153.859 142.411 153.078L155.744 139.744Z"
          fill="#121212"
        />
        <path
          d="M155.744 139.744C156.525 138.963 156.525 137.697 155.744 136.916C154.963 136.135 153.697 136.135 152.916 136.916L140.997 148.835L137.078 144.916C136.297 144.135 135.03 144.135 134.249 144.916C133.468 145.697 133.468 146.963 134.249 147.744L139.583 153.078C140.364 153.859 141.63 153.859 142.411 153.078L155.744 139.744Z"
          fill="currentColor"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M144.997 116.33C129.165 116.33 116.33 129.165 116.33 144.997C116.33 160.829 129.165 173.663 144.997 173.663C160.829 173.663 173.663 160.829 173.663 144.997C173.663 129.165 160.829 116.33 144.997 116.33ZM120.33 144.997C120.33 131.374 131.374 120.33 144.997 120.33C158.62 120.33 169.663 131.374 169.663 144.997C169.663 158.62 158.62 169.663 144.997 169.663C131.374 169.663 120.33 158.62 120.33 144.997Z"
          fill="#121212"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M144.997 116.33C129.165 116.33 116.33 129.165 116.33 144.997C116.33 160.829 129.165 173.663 144.997 173.663C160.829 173.663 173.663 160.829 173.663 144.997C173.663 129.165 160.829 116.33 144.997 116.33ZM120.33 144.997C120.33 131.374 131.374 120.33 144.997 120.33C158.62 120.33 169.663 131.374 169.663 144.997C169.663 158.62 158.62 169.663 144.997 169.663C131.374 169.663 120.33 158.62 120.33 144.997Z"
          fill="currentColor"
        /> */}
    <defs>
      <filter
        id="filter0_d_428_4159"
        x="261.627"
        y="74.9609"
        width="14.9717"
        height="14.9717"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2.75" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.36 0 0 0 0 0.36 0 0 0 0 0.36 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_428_4159"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_428_4159"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_428_4159"
        x="146.451"
        y="40.209"
        width="14.9717"
        height="14.9717"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2.75" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.36 0 0 0 0 0.36 0 0 0 0 0.36 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_428_4159"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_428_4159"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_d_428_4159"
        x="33.625"
        y="44.125"
        width="14.9717"
        height="14.9717"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2.75" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.36 0 0 0 0 0.36 0 0 0 0 0.36 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_428_4159"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_428_4159"
          result="shape"
        />
      </filter>
      <filter
        id="filter3_d_428_4159"
        x="201.75"
        y="14.5"
        width="15"
        height="15"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2.75" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0901961 0 0 0 0 0.788235 0 0 0 0 0.392157 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_428_4159"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_428_4159"
          result="shape"
        />
      </filter>
      <filter
        id="filter4_d_428_4159"
        x="236.75"
        y="130"
        width="15"
        height="15"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2.75" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0901961 0 0 0 0 0.788235 0 0 0 0 0.392157 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_428_4159"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_428_4159"
          result="shape"
        />
      </filter>
      <filter
        id="filter5_d_428_4159"
        x="102.875"
        y="90.625"
        width="15"
        height="15"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2.75" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0901961 0 0 0 0 0.788235 0 0 0 0 0.392157 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_428_4159"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_428_4159"
          result="shape"
        />
      </filter>
      <filter
        id="filter6_d_428_4159"
        x="109.875"
        y="0.5"
        width="15"
        height="15"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2.75" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0901961 0 0 0 0 0.788235 0 0 0 0 0.392157 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_428_4159"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_428_4159"
          result="shape"
        />
      </filter>
      <filter
        id="filter7_d_428_4159"
        x="0.5"
        y="114.25"
        width="15"
        height="15"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2.75" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0901961 0 0 0 0 0.788235 0 0 0 0 0.392157 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_428_4159"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_428_4159"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_428_4159"
        x1="145.438"
        y1="47"
        x2="145.438"
        y2="243.875"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#424242" />
        <stop offset="1" stopColor="#262626" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_428_4159"
        x1="145.438"
        y1="84.625"
        x2="145.438"
        y2="206.25"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#424242" />
        <stop offset="1" stopColor="#262626" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_428_4159"
        x1="145"
        y1="5"
        x2="145"
        y2="285"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#424242" />
        <stop offset="1" stopColor="#262626" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgOrbit;
