import React from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Content from "./Content";
import Footer from "./Footer";
import ProtectedLayout from "../ProtectedLayout";
import { Land } from "@/components/Icons";
import { useIsMobile } from "@/hooks/useIsMobile";

const DashbaordLayout = () => {
  const isMobile = useIsMobile(1300);

  return (
    <ProtectedLayout>
      <div className="flex w-full relative overflow-x-hidden">
        <Land className="absolute left-0 -z-10 bottom-0 mt-auto" />
        <Sidebar />
        <div className="flex-1">
          <Header />
          <Content />
          {isMobile && <Footer />}
        </div>
      </div>
    </ProtectedLayout>
  );
};

export default DashbaordLayout;
