import { useEffect } from "react";
import { toast } from "react-toastify";
import { stakeNFTAbi } from "@/utils/contractConstants";
import { useWriteContract, useAccount } from "wagmi";
import BaseModal from "@/components/Common/BaseModal";
import { WalletMoney } from "@/components/Icons";
import Button from "@/components/Common/Button";
import { useToast } from "@/components/Common/Toast/utils";

const ClaimModal = ({ closeModalHandler, stakeDetails, setRefetch }) => {
  const { address } = useAccount();
  const claim = useWriteContract();
  const { addToast } = useToast();

  const claimHandler = async () => {
    await claim.writeContractAsync({
      address: process.env.REACT_APP_STAKE_NFT_CONTRACT_ADDRESS,
      abi: stakeNFTAbi,
      functionName: "claim",
      args: [stakeDetails.stake_id],
    });
  };
  useEffect(() => {
    if (claim.error) {
      addToast("danger", claim.error?.cause?.reason || "Claim failed.");
    }
  }, [claim.error]);
  useEffect(() => {
    if (claim.isSuccess) {
      addToast("success", "You Claimed Successfully");
      setRefetch(true);
      closeModalHandler();
    }
  }, [claim.isSuccess]);

  const list = [
    { title: "Title", info: stakeDetails.stake_tile },
    { title: "Fee", info: `$${stakeDetails.withdrawable_fee_usd}` },
    { title: "Stake Value", info: `$${stakeDetails.stake_value_usd}` },
    { title: "PMG Profit", info: stakeDetails.withdrawable_final_pmg },
    { title: "PME Profit", info: stakeDetails.withdrawable_final_pme },
    {
      title: "Claims",
      info: `${stakeDetails.claimed_count}/${stakeDetails.claim_count_limit} used`,
    },
  ];
  return (
    <BaseModal
      title="Claim Your Stake"
      footer={
        <div className="flex gap-4 flex-wrap w-full justify-end">
          <Button
            model="main"
            size="sm"
            className="!w-full desktop:!w-max"
            disabled={claim.isPending || !stakeDetails.claim}
            onClick={claimHandler}
            isLoading={claim.isPending}
          >
            Claim
          </Button>
        </div>
      }
      closeModalHandler={closeModalHandler}
    >
      <div className="bg-gold-500 rounded-[20px] p-3 text-blackshade-900">
        <div className="flex items-center gap-1 mb-2">
          <WalletMoney className="w-5 h-5" />
          <span className="text-body-lg md:text-label-lg">Wallet Address</span>
        </div>
        <div className="text-label-lg md:text-title-lg">
          {address.slice(0, 7)}...{address.slice(-7)}
        </div>
      </div>
      <div className="flex flex-col gap-2 mt-4">
        {list.map((item, index) => (
          <div
            key={index}
            className="p-3 bg-dashboard-frame h-10 flex justify-between items-center rounded-[20px]"
          >
            <div className="text-body-md md:text-label-md text-blackshade-200">
              {item.title}
            </div>
            <div className="text-label-sm md:text-label-lg text-white">
              {item.info}
            </div>
          </div>
        ))}
      </div>
    </BaseModal>
  );
};

export default ClaimModal;
