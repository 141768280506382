export const ENDPOINTS = {
  SUBSCRIPTION_EMAIL: "/subscription_email/",
  CHECK_USER: "/check_user/",
  VERIFY: "/verify/",
  DASHBOARD: "/dashboard/",
  DASHBOARD_NFTS: "/dashboard_nfts/",
  DASHBOARD_LANDS: "/dashboard_lands/",
  REFEREES: "/all_user_subsets/",
  LAND_TYPES: "/land_types/",
};
