import React from "react";
import { useIsMobile } from "@/hooks/useIsMobile";
import BuyLandWrapper from "@/components/Pages/Public/BuyLand";

const Lands: React.FC = () => {
  const isMobile = useIsMobile(1100);
  return (
    <div className={isMobile ? "mobile" : ""}>
      <BuyLandWrapper />
    </div>
  );
};

export default Lands;
