import React, { useState } from "react";
import Accordion from "./accordion-item";
import faqData from "../../consts/faqData";

const FAQ = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const handleToggle = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  return (
    <section className="mt-[9rem] desktop:mt-[25rem] text-center overflow-hidden" id="faq">
      <div className="container max-w-[880px]">
        <div data-aos="fade-up" data-aos-duration="800">
          <h3 className="text-label-md desktop:text-subheading-md text-blackshade-200 mb-3">FAQ</h3>
          <h4 className="text-headline-md desktop:text-display-sm text-white">
            Looking For <span className="text-gradiant">Answers</span> ?
          </h4>
        </div>
        <div
          className="mt-14 faq-accordion"
          data-aos="fade-up"
          data-aos-duration="800"
        >
          {faqData.map((accordion, index) => (
            <Accordion
              key={index}
              header={accordion.title}
              isOpen={openIndex === index}
              onToggle={() => handleToggle(index)}
              content={accordion.content}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQ;
