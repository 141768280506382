import React from "react";
import { User } from "@/types/user";
import Card from "./Card";
import Banner from "./Banner";
import Input from "@/components/Common/Input";
import {
  Banknote,
  Earth,
  Infinity,
  Letter,
  Plate,
  UserRounded,
  Wallet2,
  Copy,
} from "@/components/Icons";
import { copyToClipboard } from "@/utils/copyToClipboard.js";
import { motion } from "framer-motion";

interface ProfileFormProps {
  user: User;
  PMBValue: string;
  PMEValue: string;
  PMGValue: string;
}

const Container: React.FC<ProfileFormProps> = ({
  user,
  PMBValue,
  PMEValue,
  PMGValue,
}) => {
  const cardList = [
    {
      title: "PURCHASED TILES",
      icon: <Banknote />,
      text: `${user.num_active_tiles} TILES`,
    },
    {
      title: "Total Lands",
      icon: <Earth />,
      text: `${user.num_active_lands} Lands`,
    },
    { title: "PME Balance", icon: <Infinity />, text: PMEValue },
    { title: "PMG Balance", icon: <Wallet2 />, text: PMGValue },
  ];
  return (
    <div className="dashboard-profile">
      <Banner id={user.user_id} />
      <motion.div
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.6, delay: 1 }}
      >
        <div className="grid grid-cols-12 gap-3 px-3 md:px-6">
          {cardList.map((item, index) => (
            <Card key={index} item={item} />
          ))}
        </div>
      </motion.div>
      <motion.div
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.6, delay: 1.2 }}
      >
        {" "}
        <div className="mt-8 dashboard-card">
          <div className="w-full md:w-1/2 xl:w-1/3">
            <div className="text-subheading-md mb-6">User Information</div>
            <div className="mb-12">
              <Input
                label="Username"
                inputClassName="mb-6"
                defaultValue={user.username}
                readOnly
                disabled
                prefixIcon={<UserRounded />}
                model="lg"
              />
              <Input
                label="Email Address"
                defaultValue={user.email}
                readOnly
                disabled
                prefixIcon={<Letter />}
                model="lg"
              />
            </div>
            <div className="text-subheading-md mb-6">Referrer Code</div>
            <Input
              defaultValue={user.referral_id}
              readOnly
              disabled
              prefixIcon={<Plate />}
              suffixIcon={
                <div
                  className="text-gold-500 cursor-pointer"
                  onClick={() => copyToClipboard(user.referral_id)}
                >
                  <Copy />
                </div>
              }
              model="lg"
            />
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default Container;
