import { AltArrowDown } from "@/components/Icons";
import React, { ReactNode, useState, useRef, useEffect } from "react";

interface AccordionProps {
  header: string | ReactNode;
  children: ReactNode;
}

const Accordion: React.FC<AccordionProps> = ({ header, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [maxHeight, setMaxHeight] = useState("0px");
  const contentRef = useRef<HTMLDivElement>(null);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    // Dynamically update max-height based on content height for smooth transition
    if (contentRef.current) {
      setMaxHeight(isOpen ? `${contentRef.current.scrollHeight}px` : "0px");
    }
  }, [isOpen]);

  return (
    <div className="rounded-lg">
      {/* Header */}
      <button
        onClick={toggleAccordion}
        className="w-full transition-colors relative pr-8"
      >
        {header}
        <div className="absolute right-0 top-0 p-2">
          <AltArrowDown
          className={`text-gold-500 transition-all ${
            isOpen ? "rotate-180" : "rotate-0"
          }`}
        />
        </div>
        
      </button>

      {/* Content with Smooth Transition */}
      <div
        ref={contentRef}
        style={{
          maxHeight,
        }}
        className="overflow-hidden transition-max-height duration-300 ease-in-out"
      >
        <div className="">{children}</div>
      </div>
    </div>
  );
};

export default Accordion;
