import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAccount } from "wagmi";
import { api } from "@/utils/axiosInstance.js";
import Modal from "./components/Modal";
import LandTable from "./components/LandTable";
import { useToast } from "@/components/Common/Toast/utils";

const PurchasedLands = () => {
  const [userTiles, setUserTiles] = useState([]);
  const [landDetails, setLandDetails] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const { pathname } = useLocation();
  const { address } = useAccount();
  const [isLoading, setIsLoading] = useState(true);
  const { addToast } = useToast();

  const getUserTile = async () => {
    setIsLoading(true);
    try {
      const response = await api.post("/dashboard_lands/", {
        user_address: address,
      });
      setUserTiles(response.data.user_active_lands ?? []);
    } catch (error) {
      console.error("Error getting lands", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getUserTile();
  }, [pathname]);

  const ComingSoonMessage = () => {
    addToast("info", "This feature is coming soon! Stay tuned for updates.");
  };
  const handleConvertToNFT = (
    tilesCoordination,
    land_id,
    purchasepricetoken,
    token_type,
    approve_token
  ) => {
    setLandDetails({
      tilesCoordination,
      land_id,
      purchasepricetoken,
      token_type,
      approve_token,
    });
    setOpenModal(true);
  };
  const closeModalHandler = () => {
    setOpenModal(false);
  };
  return (
    <>
      <LandTable
        userTiles={userTiles}
        isLoading={isLoading}
        handleConvertToNFT={handleConvertToNFT}
        ComingSoonMessage={ComingSoonMessage}
      />
      {openModal && (
        <Modal
          landDetails={landDetails}
          closeModalHandler={closeModalHandler}
        />
      )}
    </>
  );
};

export default PurchasedLands;
