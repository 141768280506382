import React, { ReactNode, useState, useRef, useEffect } from "react";
import { AltArrowDown } from "@/components/Icons";
import circleLine from "@/scss/images/circle-lined.png";

interface AccordionProps {
  header: string | ReactNode;
  isOpen: boolean;
  onToggle: () => void;
  content: string;
}

const Accordion: React.FC<AccordionProps> = ({
  header,
  isOpen,
  onToggle,
  content,
}) => {
  const [maxHeight, setMaxHeight] = useState("0px");
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (contentRef.current) {
      setMaxHeight(isOpen ? `${contentRef.current.scrollHeight}px` : "0px");
    }
  }, [isOpen]);

  return (
    <div
      className={`${
        isOpen
          ? "bg-dashboard-frame frame-effects rounded-3xl border border-solid !border-gold-500"
          : "gradient-border-box"
      } text-left  mb-6`}
    >
      {isOpen ? (
        <>
          {" "}
          <img
            src={circleLine}
            alt=""
            className="absolute rotate-180 -right-[22rem] desktop:-right-[50%] top-1/2 -translate-y-1/2"
          />
          <img
            src={circleLine}
            alt=""
            className="absolute -left-[22rem] desktop:-left-[50%] top-1/2 -translate-y-1/2"
          />
        </>
      ) : (
        ""
      )}
      <button
        onClick={onToggle}
        className={`w-full transition-colors relative p-4 text-left text-title-lg desktop:text-subheading-md text-white flex justify-between items-center`}
      >
        {header}
        <div className=" p-2">
          <AltArrowDown
            className={`text-gold-500 transition-all ${
              isOpen ? "rotate-180" : "rotate-0"
            }`}
          />
        </div>
      </button>
      <div
        ref={contentRef}
        style={{
          maxHeight,
        }}
        className={`overflow-hidden transition-max-height duration-300 ease-in-out`}
      >
        <div
          dangerouslySetInnerHTML={{ __html: content }}
          className="px-4 pb-4 text-label-md desktop:text-title-md text-blackshade-200 "
        ></div>
      </div>
    </div>
  );
};

export default Accordion;
