import * as React from "react";
import type { SVGProps } from "react";
const SvgPurchasedLands = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M16.967 15.082v-3.915H24.7a.97.97 0 0 0 .967-.967V3.433a.97.97 0 0 0-.967-.966h-7.733A.97.97 0 0 0 16 1.5a.97.97 0 0 0-.967.967v12.615C7.493 15.584 1.5 21.868 1.5 29.533a.97.97 0 0 0 .967.967h27.066a.97.97 0 0 0 .967-.967c0-7.665-5.993-13.949-13.533-14.451m10.217 8.738c-1.237-.531-2.648-.966-4.176-1.295-.328-1.527-.763-2.948-1.295-4.186a12.65 12.65 0 0 1 5.471 5.481M16 21.8a35.5 35.5 0 0 0-4.95.358c.474-1.895 1.132-3.548 1.934-4.814A12.4 12.4 0 0 1 16 16.967c1.044 0 2.05.145 3.016.377.812 1.276 1.46 2.92 1.933 4.814A34 34 0 0 0 16 21.8m5.365 2.368a32 32 0 0 1 .406 4.399H10.23c.039-1.528.174-3.016.406-4.399 1.682-.28 3.49-.435 5.365-.435s3.683.155 5.365.435M23.733 4.4v4.833h-6.766V4.4zM10.297 18.34C9.765 19.575 9.33 20.996 9 22.524c-1.527.328-2.938.764-4.176 1.295a12.7 12.7 0 0 1 5.472-5.48M3.81 26.516c1.276-.812 2.92-1.469 4.814-1.933-.184 1.276-.3 2.61-.338 3.983H3.472a13 13 0 0 1 .338-2.05m19.894 2.05a33 33 0 0 0-.338-3.983c1.904.474 3.547 1.131 4.814 1.933.164.667.29 1.344.338 2.05h-4.824z"
    />
  </svg>
);
export default SvgPurchasedLands;
