import { useState, useRef } from "react";
import { api } from "@/utils/axiosInstance.js";
import { useAccount } from "wagmi";
import { useLandStore } from "../store";

export const useAllTilesData = () => {
  const [isLoading, setIsLoading] = useState(false);
  const abortControllerRef = useRef(new AbortController());
  const { address } = useAccount();
  const { setUserTiles, setAllTile } = useLandStore();

  const getAllTileData = async (coordinates) => {
    setIsLoading(true);

    // Cancel the previous request
    abortControllerRef.current.abort();

    // Create a new AbortController for the current request
    abortControllerRef.current = new AbortController();
    const { signal } = abortControllerRef.current;

    try {
      const response = await api.post(
        "/all_active_lands/",
        {
          user_address: address,
          ...coordinates,
        },
        { signal }
      );
      setIsLoading(false);

      setAllTile(response.data.all_active_tiles ?? []);
      const result = response.data.user_active_lands
        .map((item) =>
          item.tiles_coordination.map((coord) => ({
            latitude: coord.latitude,
            longitude: coord.longitude,
            is_staked: item.is_staked,
            is_nft: item.is_nft,
          }))
        )
        .flat();
      setUserTiles(result);
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("Request canceled:", error.message);
      } else {
        console.error("Error getting lands", error);
      }
    } finally {
    }
  };

  return { isLoading, getAllTileData };
};
