import * as React from "react";
import type { SVGProps } from "react";
const SvgStarsRoad = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={1440}
    height={886}
    fill="none"
    {...props}
  >
    <g clipPath="url(#Stars-road_svg__a)">
      <circle cx={439.243} cy={873.513} r={1.724} fill="#5C5C5C" />
      <circle cx={185.743} cy={730.946} r={1.724} fill="#242424" />
      <circle cx={73.763} cy={440.235} r={1.724} fill="#5C5C5C" />
      <circle cx={1188.51} cy={760.979} r={1.724} fill="#ABABAB" />
      <circle cx={758.864} cy={786.905} r={1.724} fill="#2E2E2E" />
      <circle cx={1293.65} cy={325.501} r={1.724} fill="#5C5C5C" />
      <circle cx={871.329} cy={432.374} r={1.724} fill="#242424" />
      <circle cx={453.149} cy={212.661} r={1.724} fill="#5C5C5C" />
      <circle cx={24.407} cy={611.464} r={1.724} fill="#5C5C5C" />
      <circle cx={1187.32} cy={94.374} r={1.724} fill="#5C5C5C" />
      <circle cx={1140.55} cy={322.146} r={1.724} fill="#5C5C5C" />
      <circle cx={952.103} cy={181.442} r={1.724} fill="#2E2E2E" />
      <circle cx={280.411} cy={632.937} r={1.724} fill="#5C5C5C" />
      <circle cx={356.728} cy={713.755} r={1.724} fill="#7D7D7D" />
      <circle cx={771.419} cy={147.423} r={1.724} fill="#5C5C5C" />
      <circle cx={155.833} cy={725.29} r={1.724} fill="#5C5C5C" />
      <circle cx={1379.71} cy={658.946} r={1.724} fill="#5C5C5C" />
      <circle cx={319.302} cy={629.317} r={1.724} fill="#5C5C5C" />
      <circle cx={335.825} cy={315.663} r={1.724} fill="#2E2E2E" />
      <circle cx={789.177} cy={642.556} r={1.724} fill="#5C5C5C" />
      <circle cx={982.29} cy={371.29} r={1.724} fill="#242424" />
      <circle cx={817.101} cy={473.591} r={2.585} fill="#5C5C5C" />
      <circle cx={702.917} cy={442.153} r={2.585} fill="#5C5C5C" />
      <circle cx={761.538} cy={748.765} r={2.585} fill="#2E2E2E" />
      <circle cx={1277.14} cy={447.685} r={2.585} fill="#242424" />
      <circle cx={783.64} cy={338.2} r={2.585} fill="#242424" />
      <circle cx={716.46} cy={463.874} r={2.585} fill="#2E2E2E" />
      <circle cx={373.734} cy={405.153} r={2.585} fill="#242424" />
      <circle cx={774.909} cy={584.191} r={2.585} fill="#242424" />
      <circle cx={824.05} cy={618.429} r={2.585} fill="#5C5C5C" />
      <circle cx={399.417} cy={255.724} r={2.585} fill="#5C5C5C" />
      <circle cx={1152.77} cy={90.55} r={2.585} fill="#242424" />
      <circle cx={823.691} cy={745.898} r={2.585} fill="#ABABAB" />
      <circle cx={960.158} cy={712.309} r={0.861} fill="#242424" />
      <circle cx={995.092} cy={304.813} r={0.861} fill="#242424" />
      <circle cx={61.315} cy={481.772} r={0.861} fill="#242424" />
      <circle cx={471.35} cy={56.434} r={0.861} fill="#2E2E2E" />
      <circle cx={803.115} cy={880.268} r={0.861} fill="#2E2E2E" />
      <circle cx={1127.74} cy={763.086} r={0.861} fill="#242424" />
      <circle cx={168.209} cy={859.572} r={0.861} fill="#7D7D7D" />
      <circle cx={190.518} cy={12.467} r={0.861} fill="#242424" />
      <circle cx={442.236} cy={303.008} r={0.861} fill="#2E2E2E" />
      <circle cx={532.916} cy={414.11} r={0.861} fill="#5C5C5C" />
      <circle cx={448.08} cy={481.555} r={0.861} fill="#242424" />
      <circle cx={1312.17} cy={619.047} r={0.861} fill="#242424" />
      <circle cx={2.471} cy={606.662} r={0.861} fill="#5C5C5C" />
      <circle cx={70.119} cy={1.086} r={0.861} fill="#242424" />
      <circle cx={1176.49} cy={271.088} r={0.861} fill="#2E2E2E" />
      <circle cx={959.783} cy={33.352} r={0.861} fill="#2E2E2E" />
      <circle cx={590.83} cy={772.463} r={0.861} fill="#242424" />
      <circle cx={989.854} cy={510.209} r={0.861} fill="#C7C7C7" />
      <circle cx={890.557} cy={42.783} r={0.861} fill="#242424" />
      <circle cx={128.072} cy={382.785} r={0.861} fill="#7D7D7D" />
      <circle cx={291.986} cy={763.842} r={0.861} fill="#2E2E2E" />
      <circle cx={651.342} cy={558.334} r={0.861} fill="#5C5C5C" />
      <circle cx={1393.32} cy={614.774} r={0.861} fill="#5C5C5C" />
      <circle cx={1049.27} cy={158.033} r={0.861} fill="#242424" />
      <circle cx={341.752} cy={176.469} r={0.861} fill="#242424" />
      <circle cx={296.506} cy={705.322} r={0.861} fill="#242424" />
      <circle cx={567.791} cy={271.031} r={0.861} fill="#7D7D7D" />
      <circle cx={228.537} cy={800.594} r={0.861} fill="#242424" />
      <circle cx={1077.36} cy={49.321} r={0.861} fill="#5C5C5C" />
      <circle cx={11.174} cy={384.947} r={0.861} fill="#7D7D7D" />
      <circle cx={1089.15} cy={175.715} r={0.861} fill="#5C5C5C" />
      <circle cx={112.111} cy={245.014} r={0.861} fill="#242424" />
      <circle cx={154.932} cy={371.018} r={0.861} fill="#5C5C5C" />
      <circle cx={896.604} cy={40.321} r={0.861} fill="#242424" />
      <circle cx={11.467} cy={402.008} r={0.861} fill="#242424" />
      <circle cx={708.627} cy={429.955} r={0.861} fill="#242424" />
      <circle cx={41.17} cy={94.834} r={0.861} fill="#242424" />
      <circle cx={957.959} cy={348.313} r={0.861} fill="#5C5C5C" />
      <circle cx={734.408} cy={665.024} r={0.861} fill="#2E2E2E" />
      <circle cx={709.584} cy={518.963} r={0.861} fill="#242424" />
      <circle cx={568.354} cy={125.279} r={0.861} fill="#2E2E2E" />
      <circle cx={1272.19} cy={78.108} r={0.861} fill="#242424" />
      <circle cx={915.471} cy={4.942} r={0.861} fill="#242424" />
      <circle cx={1436.72} cy={284.219} r={0.861} fill="#5C5C5C" />
      <circle cx={222.877} cy={833.299} r={0.861} fill="#242424" />
      <circle cx={355.35} cy={818.899} r={0.861} fill="#5C5C5C" />
      <circle cx={554.971} cy={352.111} r={0.861} fill="#5C5C5C" />
      <circle cx={476.432} cy={770.076} r={0.861} fill="#242424" />
      <circle cx={1174.39} cy={483.555} r={0.861} fill="#242424" />
      <circle cx={630.291} cy={83.143} r={0.861} fill="#ABABAB" />
      <circle cx={1014.79} cy={92.883} r={0.861} fill="#2E2E2E" />
      <circle cx={844.643} cy={54.795} r={0.861} fill="#242424" />
      <circle cx={580.826} cy={783.488} r={0.861} fill="#5C5C5C" />
      <circle cx={791.904} cy={126.219} r={0.861} fill="#242424" />
      <circle cx={1399.57} cy={188.975} r={0.861} fill="#5C5C5C" />
      <circle cx={899.776} cy={210.658} r={0.861} fill="#5C5C5C" />
      <circle cx={93.936} cy={211.061} r={0.861} fill="#2E2E2E" />
      <circle cx={1148.71} cy={355.438} r={0.861} fill="#242424" />
      <circle cx={1000.6} cy={783.168} r={0.861} fill="#5C5C5C" />
      <circle cx={562.94} cy={578.959} r={0.861} fill="#ABABAB" />
      <circle cx={775.151} cy={121.895} r={0.861} fill="#5C5C5C" />
      <circle cx={460.123} cy={765.557} r={0.861} fill="#2E2E2E" />
      <circle cx={1428.68} cy={822.363} r={0.861} fill="#5C5C5C" />
      <circle cx={674.272} cy={745.768} r={0.861} fill="#242424" />
      <circle cx={964.545} cy={99.104} r={0.861} fill="#5C5C5C" />
      <circle cx={152.951} cy={739.756} r={0.861} fill="#242424" />
      <circle cx={190.897} cy={303.754} r={0.861} fill="#5C5C5C" />
      <circle cx={1193.47} cy={594.234} r={0.861} fill="#5C5C5C" />
      <circle cx={959.904} cy={738.762} r={0.861} fill="#2E2E2E" />
      <circle cx={762.986} cy={486.983} r={0.861} fill="#5C5C5C" />
      <circle cx={744.412} cy={198.027} r={0.861} fill="#242424" />
      <circle cx={1433.67} cy={712.684} r={0.861} fill="#5C5C5C" />
      <circle cx={485.748} cy={692.856} r={0.861} fill="#242424" />
      <circle cx={1204.8} cy={802.35} r={0.861} fill="#242424" />
      <circle cx={564.85} cy={477.758} r={0.861} fill="#2E2E2E" />
      <circle cx={128.916} cy={874.492} r={0.861} fill="#2E2E2E" />
      <circle cx={918.678} cy={811.883} r={0.861} fill="#5C5C5C" />
      <circle cx={990.729} cy={315.719} r={0.861} fill="#5C5C5C" />
      <circle cx={564.908} cy={595.848} r={0.861} fill="#242424" />
      <circle cx={538.842} cy={585.469} r={0.861} fill="#5C5C5C" />
      <circle cx={1044.43} cy={78.168} r={0.861} fill="#242424" />
      <circle cx={237.346} cy={217.658} r={0.861} fill="#C7C7C7" />
      <circle cx={136.291} cy={841.891} r={0.861} fill="#5C5C5C" />
      <circle cx={1287.99} cy={611.123} r={0.861} fill="#5C5C5C" />
      <circle cx={1381.13} cy={137.43} r={0.861} fill="#5C5C5C" />
      <circle cx={114.944} cy={633.375} r={0.861} fill="#242424" />
      <circle cx={934.881} cy={672.061} r={0.861} fill="#2E2E2E" />
      <circle cx={591.342} cy={709.557} r={0.861} fill="#242424" />
      <circle cx={1220.48} cy={45.819} r={0.861} fill="#2E2E2E" />
      <circle cx={455.858} cy={160.035} r={0.861} fill="#5C5C5C" />
      <circle cx={603.76} cy={650.174} r={0.861} fill="#242424" />
      <circle cx={141.725} cy={322.176} r={0.861} fill="#5C5C5C" />
      <circle cx={509.088} cy={245.018} r={0.861} fill="#2E2E2E" />
      <circle cx={168.279} cy={832.652} r={0.861} fill="#5C5C5C" />
      <circle cx={1105.28} cy={330.414} r={0.861} fill="#5C5C5C" />
      <circle cx={431.322} cy={784.381} r={0.861} fill="#5C5C5C" />
      <circle cx={963.44} cy={322.233} r={0.861} fill="#242424" />
      <circle cx={1298.87} cy={111.789} r={0.861} fill="#2E2E2E" />
      <circle cx={767.854} cy={704.098} r={0.861} fill="#242424" />
      <circle cx={1176.31} cy={124.121} r={0.861} fill="#5C5C5C" />
      <circle cx={0.967} cy={775.137} r={0.861} fill="#242424" />
      <circle cx={1251} cy={726.006} r={0.861} fill="#242424" />
      <path fill="#2E2E2E" d="m1128.42 849.744 1.49 2.586h-2.99z" />
      <path fill="#242424" d="m1075.24 753.363 1.5 2.586h-2.99z" />
      <path fill="#5C5C5C" d="m946.29 810.945 1.493 2.586h-2.986z" />
      <path fill="#242424" d="m305.419 425.514 1.493 2.585h-2.986z" />
      <path fill="#7D7D7D" d="m396.106 792.793 1.493 2.585h-2.985z" />
      <path
        fill="#242424"
        d="m1264.42 109.344 1.5 2.585h-2.99zM1196.73 679.906l1.49 2.586h-2.99z"
      />
      <circle cx={1147.13} cy={755.288} r={1.724} fill="#5C5C5C" />
      <circle cx={926.054} cy={536.452} r={2.585} fill="#5C5C5C" />
      <circle cx={1150.27} cy={705.709} r={0.861} fill="#5C5C5C" />
      <circle cx={1039.4} cy={625.604} r={0.861} fill="#242424" />
      <circle cx={1062.56} cy={641.979} r={0.861} fill="#242424" />
      <circle cx={1098.09} cy={521.645} r={0.861} fill="#242424" />
      <circle cx={1084.19} cy={738.506} r={0.861} fill="#ABABAB" />
      <circle cx={884.717} cy={566.295} r={0.861} fill="#5C5C5C" />
      <circle cx={1165.73} cy={744.576} r={0.861} fill="#242424" />
      <circle cx={1030.75} cy={779.182} r={0.861} fill="#5C5C5C" />
      <circle cx={1227.05} cy={651.871} r={0.861} fill="#2E2E2E" />
      <circle cx={1163.33} cy={531.611} r={0.861} fill="#5C5C5C" />
      <circle cx={886.096} cy={731.031} r={0.861} fill="#242424" />
      <path
        fill="#5C5C5C"
        d="m1069.75 664.049 1.5 2.585h-2.99zM416.138 871.389l1.492 2.585h-2.985z"
      />
      <path
        fill="#242424"
        d="m914.438 723.723 1.493 2.585h-2.985zM238.036 642.365l.366 1.358 1.358.366-1.358.366-.366 1.358-.365-1.358-1.359-.366 1.359-.366z"
      />
      <path
        fill="#5C5C5C"
        d="m1170.45 110.604.36 1.358 1.36.365-1.36.366-.36 1.358-.37-1.358-1.36-.366 1.36-.365z"
      />
      <path
        fill="#242424"
        d="m1087.57 521.828.36 1.358 1.36.366-1.36.365-.36 1.358-.37-1.358-1.36-.365 1.36-.366zM399.22 48.496l.365 1.358 1.358.366-1.358.365-.365 1.358-.366-1.358-1.358-.365 1.358-.366z"
      />
      <path
        fill="#5C5C5C"
        d="m38.634 444.914.365 1.358 1.358.366-1.358.365-.365 1.358-.366-1.358-1.358-.365 1.358-.366z"
      />
      <path
        fill="#242424"
        d="m833.47 708.986.365 1.358 1.358.366-1.358.366-.365 1.358-.366-1.358-1.358-.366 1.358-.366z"
      />
      <circle cx={1268.18} cy={429.937} r={1.724} fill="#5C5C5C" />
      <circle cx={1079.1} cy={482.48} r={1.724} fill="#242424" />
      <circle cx={1282.26} cy={346.831} r={2.585} fill="#242424" />
      <circle cx={1279.8} cy={513.349} r={2.585} fill="#242424" />
      <circle cx={1305.57} cy={621.827} r={2.585} fill="#242424" />
      <circle cx={1224.37} cy={383.606} r={0.861} fill="#5C5C5C" />
      <circle cx={1360.57} cy={423.807} r={0.861} fill="#242424" />
      <circle cx={1398.62} cy={586.567} r={0.861} fill="#ABABAB" />
      <circle cx={1115.7} cy={310.473} r={0.861} fill="#7D7D7D" />
      <circle cx={1425.62} cy={556.703} r={0.861} fill="#242424" />
      <circle cx={1172.65} cy={565.744} r={0.861} fill="#5C5C5C" />
      <circle cx={1397.67} cy={385.371} r={0.861} fill="#5C5C5C" />
      <path
        fill="#242424"
        d="m307.485 549.479.366 1.358 1.358.365-1.358.366-.366 1.358-.365-1.358-1.358-.366 1.358-.365z"
      />
      <path
        fill="#7D7D7D"
        d="m509.071 742.549.366 1.358 1.358.365-1.358.366-.366 1.358-.365-1.358-1.358-.366 1.358-.365z"
      />
      <path
        fill="#242424"
        d="m621.024 572.5.366 1.358 1.358.366-1.358.365-.366 1.358-.365-1.358-1.358-.365 1.358-.366z"
      />
      <path
        fill="#C7C7C7"
        d="m539.267 674.451.365 1.358 1.358.366-1.358.365-.365 1.358-.366-1.358-1.358-.365 1.358-.366z"
      />
      <path
        fill="#2E2E2E"
        d="m1344.32 161.105.36 1.358 1.36.366-1.36.366-.36 1.358-.37-1.358-1.36-.366 1.36-.366z"
      />
      <circle cx={1398.63} cy={303.61} r={0.861} fill="#5C5C5C" />
      <path
        fill="#5C5C5C"
        d="m1310.5 363.584 1.5 2.585h-2.99zM1336.27 406.336l1.49 2.585h-2.99z"
      />
      <path fill="#242424" d="m1391.5 574.621 1.49 2.586H1390z" />
      <path
        fill="#5C5C5C"
        d="m1199.67 537.096.37 1.358 1.36.365-1.36.366-.37 1.358-.36-1.358-1.36-.366 1.36-.365z"
      />
      <path
        fill="#242424"
        d="m1246.63 321.055.37 1.358 1.35.365-1.35.366-.37 1.358-.37-1.358-1.35-.366 1.35-.365z"
      />
      <path
        fill="#5C5C5C"
        d="m1305.09 802.828.37 1.358 1.36.366-1.36.365-.37 1.358-.36-1.358-1.36-.365 1.36-.366z"
      />
      <path
        fill="#2E2E2E"
        d="m562.603 449.217.365 1.358 1.358.365-1.358.366-.365 1.358-.366-1.358-1.358-.366 1.358-.365z"
      />
      <path
        fill="#5C5C5C"
        d="m709.79 618.662.366 1.358 1.358.366-1.358.365-.366 1.358-.366-1.358-1.358-.365 1.358-.366z"
      />
      <path
        fill="#242424"
        d="m925.544 138.613.366 1.358 1.358.366-1.358.366-.366 1.358-.366-1.358-1.358-.366 1.358-.366z"
      />
      <circle cx={274.884} cy={335.472} r={1.724} fill="#242424" />
      <circle cx={248.528} cy={498.728} r={1.724} fill="#5C5C5C" />
      <circle cx={171.702} cy={554.685} r={2.585} fill="#5C5C5C" />
      <circle cx={271.538} cy={569.109} r={2.585} fill="#242424" />
      <circle cx={363.913} cy={469.31} r={2.585} fill="#5C5C5C" />
      <circle cx={223.803} cy={514.979} r={0.861} fill="#5C5C5C" />
      <circle cx={186.225} cy={505.168} r={0.861} fill="#242424" />
      <circle cx={215.748} cy={488.113} r={0.861} fill="#242424" />
      <circle cx={182.951} cy={592.514} r={0.861} fill="#2E2E2E" />
      <circle cx={183.354} cy={410.537} r={0.861} fill="#5C5C5C" />
      <circle cx={181.705} cy={341.67} r={0.861} fill="#5C5C5C" />
      <circle cx={361.361} cy={589.027} r={0.861} fill="#5C5C5C" />
      <circle cx={313.229} cy={428.1} r={0.861} fill="#242424" />
      <circle cx={372.85} cy={321.672} r={0.861} fill="#7D7D7D" />
      <circle cx={232.283} cy={546.793} r={0.861} fill="#242424" />
      <circle cx={298.205} cy={564.252} r={0.861} fill="#2E2E2E" />
      <circle cx={281.381} cy={574.865} r={0.861} fill="#242424" />
      <circle cx={352.256} cy={448.065} r={0.861} fill="#5C5C5C" />
      <circle cx={330.408} cy={453.184} r={0.861} fill="#242424" />
    </g>
    <defs>
      <clipPath id="Stars-road_svg__a">
        <path fill="#fff" d="M0 0h1444v886H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgStarsRoad;
