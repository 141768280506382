import React, { ReactNode } from "react";
interface Item {
  icon: ReactNode;
  title: string;
  text: string;
}
interface CardProps {
  item: Item;
}
const Card: React.FC<CardProps> = ({ item }) => {
  return (
    <div className="dashboard-profile-col col-span-12 desktop:col-span-3">
      <div className="dashboard-profile-card frame-effects">
        <div className="flex items-center gap-2">
          <span className="text-gold-500 w-6 h-6 flex items-center justify-center">
            {item.icon}
          </span>{" "}
          <div className="text-label-md md:text-title-md">{item.title}</div>
        </div>
        <div className="text-body-lg md:text-label-lg">{item.text}</div>
      </div>
    </div>
  );
};

export default Card;
