import React, { useEffect } from "react";
import { useAccount, useReadContract } from "wagmi";
import { landAbi } from "../utils/contractConstants";
import { useLandStore } from "../store/landStore.js";

function LandProvider({ children }) {
  const { setUserTiles, setAllTile } = useLandStore();
  const { address, isConnected } = useAccount();

  const { data: landOfUserData } = useReadContract(
    isConnected && {
      address: process.env.REACT_APP_LAND_CONTRACT_ADDRESS,
      abi: landAbi,
      functionName: "getLandsByOwner",
      args: [address],
    }
  );

  // const { data: allTilesData } = useReadContract(
  //   isConnected && {
  //     address: process.env.REACT_APP_LAND_CONTRACT_ADDRESS,
  //     abi: landAbi,
  //     functionName: "getAllTileData",
  //   }
  // );
  useEffect(() => {
    if (landOfUserData) {
      // setUserTiles(landOfUserData);
    }
  }, [landOfUserData, isConnected]);

  // useEffect(() => {
  //   if (allTilesData) {
  //     setAllTile(allTilesData);
  //   }
  // }, [allTilesData, isConnected]);

  return <>{children}</>;
}

export default LandProvider;
