import React, { useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import Menu from "@/config/landing-menu";
import { NavigationProps, MenuItem } from "../types";
import { SidebarTick } from "@/components/Icons";
import avatar from "@/scss/images/back-logo-sidebar.png";
import logo from "@/assets/images/logo/logorec.png";

const Navigation: React.FC<NavigationProps> = ({
  isAuthenticated,
  menuActive,
  handleMenuActive,
}) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const location = useLocation();
  const hash = `/${location.hash}`;
  const handleDropdown = (index: number) => {
    setActiveIndex(index);
  };

  const checkActiveLink = (path: string) => {
    if (location.hash) {
      if (hash === path) {
        return true;
      }
    } else {
      if (location.pathname === path) {
        return true;
      }
    }
  };

  return (
    <>
      {" "}
      <nav
        className={`fixed desktop:relative pb-14 desktop:pb-0 bg-blackshade-900 z-40 top-0 left-0 w-[300px] desktop:w-auto h-screen transition-all ease-in desktop:h-auto desktop:translate-x-0 overflow-scroll desktop:overflow-auto ${
          menuActive ? "translate-x-0" : "-translate-x-[140%]"
        }`}
      >
        <ul
          id="menu-primary-menu"
          className="flex flex-col desktop:flex-row gap-8 desktop:items-center px-12 desktop:p-4"
        >
          <div className="relative desktop:hidden">
            <img
              src={avatar}
              alt=""
              className="h-[182px] w-[200px] mx-auto mt-3"
            />
            <Link to="/">
              <img
                src={logo}
                alt="logo"
                className="w-[100px] h-[100px] absolute top-[65px] left-[56px]"
              />
            </Link>
          </div>
          {isAuthenticated && (
            <li
              onClick={() => handleDropdown(Menu.length)}
              className={`text-blackshade-300 text-title-lg hover:text-gold-500 `}
            >
              <Link to="/dashboard/profile" onClick={handleMenuActive}>
                Dashboard
              </Link>
            </li>
          )}
          {Menu.map((data: MenuItem, idx: number) => (
            <li
              key={idx}
              onClick={() => handleDropdown(idx)}
              className={`text-blackshade-300 text-title-lg hover:text-gold-500 ${
                activeIndex === idx ? "active" : ""
              }`}
            >
              {data.isDowanloadable ? (
                <a
                  href={data.path}
                  target="_blank"
                  rel="noopener noreferrer"
                  download
                >
                  {data.title}
                </a>
              ) : (
                <NavLink to={data.path} onClick={handleMenuActive}>
                  {checkActiveLink(data.path) && (
                    <SidebarTick className="absolute left-0 desktop:hidden" />
                  )}
                  <div
                    className={`${
                      checkActiveLink(data.path) ? "text-gold-500" : ""
                    }`}
                  >
                    {data.title}
                  </div>
                </NavLink>
              )}
            </li>
          ))}
        </ul>
      </nav>{" "}
      <div
        className={`bg-blackshade-900 opacity-[0.88] z-[20] fixed inset-0 desktop:hidden ${
          menuActive ? "block" : "hidden"
        }`}
        onClick={handleMenuActive}
      ></div>
    </>
  );
};

export default Navigation;
