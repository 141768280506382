import { Navigate } from "react-router-dom";
import { useAuth } from "../context/authProvider";
import Loading from "@/components/Common/Loading";

const PublicRoute = ({ element, ...rest }) => {
  const { isAuthenticated, isFetchingUserData, isLoading, isConnected } =
    useAuth();
  if (isFetchingUserData || isLoading) {
    return <Loading />;
  }
  return !isAuthenticated && isConnected ? element : <Navigate to="/" />;
};
export default PublicRoute;
