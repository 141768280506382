import { useEffect, useState } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim"; // Slim version of tsparticles to reduce the bundle size

const Loading = () => {
  const [init, setInit] = useState(false);

  // Initialize particles once
  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine); // Load the slim version of particles engine
    }).then(() => {
      setInit(true);
    });
  }, []);

  const particlesLoaded = (container) => {
  };

  return (
    <div className="fixed z-[1000] inset-0">
      {init && (
        <>
          {/* First Particles Component */}
          <Particles
            id="tsparticles-first" // Unique ID for the first particles instance
            particlesLoaded={particlesLoaded}
            options={{
              fpsLimit: 120,
              interactivity: {
                events: {
                  onClick: {
                    enable: true,
                    mode: "push",
                  },
                  resize: true,
                },
                modes: {
                  bubble: {
                    distance: 200,
                    duration: 2,
                    opacity: 0,
                    size: 0,
                    speed: 1,
                  },
                  push: {
                    quantity: 4,
                  },
                  repulse: {
                    distance: 200,
                    duration: 0.9,
                  },
                },
              },
              particles: {
                color: {
                  value: "#7D7D7D",
                },
                links: {
                  color: "#7D7D7D",
                  distance: 150,
                  enable: true,
                  opacity: 0.5,
                  width: 1,
                },
                move: {
                  direction: "none",
                  enable: true,
                  outModes: {
                    default: "bounce",
                  },
                  random: false,
                  speed: 1, // Lowered speed for the first particles
                  straight: false,
                },
                number: {
                  density: {
                    enable: true,
                    area: 800,
                  },
                  value: 80,
                },
                opacity: {
                  value: 0.5,
                },
                shape: {
                  type: "circle",
                },
                size: {
                  value: { min: 1, max: 5 },
                },
              },
              detectRetina: true,
            }}
          />

          {/* Second Particles Component */}
          <Particles
            id="tsparticles-second" // Unique ID for the second particles instance
            particlesLoaded={particlesLoaded}
            options={{
              background: {
                color: "#121212",
                size: "40%",
                position: "60% 50%",
                zIndex: "-10",
              },
              interactivity: {
                events: {
                  onClick: {
                    enable: true,
                    mode: "repulse",
                  },
                  onHover: {
                    enable: true,
                    mode: "bubble",
                  },
                },
                modes: {
                  bubble: {
                    distance: 200,
                    duration: 2,
                    opacity: 0,
                    size: 0,
                    speed: 3,
                  },
                  repulse: {
                    distance: 400,
                    duration: 0.4,
                  },
                },
              },
              particles: {
                color: { value: "#FFB70F" },
                move: {
                  direction: "none",
                  enable: true,
                  outModes: "out",
                  random: true,
                  speed: 0.3,
                },
                number: {
                  density: {
                    enable: true,
                  },
                  value: 600,
                },
                opacity: {
                  animation: {
                    enable: true,
                    speed: 5,
                  },
                  value: { min: 0.3, max: 0.6 },
                },
                shape: {
                  type: "circle",
                },
                size: {
                  value: 1,
                },
              },
              detectRetina: true,
            }}
          />
        </>
      )}
      <div className="loader-circle"></div>
      <div className="loader-line-mask">
        <div className="loader-line"></div>
      </div>
    </div>
  );
};

export default Loading;
