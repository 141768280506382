import * as React from "react";
import type { SVGProps } from "react";
const SvgErrorStatus = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={64}
    height={64}
    fill="none"
    {...props}
  >
    <path
      fill="#F35B1A"
      d="M32 16.666a2 2 0 0 1 2 2v16a2 2 0 0 1-4 0v-16a2 2 0 0 1 2-2M32 45.333A2.667 2.667 0 1 0 32 40a2.667 2.667 0 0 0 0 5.333"
    />
    <path
      fill="#F35B1A"
      fillRule="evenodd"
      d="M3.334 32c0-15.832 12.835-28.667 28.667-28.667S60.667 16.168 60.667 32 47.833 60.666 32.001 60.666 3.334 47.832 3.334 32M32.001 7.333C18.378 7.333 7.334 18.377 7.334 32s11.044 24.666 24.667 24.666S56.667 45.623 56.667 32 45.624 7.333 32.001 7.333"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgErrorStatus;
