import * as React from "react";
import type { SVGProps } from "react";
const SvgCoin = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <path
      stroke="#121212"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M24.67 16.87v4.932c0 4.16-3.88 7.534-8.667 7.534s-8.667-3.373-8.667-7.533v-4.934c0 4.16 3.88 7.134 8.667 7.134 4.786 0 8.666-2.974 8.666-7.134"
    />
    <path
      stroke="#121212"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M24.67 10.198a6.27 6.27 0 0 1-.92 3.294c-1.427 2.346-4.36 3.84-7.747 3.84s-6.32-1.494-7.747-3.84a6.27 6.27 0 0 1-.92-3.294c0-2.08.973-3.96 2.533-5.32 1.574-1.373 3.734-2.213 6.134-2.213s4.56.84 6.133 2.2c1.56 1.373 2.533 3.253 2.533 5.333"
    />
    <path
      stroke="#121212"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M24.67 10.198v6.667c0 4.16-3.88 7.133-8.667 7.133s-8.667-2.973-8.667-7.133v-6.667c0-4.16 3.88-7.533 8.667-7.533 2.4 0 4.56.84 6.133 2.2 1.56 1.373 2.533 3.253 2.533 5.333"
    />
  </svg>
);
export default SvgCoin;
