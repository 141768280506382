import React, { createContext, useRef, useState, ReactNode } from "react";
import Toast from "./index";
import { ToastMessage, ToastContextType } from "./types";

export const ToastContext = createContext<ToastContextType | undefined>(
  undefined
);

export const ToastProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [toasts, setToasts] = useState<ToastMessage[]>([]);
  const displayedToasts = useRef(new Set<string>());
  const addToast = (
    type: ToastMessage["type"],
    message: string,
    ctaLabel?: string,
    onCtaClick?: () => void
  ) => {
    if (displayedToasts.current.has(message)) return; // Prevent duplicate display

    const id = Math.random(); // Generate a unique id
    setToasts((prev) => [
      ...prev,
      { id, type, message, ctaLabel, onCtaClick }, // Include CTA props
    ]);
    displayedToasts.current.add(message); // Mark as shown
  };
  const removeToast = (id: number) => {
    setToasts((prev) => {
      const toastToRemove = prev.find((toast) => toast.id === id);
      if (toastToRemove) {
        displayedToasts.current.delete(toastToRemove.message); 
      }
      return prev.filter((toast) => toast.id !== id);
    });
  };


  return (
    <ToastContext.Provider value={{ addToast, removeToast, toasts }}>
      {children}
      <div className="fixed bottom-4 right-4 space-y-2 z-[100]">
        {toasts.map((toast) => (
          <Toast
            key={toast.id}
            {...toast}
            onClose={() => removeToast(toast.id)}
          />
        ))}
      </div>
    </ToastContext.Provider>
  );
};
