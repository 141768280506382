import * as React from "react";
import type { SVGProps } from "react";
const SvgLayersMinimalistic = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={12}
    viewBox="0 0 16 12"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8 1.167c-.707 0-1.273.219-2.623.759l-1.872.749c-.674.269-1.14.456-1.443.615q-.044.023-.08.044l.08.043c.303.159.77.346 1.443.616l1.872.749c1.35.54 1.916.758 2.623.758s1.273-.218 2.623-.758l1.872-.75c.674-.269 1.14-.456 1.443-.615l.08-.043-.08-.044c-.303-.159-.77-.346-1.443-.615l-1.872-.75c-1.35-.54-1.916-.758-2.623-.758m-2.917-.2C6.334.465 7.08.166 8 .166s1.666.299 2.917.8l.077.03 1.903.761c.636.255 1.152.46 1.505.646.18.094.353.2.487.329a.83.83 0 0 1 .278.6.83.83 0 0 1-.278.601 2.1 2.1 0 0 1-.487.329c-.353.185-.869.392-1.505.646l-1.903.761-.077.031c-1.251.5-1.998.8-2.917.8-.92 0-1.666-.3-2.917-.8l-.077-.03-1.903-.762c-.636-.254-1.152-.46-1.505-.646a2.1 2.1 0 0 1-.487-.329.83.83 0 0 1-.278-.6c0-.27.14-.47.278-.601a2.1 2.1 0 0 1 .487-.329c.353-.185.869-.391 1.505-.646l1.903-.76zm-3.417 4.66.002.002a1 1 0 0 0 .095.077c.073.056.186.139.339.238.306.199.774.464 1.403.715l1.872.75c1.35.54 1.916.758 2.623.758s1.273-.219 2.623-.759l1.872-.749a8 8 0 0 0 1.403-.715 5 5 0 0 0 .417-.3l.017-.015.001-.001.001-.001a.5.5 0 0 1 .667.746L14.667 6l.334.373H15l-.002.001-.003.003-.009.008a2 2 0 0 1-.138.112 6 6 0 0 1-.406.286c-.355.23-.88.527-1.575.805l-1.873.749-.077.03c-1.251.501-1.998.8-2.917.8-.92 0-1.666-.299-2.917-.8l-.077-.03-1.872-.749a9 9 0 0 1-1.576-.805 6 6 0 0 1-.544-.398l-.01-.008-.002-.003H1v-.001L1.333 6 1 6.373a.5.5 0 0 1 .666-.746m12.667 2.668.001-.001a.5.5 0 0 1 .667.745l-.334-.372.334.372L15 9.04l-.002.001-.003.003-.009.008a2 2 0 0 1-.138.112q-.14.11-.406.286c-.355.23-.88.527-1.575.805l-1.873.749-.077.03c-1.251.501-1.998.8-2.917.8-.92 0-1.666-.299-2.917-.8l-.077-.03-1.872-.75a9 9 0 0 1-1.576-.804 6 6 0 0 1-.544-.398l-.01-.008-.002-.003L1 9.04l.333-.373L1 9.039a.5.5 0 0 1 .667-.745l.001.002a1 1 0 0 0 .095.076c.073.056.186.14.339.239.306.198.774.464 1.403.715l1.872.75c1.35.539 1.916.758 2.623.758s1.273-.219 2.623-.759l1.872-.749a8 8 0 0 0 1.403-.715 5 5 0 0 0 .417-.301l.017-.014z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgLayersMinimalistic;
