import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { AuthProvider, Web3ModalProvider } from "./context";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import SignModal from "./components/Common/SignModal";
import { Helmet } from "react-helmet";
import "react-toastify/dist/ReactToastify.css";
import LandProvider from "./context/landProvider";
import { ToastProvider } from "./components/Common/Toast/context";
function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <Web3ModalProvider>
      <ToastProvider>
        <AuthProvider>
          <LandProvider>
            <Outlet />
            <Helmet>
              <script
                async
                src="https://www.googletagmanager.com/gtag/js?id=G-M6S3NNV8MR"
              ></script>
              <script>
                {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-M6S3NNV8MR');
        `}
              </script>
            </Helmet>
            <ToastContainer
              position="top-right"
              autoClose={2500}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />
            <SignModal />
          </LandProvider>
        </AuthProvider>
      </ToastProvider>
    </Web3ModalProvider>
  );
}

export default App;
