import { MapProvider } from "./context";
import BuyLandMap from "./BuyLandMap";

const BuyLandWrapper = () => {
  return (
    <MapProvider>
      <BuyLandMap />
    </MapProvider>
  );
};
export default BuyLandWrapper;
