import * as React from "react";
import type { SVGProps } from "react";
const SvgYoutube = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <g clipPath="url(#youtube_svg__a)">
      <path
        fill="#FFB70F"
        fillRule="evenodd"
        d="M22.746 4.834c.373.374.64.84.776 1.35.501 1.885.501 5.815.501 5.815s0 3.93-.501 5.815A3.02 3.02 0 0 1 21.4 19.95c-1.877.504-9.377.504-9.377.504s-7.5 0-9.376-.505a3.02 3.02 0 0 1-2.122-2.135C.023 15.93.023 11.999.023 11.999s0-3.93.502-5.814a3.02 3.02 0 0 1 2.122-2.136c1.876-.504 9.376-.504 9.376-.504s7.5 0 9.377.504c.51.14.974.41 1.346.785M15.842 12 9.569 8.43v7.138z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="youtube_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgYoutube;
