import thebittimes from "@/assets/images/logo/thebittimes.png";
import chainwire from "@/assets/images/logo/chainwire.png";
import chainbits from "@/assets/images/logo/chainbits.png";
import newsFlash from "@/assets/images/logo/crypto-news-flash.png";
import daily from "@/assets/images/logo/cryptodaily.png";
import yahoofinance from "@/assets/images/logo/yahoofinance.png";
import newsfile from "@/assets/images/logo/newsfile.png";
import finanz from "@/assets/images/logo/finanz-nachrichen.png";
import cointelegraph from "@/assets/images/logo/cointelegraph.png";
import streetinsider from "@/assets/images/logo/streetinsider.png";
import coincarp from "@/assets/images/logo/coincarp.png";
import coinmarketcap from "@/assets/images/logo/coinmarketcap.png";

const newsData = [
  {
    id: 1,
    img: coinmarketcap,
    link: "https://coinmarketcap.com/currencies/pco-metaverse/",
  },
  {
    id: 2,
    img: coincarp,
    link: "https://www.coincarp.com/events/pco-metaearth-new-listing-on-lbank/",
  },
  {
    id: 3,
    img: chainbits,
    link: "https://www.chainbits.com/press-releases/introducing-pco-metaverse-paving-the-way-for-a-new-era-of-virtual-gaming-and-creativity/",
  },
  {
    id: 4,
    img: newsFlash,
    link: "https://www.crypto-news-flash.com/introducing-pco-metaverse-paving-the-way-for-a-new-era-of-virtual-gaming-and-creativity/",
  },
  {
    id: 5,
    img: daily,
    link: "https://cryptodaily.co.uk/2024/05/introducing-pco-metaverse-paving-the-way-for-a-new-era-of-virtual-gaming-and-creativity",
  },

  {
    id: 6,
    img: thebittimes,
    link: "https://thebittimes.com/introducing-pco-metaverse-paving-the-way-for-a-new-era-of-virtual-gaming-and-creativity-tbt88504.html",
  },
  {
    id: 7,
    img: newsfile,
    link: "https://www.newsfilecorp.com/release/209272/LBank-Exchange-Will-List-PCO-Metaverse-PME-on-May-20-2024",
  },
  {
    id: 8,
    img: finanz,
    link: "https://www.finanznachrichten.de/nachrichten-2024-05/62255195-lbank-exchange-will-list-pco-metaverse-on-may-20-2024-296.htm",
  },
  {
    id: 9,
    img: cointelegraph,
    link: "https://cointelegraph.com/press-releases/exploring-the-boundless-potential-of-metaverse-as-a-service-in-pco-metaverse",
  },
  {
    id: 10,
    img: streetinsider,
    link: "https://www.streetinsider.com/Newsfile/LBank+Exchange+Will+List+PCO+Metaverse+%28PME%29+on+May+20%2C+2024/23238816.html",
  },
  {
    id: 11,
    img: yahoofinance,
    link: "https://finance.yahoo.com/news/lbank-exchange-list-pco-metaverse-145600674.html",
  },

  {
    id: 12,
    img: chainwire,
    link: "https://chainwire.org/2024/05/16/introducing-pco-metaverse-paving-the-way-for-a-new-era-of-virtual-gaming-and-creativity/",
  },
];

export default newsData;
