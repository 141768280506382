export const contractsList = [
  {
    title: "1. UserRegistry Contract Address (Proxy)",
    value: process.env.REACT_APP_USER_REGISTERY_CONTRACT_ADDRESS,
  },
  {
    title: "2. LandCoreV2 Contract Address (Proxy)",
    value: process.env.REACT_APP_LAND_CONTRACT_ADDRESS,
  },
  {
    title: "3. LandNFT Contract Address (Proxy)",
    value: process.env.REACT_APP_LAND_TO_NFT_CONTRACT_ADDRESS,
  },
  {
    title: "4. LandNFTStake Contract Address (Proxy)",
    value: process.env.REACT_APP_STAKE_NFT_CONTRACT_ADDRESS,
  },
  {
    title: "5. PMETokenV3 Contract Address (Proxy)",
    value: process.env.REACT_APP_PME_CONTRACT_ADDRESS,
  },
  {
    title: "6. PMGToken Contract Address (Proxy)",
    value: process.env.REACT_APP_PMG_CONTRACT_ADDRESS,
  },

  {
    title: "7. PMBToken (PcolandMetaEarthBridge)",
    value: process.env.REACT_APP_PMB_CONTRACT_ADDRESS,
  },
];
