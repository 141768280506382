import * as React from "react";
import type { SVGProps } from "react";
const SvgCartLargeMinimalistic = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={20}
    viewBox="0 0 21 20"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M1.249.293A.75.75 0 1 0 .75 1.708l.261.092c.668.234 1.107.39 1.43.548.303.149.437.27.525.398.09.132.16.314.199.677.041.38.042.876.042 1.616V7.76c0 1.453.014 2.5.15 3.3.147.855.44 1.466.985 2.042.594.627 1.347.9 2.243 1.027.859.121 1.948.121 3.294.121h5.406c.742 0 1.366 0 1.87-.062.536-.065 1.025-.208 1.451-.556s.666-.797.838-1.309c.163-.482.289-1.093.438-1.82l.51-2.469V8.03l.01-.053c.165-.824.304-1.518.338-2.076.037-.587-.03-1.165-.412-1.661-.235-.305-.565-.478-.866-.584a4.6 4.6 0 0 0-1.003-.209c-.687-.077-1.521-.077-2.34-.077H4.668l-.01-.108c-.055-.497-.171-.95-.453-1.362-.284-.416-.662-.682-1.103-.898C2.69.799 2.167.615 1.55.399zm3.46 4.577h11.38c.856 0 1.61.001 2.205.068q.441.05.672.133a.6.6 0 0 1 .176.087c.061.081.128.229.102.65-.027.444-.144 1.036-.321 1.926v.002l-.5 2.42c-.16.783-.27 1.303-.4 1.688-.122.366-.238.523-.363.625s-.303.184-.686.23c-.403.05-.934.051-1.734.051H9.937c-1.416 0-2.4-.002-3.14-.107-.715-.101-1.092-.285-1.365-.573-.32-.337-.493-.668-.595-1.263-.11-.649-.129-1.558-.129-3.047zM6.5 19.75a2.25 2.25 0 1 1 0-4.5 2.25 2.25 0 0 1 0 4.5m-.75-2.25a.75.75 0 1 0 1.5 0 .75.75 0 0 0-1.5 0M15.5 19.75a2.25 2.25 0 1 1 0-4.5 2.25 2.25 0 0 1 0 4.5m-.75-2.25a.75.75 0 1 0 1.5 0 .75.75 0 0 0-1.5 0"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCartLargeMinimalistic;
