import * as React from "react";
import type { SVGProps } from "react";
const SvgUsersGroupRounded = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    fill="none"
    viewBox="0 0 28 28"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11 .667a6.333 6.333 0 1 0 0 12.667A6.333 6.333 0 0 0 11 .667M6.667 7a4.333 4.333 0 1 1 8.666 0 4.333 4.333 0 0 1-8.666 0"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      d="M19 2a1 1 0 1 0 0 2 3 3 0 0 1 0 6 1 1 0 1 0 0 2 5 5 0 1 0 0-10"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3.904 17.028C5.77 15.96 8.281 15.334 11 15.334s5.23.627 7.096 1.694c1.837 1.05 3.237 2.653 3.237 4.639s-1.4 3.59-3.237 4.64C16.23 27.373 13.719 28 11 28s-5.23-.627-7.096-1.694C2.067 25.257.667 23.655.667 21.668s1.4-3.59 3.237-4.64m.992 1.736c-1.54.88-2.23 1.944-2.23 2.903s.69 2.023 2.23 2.903C6.41 25.434 8.564 26 11 26s4.591-.566 6.104-1.43c1.54-.88 2.23-1.944 2.23-2.903s-.69-2.023-2.23-2.903c-1.513-.864-3.668-1.43-6.104-1.43s-4.591.566-6.104 1.43"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      d="M23.214 16.69a1 1 0 1 0-.428 1.954c1.056.232 1.9.63 2.458 1.085.558.457.756.903.756 1.271 0 .335-.16.727-.604 1.139-.447.415-1.133.798-2.018 1.064a1 1 0 1 0 .577 1.915c1.097-.33 2.077-.84 2.802-1.514C27.485 22.928 28 22.04 28 21c0-1.153-.632-2.117-1.49-2.818s-2.013-1.21-3.296-1.492"
    />
  </svg>
);
export default SvgUsersGroupRounded;
