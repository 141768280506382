// import { CheckedBoxIcon, UncheckedBoxIcon } from "@/components/Icons";
import { CheckedBox, UncheckedBox } from "@/components/Icons";
import React from "react";
interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  unCheckedIcon?: React.ReactNode;
  checkedIcon?: React.ReactNode;
}

const CheckBox: React.FC<Props> = ({
  onChange,
  unCheckedIcon,
  checkedIcon,
  ...props
}) => {
  return (
    <div className="relative cursor-pointer">
      <input
        type="checkbox"
        className="absolute top-0 bottom-0 right-0 left-0 z-50 cursor-pointer opacity-0"
        onChange={(e) => {
          if (onChange) onChange(e);
        }}
        {...props}
      />

      {props.checked
        ? checkedIcon || <CheckedBox className="w-10 h-10" />
        : unCheckedIcon || <UncheckedBox className="w-10 h-10" />}
    </div>
  );
};

export default CheckBox;
