import React, { useState, useCallback } from "react";
import { Scrollbar, A11y, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import map from "@/scss/images/blog-part-map.png";
import wave from "@/scss/images/Blog-part-Wave.png";
import Button from "@/components/Common/Button";
import { AltArrowLeft, AltArrowRight } from "@/components/Icons";
import BlogItem from "./blog-item";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";

const Blog = ({ data }) => {
  const [swiperRef, setSwiperRef] = useState();

  const handlePrevious = useCallback(() => {
    swiperRef?.slidePrev();
  }, [swiperRef]);

  const handleNext = useCallback(() => {
    swiperRef?.slideNext();
  }, [swiperRef]);

  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '"></span>';
    },
  };
  return (
    <section className="text-center pt-20 relative">
      <img
        src={wave}
        alt="wave"
        className="absolute w-full h-[248px] top-12 desktop:h-[777px] desktop:-top-[1rem]"
      />

      <div className="container">
        <div data-aos="fade-up" data-aos-duration="800">
          <h3 className="text-label-md desktop:text-subheading-md text-blackshade-200 mb-3">
            Blogs
          </h3>
          <h4 className="text-headline-md desktop:text-display-sm text-white">
            Latest <span className="text-gradiant">News</span>
          </h4>
        </div>
        <div
          className="mt-10 desktop:mt-14"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <Swiper
            navigation={{
              nextEl: ".blog-button-next",
              prevEl: ".blog-button-prev",
            }}
            centeredSlides={true}
            loop={true}
            onSwiper={setSwiperRef}
            modules={[Scrollbar, A11y, Navigation, Pagination]}
            pagination={pagination}
            spaceBetween={30}
            scrollbar={{ draggable: true }}
            breakpoints={{
              0: {
                slidesPerView: 1.2,
              },
              767: {
                slidesPerView: 2,
              },
              991: {
                slidesPerView: 3,
              },
            }}
            className="h-[550px] blog-swiper"
          >
            {data.map((item) => (
              <SwiperSlide key={item.id}>
                <BlogItem item={item} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
      <img
        src={map}
        alt="map"
        className="-bottom-[7rem] object-cover desktop:object-none desktop:-bottom-[25rem] w-full h-[350px] desktop:h-[777px] absolute -z-10"
      />
      <div className="flex gap-[13rem] justify-center absolute -bottom-1 w-full z-20">
        <Button
          model="tonal"
          size="md"
          className="w-12 h-12 !p-0"
          onClick={handlePrevious}
        >
          <AltArrowLeft />
        </Button>
        <Button
          model="tonal"
          size="md"
          className="w-12 h-12 !p-0"
          onClick={handleNext}
        >
          <AltArrowRight />
        </Button>
      </div>
    </section>
  );
};

export default Blog;
