import React from "react";
interface TableMobileCardProps {
  action?: React.ReactNode;
  children: React.ReactNode;
  header?: React.ReactNode;
}

const TableMobileCard: React.FC<TableMobileCardProps> = ({
  action,
  children,
  header,
}) => {
  return (
    <div>
      <div className="relative z-10 bg-banner rounded-lg backdrop-blur-md	p-2 flex justify-between text-blackshade-900">
        {header}
      </div>
      <div className="frame-effects bg-dashboard-frame rounded-b-lg rounded-t-none px-3  pb-3 pt-4 -mt-2">
        {children}
        {action && (
          <div className="pt-3 border-t border-blackshade-700 mt-3">
            {action}
          </div>
        )}
      </div>
    </div>
  );
};

export default TableMobileCard;
