import { useState } from "react";
import { useAccount } from "wagmi";
import EmptyPage from "../components/EmptyPage";
import CardContainer from "./components/CardContainer";
import { useNFTDataQuery } from "@/queries/nft";
import { toast } from "react-toastify";
import { api } from "@/utils/axiosInstance";
import InfoBox from "./components/InfoBox";
import Button from "@/components/Common/Button";
import CardLoading from "./components/CardLoading";
import { DangerCircle, Nft, Refresh } from "@/components/Icons";
import { useToast } from "@/components/Common/Toast/utils";

const MyNFTs = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { address } = useAccount();
  const { data: nftList, isLoading: nftLoading } = useNFTDataQuery(address);
  const nftData = nftList ? Object.entries(nftList.user_tokens) : [];
  const { addToast } = useToast();

  const refreshPageHandler = () => {
    setIsLoading(true);
    api
      .post("/dashboard_refresh_nft/", {
        user_address: address,
      })
      .then((res) => {
        if (res.status === 200 || res.status === 202) {
          addToast("success", res.data.message);
        }
      })
      .catch((error) => {
        if (error.status === 429 || error.status === 202) {
          addToast("danger", error.response.data.message);
        }
      })
      .finally(() => setIsLoading(false));
  };
  return (
    <div className="">
      {nftData.length > 0 && (
        <>
          <InfoBox />
        </>
      )}
      {!nftLoading && (
        <div className="bg-dashboard-frame fram-effects rounded-[20px] px-6 py-3 my-6 md:my-8">
          <div className="flex items-center justify-between flex-wrap">
            <div className="flex gap-2 items-center">
              <div className="text-label-md text-white flex items-start gap-2">
                <div className=" h-6 w-6">
                  <DangerCircle className="text-gold-500 h-6 w-6" />
                </div>
                <div>
                  If you're experiencing issues with your NFT, please press the
                  'Reload NFTs' button once to refresh.
                </div>
              </div>
            </div>
            <Button
              model="outlined"
              size="md"
              onClick={refreshPageHandler}
              disabled={isLoading}
              className="w-full lg:w-auto mt-7 lg:mt-0"
              isLoading={isLoading}
            >
              <Refresh />
              Reload NFTs
            </Button>
          </div>
        </div>
      )}
      {nftLoading ? (
        <CardLoading />
      ) : nftData.length > 0 ? (
        <CardContainer nftData={nftData} />
      ) : (
        <EmptyPage
          icon={<Nft />}
          title="No NFT has been Found"
          info="As soon as you add your NFT, it will appear here"
        />
      )}
    </div>
  );
};

export default MyNFTs;
