import React, { useState, useEffect } from "react";
import Table from "./components/Table";
import { api } from "@/utils/axiosInstance";
import { useAccount } from "wagmi";
import { useLocation } from "react-router-dom";
import { Claim } from "./types";
const ClaimedHistory: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [claimedList, setClaimedlist] = useState<Claim[] | null>(null);
  const { address } = useAccount();
  const { pathname } = useLocation();

  const getClaimedHistory = async () => {
    setIsLoading(true);
    try {
      const response = await api.post<{ claimed_logs: Claim[] }>(
        "/dashboard_claimed_history/",
        { user_address: address }
      );
      setClaimedlist(response.data.claimed_logs);
    } catch (error) {
      console.error("Failed to fetch claimed history:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getClaimedHistory();
  }, [pathname]);

  return <Table isLoading={isLoading} list={claimedList ?? []} />;
};

export default ClaimedHistory;
