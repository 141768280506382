import img1 from "@/assets/images/post/blog-1.jpeg";
import img2 from "@/assets/images/post/blog-2.jpeg";
import img3 from "@/assets/images/post/blog-3.jpeg";
import img4 from "@/assets/images/post/blog-4.jpeg";
import img5 from "@/assets/images/post/blog-5.jpeg";
import imgheader1 from "@/assets/images/blog/1-image.png";
import imgheader2 from "@/assets/images/blog/2-image.png";
import imgheader3 from "@/assets/images/blog/3-image.png";
import imgheader4 from "@/assets/images/blog/4-image.png";
import imgheader5 from "@/assets/images/blog/5-image.png";
import imgbody1 from "@/assets/images/blog/1-header.png";
import imgbody2 from "@/assets/images/blog/2-header.png";
import imgbody3 from "@/assets/images/blog/3-header.png";
import imgbody4 from "@/assets/images/blog/4-header.png";
import imgbody5 from "@/assets/images/blog/5-header.png";

const blogData = [
  {
    id: 1,
    img: img1,
    category: "PCO Meta Earth",
    title: "Revolutionizing Digital Ownership with Polygon Blockchain",
    time: "20 Jun 2022",
    text: "In the ever-evolving landscape of digital assets and virtual environments ...",
    headerImg: imgheader1,
    paragraphs: [
      {
        title: "Introduction",
        text: "In the ever-evolving landscape of digital assets and virtual environments, PCO Meta Earth (PME) stands out as a pioneering project that merges the best of blockchain technology with immersive virtual experiences. Built on the robust Polygon blockchain, PME offers a unique platform where users can own, customize, and trade virtual land represented as NFT tokens.",
      },
      {
        title: "Full Ownership of Digital Assets ",
        text: "One of the core features of PCO Meta Earth is the complete ownership it grants users over their digital assets. Each piece of virtual land is represented as a non-fungible token (NFT) on the blockchain, ensuring transparency, security, and immutability in all transactions. This NFT-based ownership guarantees that users have indisputable rights to their virtual properties, allowing for secure buying, selling, and trading within the metaverse.",
      },
      {
        img: imgbody1,
        title: "The Power of Polygon Blockchain",
        text: "PCO Meta Earth leverages the Polygon blockchain, known for its scalability and low transaction costs. By utilizing Polygon, PME ensures fast and cost-effective transactions, which are crucial for a seamless user experience in a virtual environment. The integration of blockchain technology not only enhances security but also provides a transparent ledger of all asset transfers and transactions, fostering trust within the community.",
      },
      {
        title: "Creating a Thriving Digital Economy",
        text: "Beyond ownership, PME offers extensive opportunities for users to personalize and monetize their virtual land. From hosting events and opening virtual stores to developing interactive experiences, the possibilities are endless. Users can engage in direct transactions, auctions, and even decentralized finance (DeFi) activities such as staking and yield farming within the metaverse, creating a vibrant and dynamic virtual economy.",
      },
      {
        title: "Conclusion",
        text: " PCO Meta Earth is setting new standards in the digital ownership and virtual real estate space. By combining the transparency and security of the Polygon blockchain with immersive virtual experiences, PME provides a unique platform for users to explore, create, and profit in the digital world. Join PCO Meta Earth today and be a part of the future of digital ownership.",
      },
    ],
  },
  {
    id: 2,
    img: img2,
    category: "The Future of Metaverse",
    title: "How PCO Meta Earth Elevates Virtual Reality Experiences",
    time: "20 Jun 2022",
    text: " As the concept of the metaverse continues to gain traction, PCO Meta Earth (PME) ...",
    headerImg: imgbody2,
    paragraphs: [
      {
        title: "Introduction",
        text: "As the concept of the metaverse continues to gain traction, PCO Meta Earth (PME) is at the forefront, pushing the boundaries of what’s possible in virtual reality (VR) and augmented reality (AR). This innovative platform, built on the Polygon blockchain, integrates advanced technologies to offer users an unparalleled virtual experience.",
      },
      {
        title: "Advanced VR and AR Integration ",
        text: " PCO Meta Earth harnesses the power of VR and AR to create fully immersive digital environments. Virtual reality enables users to step into another world, experiencing a rich and interactive digital space. Augmented reality, on the other hand, overlays digital information onto the real world, enhancing everyday experiences with virtual elements. Together, these technologies blur the lines between the digital and physical worlds, providing users with a seamless and engaging experience.",
      },
      {
        img: imgheader2,
        title: "Spatial Computing for Natural Interactions",
        text: " Another key technology utilized by PME is spatial computing, which allows users to interact with digital objects as if they were physical. This natural interaction enhances the user experience, making it more intuitive and engaging. Spatial computing enables users to manipulate virtual objects in real-time, creating a more immersive and realistic virtual environment.",
      },
      {
        title: "AI-Powered Personalization",
        text: " Artificial intelligence (AI) plays a significant role in PCO Meta Earth, powering intelligent virtual assistants, dynamic content creation, and personalized user experiences. AI algorithms can generate unique content, respond to user interactions in real-time, and tailor the virtual environment to individual preferences. This level of personalization ensures that each user’s experience in PME is unique and tailored to their needs.",
      },
      {
        title: "Conclusion",
        text: " PCO Meta Earth is redefining the metaverse by integrating cutting-edge VR and AR technologies with the security and transparency of blockchain. The result is a platform that offers users a truly immersive and personalized virtual experience. Whether for entertainment, education, or social interaction, PCO Meta Earth is the future of virtual reality. Explore the limitless possibilities today with PME.",
      },
    ],
  },
  {
    id: 3,
    img: img3,
    category: "Decentralizeation",
    title: "Empowering Users in Platform Development",
    time: "20 Jun 2022",
    text: " Decentralization is at the heart of the blockchain revolution, and PCO Meta Earth (PME) ...",
    headerImg: imgbody3,
    paragraphs: [
      {
        title: "Introduction",
        text: "Decentralization is at the heart of the blockchain revolution, and PCO Meta Earth (PME) embraces this principle through its decentralized autonomous organization (DAO) governance model. This model empowers users to actively participate in the development and decision-making processes of the platform, ensuring a democratic and community-driven approach.",
      },
      {
        title: "DAO Governance Model",
        text: " In PCO Meta Earth, governance is conducted through a DAO structure where users can propose and vote on changes to the platform. This model ensures that the community has a significant voice in shaping the future of PME. By holding PME tokens, users gain voting power proportional to their stake, allowing them to influence key decisions and platform developments.",
      },
      {
        img: imgheader3,
        title: "Proposal and Voting Process ",
        text: "To maintain the integrity of the governance process, a minimum quorum of 15% of the total token supply is required for a valid proposal. Users must hold at least 1% of the total token supply to submit a proposal, preventing spam and ensuring that only serious suggestions are considered. The typical voting period is seven days, providing adequate time for the community to review and decide on proposals.",
      },
      {
        title: "Incentives for Participation",
        text: "Active participation in governance is incentivized through rewards and platform perks. Users who engage in the decision-making process can earn bonuses, encouraging a high level of involvement and ensuring that the platform evolves in line with the community’s needs and desires. Critical decisions may require a supermajority (e.g., 66% or 75%) for approval, ensuring broad support for major changes.",
      },
      {
        title: "Conclusion",
        text: "PCO Meta Earth’s DAO governance model is a testament to its commitment to decentralization and community involvement. By giving users the power to propose and vote on platform changes, PME fosters a democratic environment where every voice matters. Join the PCO Meta Earth community today and be a part of a truly decentralized metaverse platform.",
      },
    ],
  },
  {
    id: 4,
    img: img4,
    category: "Virtual Economy",
    title: "Opportunities for Digital Earnings in PCO Meta Earth",
    time: "20 Jun 2022",
    text: " PCO Meta Earth (PME) is not just a virtual playground; it is a dynamic platform offering ...",
    headerImg: imgbody4,
    paragraphs: [
      {
        title: "Introduction",
        text: "PCO Meta Earth (PME) is not just a virtual playground; it is a dynamic platform offering numerous opportunities for users to earn and thrive in a digital economy. By leveraging blockchain technology and NFTs, PME provides a robust ecosystem where users can acquire, personalize, and monetize virtual land and assets.",
      },
      {
        title: "Acquiring and Personalizing Virtual Land ",
        text: " In PCO Meta Earth, users can acquire virtual land through various means, including direct transactions, auctions, and a decentralized marketplace. Once acquired, users have complete control over their virtual property and can personalize it using a variety of tools and templates. This personalization allows users to create unique digital experiences and spaces that can attract other users and generate revenue.",
      },
      {
        img: imgheader4,
        title: "Monetization Strategies",
        text: "PME offers several monetization strategies for virtual landowners. Users can host events, rent out spaces, sell digital goods, and even offer services within their virtual properties. The integration of decentralized finance (DeFi) elements such as staking and yield farming provides additional avenues for earning within the metaverse. These diverse opportunities create a vibrant virtual economy where users can leverage their creativity and entrepreneurial skills.",
      },
      {
        title: "Engaging in the Virtual Economy",
        text: "Beyond land ownership and monetization, PCO Meta Earth features a thriving virtual economy where users can buy, sell, and trade virtual items and services. The use of blockchain technology ensures that all transactions are secure and transparent, fostering trust and reliability within the community. This active marketplace not only enhances user engagement but also contributes to the overall growth and sustainability of the platform.",
      },
      {
        title: "Conclusion",
        text: " PCO Meta Earth is a gateway to a flourishing virtual economy where users can earn and grow their digital wealth. By offering various ways to acquire, personalize, and monetize virtual land, PME empowers users to participate actively in a decentralized digital ecosystem. Explore the economic opportunities in PCO Meta Earth today and discover how you can turn your virtual assets into real value.",
      },
    ],
  },
  {
    id: 5,
    img: img5,
    category: "PME Token",
    title: " The Backbone of PCO Meta Earth’s Economy",
    time: "20 Jun 2022",
    text: " The PME token is the lifeblood of PCO Meta Earth’s (PME) ecosystem, playing a crucial role ...",
    headerImg: imgbody5,
    paragraphs: [
      {
        title: "Introduction",
        text: "The PME token is the lifeblood of PCO Meta Earth’s (PME) ecosystem, playing a crucial role in ownership, transactions, and governance within the metaverse. Built on the Polygon blockchain, the PME token provides users with unique opportunities to engage in the virtual economy and influence the platform’s development.",
      },
      {
        title: "Tokenomics and Distribution",
        text: "With a total supply of 35 billion PME tokens, the tokenomics of PME are designed to support a robust and sustainable ecosystem. The initial value of PME is set at $0.02 per token, with a market capitalization of $700 million. The token distribution includes allocations for community rewards, team, advisors, ecosystem development, partnerships, liquidity, staking rewards, community incentives, and a reserve fund. This diverse allocation ensures that the ecosystem is well-supported and incentivizes various stakeholders",
      },
      {
        img: imgheader5,
        title: "Governance and Participation",
        text: "PME token holders play a vital role in the governance of PCO Meta Earth. By holding PME tokens, users gain voting power proportional to their stake, enabling them to propose and vote on key decisions affecting the platform. The governance model ensures that the community has a significant voice in the platform's development and future direction, fostering a democratic and participatory environment.",
      },
      {
        title: "Transactions and Utility",
        text: "The PME token facilitates ownership and transactions within the metaverse, allowing users to buy, sell, and trade virtual land and assets. The integration with the Polygon blockchain ensures fast, secure, and cost-effective transactions. Additionally, the PME token can be used in various DeFi activities within the platform, such as staking and yield farming, providing users with multiple ways to earn and grow their digital assets.",
      },
      {
        title: "Conclusion",
        text: " The PME token is the cornerstone of PCO Meta Earth’s economy, enabling ownership, transactions, and governance within the platform. By participating in the PME ecosystem, users can take advantage of the numerous opportunities to earn, influence, and thrive in a decentralized digital world. Embrace the future of digital economy with PME token and join PCO Meta Earth today.",
      },
    ],
  },
];

export default blogData;
