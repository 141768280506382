import * as React from "react";
import type { SVGProps } from "react";
const SvgBanknote = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M17.21 1.83c-.781-.08-1.79-.08-3.21-.08H8c-1.42 0-2.429 0-3.21.08-.77.079-1.235.227-1.596.468a3.3 3.3 0 0 0-.896.896c-.241.361-.39.827-.468 1.596-.08.781-.08 1.79-.08 3.21s0 2.429.08 3.21c.079.77.227 1.235.468 1.596.237.355.542.66.896.896.361.241.827.39 1.596.468.781.08 1.79.08 3.21.08h6c1.42 0 2.429 0 3.21-.08.77-.079 1.235-.227 1.596-.468.354-.237.66-.541.896-.896.241-.361.39-.827.468-1.596.08-.781.08-1.79.08-3.21s0-2.429-.08-3.21c-.079-.77-.227-1.235-.468-1.596a3.3 3.3 0 0 0-.896-.896c-.361-.241-.827-.39-1.596-.468m.152-1.492c.888.09 1.629.28 2.277.713.519.346.964.791 1.31 1.31.434.648.623 1.39.713 2.277.088.866.088 1.951.088 3.32v.084c0 1.369 0 2.454-.088 3.32-.09.888-.28 1.629-.712 2.277a4.8 4.8 0 0 1-1.311 1.31c-.648.434-1.39.623-2.277.713-.866.088-1.951.088-3.32.088H7.958c-1.369 0-2.454 0-3.32-.088-.888-.09-1.629-.28-2.277-.713a4.8 4.8 0 0 1-1.31-1.31c-.434-.648-.623-1.39-.713-2.277C.25 10.496.25 9.411.25 8.042v-.084c0-1.369 0-2.454.088-3.32.09-.888.28-1.629.713-2.277a4.8 4.8 0 0 1 1.31-1.31C3.009.617 3.751.428 4.638.338 5.504.25 6.589.25 7.958.25h6.084c1.369 0 2.454 0 3.32.088M4.5 4.25a.75.75 0 0 1 .75.75v6a.75.75 0 0 1-1.5 0V5a.75.75 0 0 1 .75-.75m6.5 1.5a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5M7.25 8a3.75 3.75 0 1 1 7.5 0 3.75 3.75 0 0 1-7.5 0M17.5 4.25a.75.75 0 0 1 .75.75v6a.75.75 0 0 1-1.5 0V5a.75.75 0 0 1 .75-.75"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgBanknote;
