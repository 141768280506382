import * as React from "react";
import type { SVGProps } from "react";
const SvgInstagram = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#FFB70F"
      d="M18.395 7.022a1.44 1.44 0 1 1-.005-2.88 1.44 1.44 0 0 1 .005 2.88"
    />
    <path
      fill="#FFB70F"
      fillRule="evenodd"
      d="M12.012 18.161a6.161 6.161 0 1 1-.024-12.323 6.163 6.163 0 0 1 .024 12.323m-.02-10.16a4 4 0 1 0 .016 8 4 4 0 0 0-.016-8"
      clipRule="evenodd"
    />
    <path
      fill="#FFB70F"
      fillRule="evenodd"
      d="M4.12.646C4.88.348 5.752.143 7.03.083 8.31.022 8.72.008 11.977.001c3.259-.006 3.667.007 4.947.063 1.278.056 2.15.257 2.914.553.79.304 1.46.714 2.128 1.379a5.9 5.9 0 0 1 1.388 2.123c.298.763.502 1.634.563 2.912.06 1.28.075 1.687.082 4.946.006 3.258-.007 3.667-.063 4.948-.056 1.276-.257 2.15-.552 2.912a5.9 5.9 0 0 1-1.38 2.129 5.9 5.9 0 0 1-2.123 1.388c-.763.298-1.634.502-2.91.563-1.28.06-1.69.075-4.949.081-3.257.007-3.666-.006-4.946-.062-1.277-.056-2.15-.257-2.913-.552a5.9 5.9 0 0 1-2.129-1.38 5.9 5.9 0 0 1-1.388-2.123c-.298-.762-.502-1.634-.563-2.91-.061-1.28-.075-1.69-.082-4.948-.006-3.259.007-3.667.063-4.947C.12 5.8.321 4.926.616 4.162a5.9 5.9 0 0 1 1.38-2.128A5.9 5.9 0 0 1 4.12.646m.822 20.722c.423.163 1.058.357 2.228.408 1.266.055 1.645.067 4.849.06 3.204-.005 3.583-.019 4.849-.078 1.168-.056 1.804-.252 2.226-.417.56-.22.96-.48 1.378-.9.42-.421.679-.822.896-1.382.163-.424.357-1.06.408-2.23.055-1.264.067-1.644.06-4.848-.005-3.203-.019-3.583-.079-4.848-.055-1.17-.251-1.805-.417-2.227a3.7 3.7 0 0 0-.9-1.378c-.42-.42-.82-.679-1.382-.896-.422-.163-1.059-.357-2.228-.408-1.266-.055-1.645-.067-4.85-.06-3.203.006-3.582.019-4.847.079-1.17.055-1.804.251-2.227.417-.56.219-.959.479-1.379.9-.418.42-.678.82-.895 1.382C2.47 5.365 2.274 6 2.224 7.17c-.055 1.266-.067 1.645-.06 4.849.005 3.204.019 3.583.078 4.848.055 1.17.253 1.805.418 2.228.219.56.48.958.9 1.378.42.418.82.678 1.382.895"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgInstagram;
