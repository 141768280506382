import React from "react";
import {
  UserRounded,
  CartLargeMinimalistic,
  Nft,
  WalletMoney,
} from "@/components/Icons";

export const workData = [
  {
    id: 1,
    numb: "01",
    title: "Set up your account ",
    text: "Log into your account on our website and complete your profile for a personalized experience.",
    icon: <UserRounded />,
  },
  {
    id: 2,
    numb: "02",
    title: "Buy your PCO LAND",
    text: `Discover the excitement of PCO Land Earth! 
Explore the vibrant world of PCO Land Earth and secure your piece of land today!`,
    icon: <CartLargeMinimalistic />,
  },
  {
    id: 3,
    numb: "03",
    title: "Convert to land NFT's",
    text: `Choose the one that suits your taste. You can purchase land and grids, which can also be converted into NFTs. These can be used for various purposes such as residential, commercial, office, industrial, recreational, and more!`,
    icon: <Nft />,
  },
  {
    id: 4,
    numb: "04",
    title: "Sell/stake your NFT's",
    text: `You can sell your NFTs or stake them to earn a monthly income.
Depending on their use, you can stake them and receive rewards in return. 
This is happening for the first time in the metaverse!`,
    icon: <WalletMoney />,
  },
];
