import * as React from "react";
import type { SVGProps } from "react";
const SvgBuyLand = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    viewBox="0 0 32 32"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.437 7.124A12.3 12.3 0 0 0 3.667 16c0 6.812 5.521 12.334 12.333 12.334q.256 0 .507-.01c-.274-1.216-.31-2.85.525-4.425.87-1.638 2.67-2.349 3.993-2.674a12.3 12.3 0 0 1 2.53-.339h.059c2.23-.024 3.234-.749 3.768-1.44.455-.592.627-1.183.801-1.782l.068-.23q.081-.706.082-1.434c0-3.365-1.348-6.416-3.533-8.641l-.036.134c-.217.79-.567 1.602-.948 2.18-.342.518-1.003 1.04-1.553 1.435a13 13 0 0 1-1.201.748c-.305.175-.578.332-.841.506-.576.38-1.022.79-1.321 1.408a.9.9 0 0 0-.047.654c.1.362.168.77.169 1.184.002.866-.439 1.568-1.005 2.024-.56.452-1.292.71-2.028.702-3.274-.036-5.287-2.693-5.552-5.648-.106-1.175-.621-2.364-1.272-3.403a11.8 11.8 0 0 0-1.728-2.16m1.556-1.275a14 14 0 0 1 1.867 2.372c.744 1.188 1.425 2.683 1.569 4.286.201 2.24 1.617 3.805 3.582 3.827.27.003.55-.097.75-.259.194-.156.261-.32.26-.461 0-.208-.035-.437-.096-.66a2.88 2.88 0 0 1 .175-2.057c.513-1.058 1.274-1.712 2.018-2.204.32-.21.65-.4.948-.572l.119-.068c.34-.196.64-.373.911-.569.575-.413.939-.743 1.05-.911.243-.37.516-.979.69-1.61.137-.499.175-.882.162-1.12A12.28 12.28 0 0 0 16 3.667c-2.603 0-5.017.806-7.007 2.182M30.24 17.654q.093-.814.094-1.654c0-7.916-6.417-14.333-14.333-14.333S1.667 8.084 1.667 16 8.083 30.334 16 30.334c7.28 0 13.293-5.428 14.212-12.458q.036-.128.064-.209zm-3.7 4.755c-.794.291-1.75.464-2.904.476h-.042l-.12.005a10.232 10.232 0 0 0-1.97.277c-1.202.295-2.256.826-2.704 1.67-.608 1.144-.521 2.39-.295 3.242a12.35 12.35 0 0 0 8.035-5.67"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgBuyLand;
