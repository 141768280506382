import React from "react";
import TableMobileCard from "@/components/Common/TableMobileCard";
import { formatDate } from "@/utils/formatDate";
import { TagHorizontal } from "@/components/Icons";
import { MobileTableProps } from "../types";
const MobileTable: React.FC<MobileTableProps> = ({ data, isLoading }) => {
  return (
    <TableMobileCard
      header={
        <>
          <div className="flex gap-1 items-center pl-1">
            {" "}
            <TagHorizontal />
            <div className="text-body-lg">ID:</div>
            <span className="text-label-sm">
              {isLoading ? (
                <div className="bg-blackshade-600 rounded w-12 h-3 animate-pulse"></div>
              ) : (
                data?.stake_id
              )}
            </span>
          </div>
        </>
      }
    >
      <div className="border-b border-solid border-blackshade-800 pb-3 mb-3">
        <div className="flex items-center gap-1 text-blackshade-200 text-body-md">
          <span>Tx Hash</span>
        </div>
        <div className="text-white text-body-lg">
          {isLoading ? (
            <div className="bg-blackshade-600 rounded w-[8rem] h-3 animate-pulse"></div>
          ) : (
            <a
              href={`https://polygonscan.com/tx/${data?.tx_hash}`}
              target="_blank"
              rel="noopener noreferrer"
              className="text-white underline"
            >
              {data?.tx_hash.substring(0, 32)}...
            </a>
          )}
        </div>
      </div>
      <div className="flex justify-between items-center mt-2">
        <div className="flex items-center gap-1 text-blackshade-200 text-body-md">
          <span>Date</span>
        </div>
        <div className="text-white text-body-lg">
          {isLoading ? (
            <div className="bg-blackshade-600 rounded w-14 h-3 animate-pulse"></div>
          ) : data ? (
            formatDate(data.date)
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="flex justify-between items-center mt-2">
        <div className="flex items-center gap-1 text-blackshade-200 text-body-md">
          <span>PME Amount</span>
        </div>
        <div className="text-white text-body-lg">
          {isLoading ? (
            <div className="bg-blackshade-600 rounded w-12 h-3 animate-pulse"></div>
          ) : (
            data?.pme_amount
          )}
        </div>
      </div>
      <div className="flex justify-between items-center mt-2">
        <div className="flex items-center gap-1 text-blackshade-200 text-body-md">
          <span>PMG Amount</span>
        </div>
        <div className="text-white text-body-lg">
          {isLoading ? (
            <div className="bg-blackshade-600 rounded w-12 h-3 animate-pulse"></div>
          ) : (
            data?.pmg_amount
          )}
        </div>
      </div>
    </TableMobileCard>
  );
};

export default MobileTable;
