import * as React from "react";
import type { SVGProps } from "react";
const SvgHistory = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={26}
    height={26}
    fill="none"
    viewBox="0 0 26 26"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3.772 3.759c5.06-5.054 13.286-5 18.378.091 5.093 5.094 5.145 13.325.085 18.385S8.944 27.243 3.85 22.15A13.08 13.08 0 0 1 .12 11.108a1 1 0 1 1 1.982.272 11.08 11.08 0 0 0 3.163 9.355c4.326 4.326 11.29 4.351 15.556.086 4.265-4.266 4.24-11.23-.086-15.556C16.412.94 9.452.914 5.185 5.173l.998.005a1 1 0 0 1-.01 2L2.779 7.16a1 1 0 0 1-.995-.995l-.017-3.394a1 1 0 0 1 2-.01zM13 6.667a1 1 0 0 1 1 1v4.919l3.04 3.04a1 1 0 0 1-1.414 1.414L12 13.414V7.667a1 1 0 0 1 1-1"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgHistory;
