import React, { useEffect } from "react";
import {
  Viewer,
  Math as CesiumMath,
  Cartesian3,
  Color,
  BoundingSphere,
  Ion,
  ArcGisMapService,
  ArcGisMapServerImageryProvider,
  ArcGisBaseMapType,
  ImageryLayer,
  ScreenSpaceEventType,
  Primitive,
  PerInstanceColorAppearance,
} from "cesium";
import {
  snapToGrid,
  taafBoundary,
  marginDegrees,
  gridCellWidthDegrees,
  gridCellHeightDegrees,
  createCellPolygon,
} from "@/components/Pages/Public/BuyLand/helpers";


Ion.defaultAccessToken = process.env.REACT_APP_CESIUM_KEY;
ArcGisMapService.defaultAccessToken = process.env.REACT_APP_ARCGIS_KEY;
const arcGisImagery = ArcGisMapServerImageryProvider.fromBasemapType(
  ArcGisBaseMapType.SATELLITE
);
const Map = ({ setIsMapLoaded, landDetails, viewerRef }) => {
  useEffect(() => {
    const viewer = new Viewer("cesiumNFTContainer", {
      selectedImageryProviderViewModels: [],
      selectedImageryProviderViewModel: null,
      geocoder: false,
      homeButton: false,
      infoBox: false,
      timeline: false,
      navigationHelpButton: false,
      sceneModePicker: false,
      animation: false,
      fullscreenButton: false,
      vrButton: false,
      selectionIndicator: false,
      shouldAnimate: false,
      baseLayerPicker: false,
      imageryProvider: false,
      baseLayer: ImageryLayer.fromProviderAsync(arcGisImagery),
    });

    viewerRef.current = viewer;
    // Disable user interactions
    const controller = viewer.scene.screenSpaceCameraController;
    controller.enableRotate = false;
    controller.enableZoom = false;
    controller.enableTranslate = false;
    controller.enableTilt = false;
    controller.enableLook = false;
    // Compute bounding box
    let minLati = Number.MAX_VALUE;
    let maxLat = -Number.MAX_VALUE;
    let minLoni = Number.MAX_VALUE;
    let maxLon = -Number.MAX_VALUE;
    // Remove click and double-click interactions
    viewer.screenSpaceEventHandler.removeInputAction(
      ScreenSpaceEventType.LEFT_CLICK
    );
    viewer.screenSpaceEventHandler.removeInputAction(
      ScreenSpaceEventType.LEFT_DOUBLE_CLICK
    );

    landDetails.tilesCoordination.forEach(({ latitude, longitude }) => {
      if (latitude < minLati) minLati = latitude;
      if (latitude > maxLat) maxLat = latitude;
      if (longitude < minLoni) minLoni = longitude;
      if (longitude > maxLon) maxLon = longitude;
    });

    // Ensure coordinates are valid
    if (isNaN(minLati) || isNaN(maxLat) || isNaN(minLoni) || isNaN(maxLon)) {
      console.error("Invalid coordinates detected");
      return;
    }

    // Compute bounding sphere manually
    const centerLat = (minLati + maxLat) / 2;
    const centerLon = (minLoni + maxLon) / 2;
    const center = Cartesian3.fromDegrees(centerLon, centerLat);
    const radius =
      Cartesian3.distance(
        Cartesian3.fromDegrees(minLoni, minLati),
        Cartesian3.fromDegrees(maxLon, maxLat)
      ) / 2;

    const boundingSphere = new BoundingSphere(center, radius);
    const distanceScale = 9.5; // Increase this value to zoom out further or decrease to zoom in
    const zoomedDistance = boundingSphere.radius * distanceScale;

    // Fly to the bounding rectangle
    viewer.camera.flyTo({
      destination: boundingSphere.center,
      orientation: {
        heading: CesiumMath.toRadians(0), // No rotation
        pitch: CesiumMath.toRadians(-90), // Looking straight down
        roll: CesiumMath.toRadians(0),
      },
      duration: 0, // Adjust duration as needed
      complete: () => {
        viewer.camera.lookAt(
          boundingSphere.center,
          new Cartesian3(0.0, 0.0, zoomedDistance) // Adjust zoom level
        );
      },
    });
    const cameraExtent = viewer.camera.computeViewRectangle();

    if (!cameraExtent) {
      console.error("Camera extent is undefined");
      return;
    }

    let startLongitude =
      CesiumMath.toDegrees(cameraExtent.west) - marginDegrees;
    let startLatitude =
      CesiumMath.toDegrees(cameraExtent.south) - marginDegrees;

    const { minLat, minLon } = taafBoundary;
    startLongitude = Math.max(startLongitude, minLon);
    startLatitude = Math.max(startLatitude, minLat);

    startLongitude = snapToGrid(startLongitude, gridCellWidthDegrees);
    startLatitude = snapToGrid(startLatitude, gridCellHeightDegrees);

    // Create a list of geometry instances for the primitives
    const geometryInstances = landDetails.tilesCoordination.map(
      ({ latitude, longitude }) => {
        const col = Math.floor(
          (longitude - startLongitude) / gridCellWidthDegrees
        );
        const row = Math.floor(
          (latitude - startLatitude) / gridCellHeightDegrees
        );

        const cellMinLon = startLongitude + col * gridCellWidthDegrees;
        const cellMaxLon = cellMinLon + gridCellWidthDegrees;
        const cellMinLat = startLatitude + row * gridCellHeightDegrees;
        const cellMaxLat = cellMinLat + gridCellHeightDegrees;

        const coordinates = { cellMinLon, cellMinLat, cellMaxLon, cellMaxLat };

        const color =
          Color.fromCssColorString("rgb(20, 194, 130)").withAlpha(0.6);

        return createCellPolygon({ coordinates, color });
      }
    );

    // Add primitives to the scene
    const newPrimitive = new Primitive({
      geometryInstances,
      appearance: new PerInstanceColorAppearance(),
      asynchronous: false,
    });

    viewer.scene.primitives.add(newPrimitive);

    return () => {
      if (viewer) {
        viewer.scene.primitives.remove(newPrimitive);
        viewer.destroy();
      }
    };
  }, [landDetails]);

  useEffect(() => {
    if (viewerRef.current) {
      const viewer = viewerRef.current;
      let initialLoadComplete = false;
      viewer.scene.globe?.tileLoadProgressEvent.addEventListener(function (
        remainingTiles
      ) {
        if (!initialLoadComplete && remainingTiles === 0) {
          initialLoadComplete = true;
          const checkPrimitivesAdded = () => {
            const primitivesAdded = viewer.scene.primitives.length > 0;
            if (primitivesAdded) {
              setIsMapLoaded(true);
            }
          };

          checkPrimitivesAdded();
          viewer.scene.postRender.addEventListener(checkPrimitivesAdded);
        }
      });
    }
  }, []);
  return <div id="cesiumNFTContainer" className="cesium-nft-container overflow-hidden rounded-xl"></div>;
};

export default Map;
