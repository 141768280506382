import * as React from "react";
import type { SVGProps } from "react";
const SvgCheckedBox = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={41}
    viewBox="0 0 40 41"
    fill="none"
    {...props}
  >
    <rect
      width={18.5}
      height={18.5}
      x={10.75}
      y={11.25}
      fill="#FFB70F"
      stroke="#FFB70F"
      strokeWidth={1.5}
      rx={7.25}
    />
    <path
      fill="#121212"
      d="M18.578 24.5c-.2 0-.39-.09-.53-.246l-2.83-3.158a.91.91 0 0 1 0-1.183.7.7 0 0 1 1.06 0l2.3 2.567 5.14-5.737a.7.7 0 0 1 1.06 0c.29.323.29.86 0 1.183l-5.67 6.328a.71.71 0 0 1-.53.246"
    />
  </svg>
);
export default SvgCheckedBox;
