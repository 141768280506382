import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar, A11y, Navigation, Autoplay } from "swiper";
import newsData from "../../consts/newsData";
import wave from "@/scss/images/Blog-Wave.png";

import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";

const News = () => {
  return (
    <section className="mt-[14rem] desktop:mt-[33rem] relative">
      <img
        src={wave}
        alt="wave"
        className="absolute left-0 right-0 w-full -top-[7rem] desktop:-top-[28rem]"
      />
      <div className="">
        <div
          className="text-center mb-10 desktop:mb-14"
          data-aos="fade-up"
          data-aos-duration="800"
        >
          <h3 className="text-label-md desktop:text-subheading-md text-blackshade-200 mb-3">
            NEWS
          </h3>
          <h4 className="text-headline-md desktop:text-display-sm text-white">
            WE ARE IN <span className="text-gradiant">NEWS</span>
          </h4>
        </div>{" "}
        <div className="" data-aos="fade-up" data-aos-duration="800">
          <Swiper
            navigation={{
              nextEl: ".blog-button-next",
              prevEl: ".blog-button-prev",
            }}
            loop={true}
            autoplay={{
              delay: 8000,
              disableOnInteraction: false,
            }}
            modules={[Scrollbar, A11y, Navigation, Autoplay]}
            scrollbar={{ draggable: true }}
            breakpoints={{
              0: {
                slidesPerView: 4,
                spaceBetween: 10,
              },
              767: {
                slidesPerView: 5,
                spaceBetween: 15,
              },
              991: {
                slidesPerView: 7,
                spaceBetween: 30,
              },
            }}
          >
            {newsData.slice(0, 6).map((item, idx) => (
              <SwiperSlide
                key={item.id}
                className="h-14 p-3 desktop:h-[100px] bg-dashboard-frame rounded-2xl desktop:p-6 flex items-center justify-center max-w-max"
              >
                <a
                  href={item.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="image"
                  key={idx}
                >
                  <img src={item.img} alt="PCO" />
                </a>
              </SwiperSlide>
            ))}
          </Swiper>
          <Swiper
            loop={true}
            navigation={{
              nextEl: ".blog-button-next",
              prevEl: ".blog-button-prev",
            }}
            autoplay={{
              delay: 9000,
              disableOnInteraction: false,
            }}
            modules={[Scrollbar, A11y, Navigation, Autoplay]}
            scrollbar={{ draggable: true }}
            className="mt-6"
            breakpoints={{
              0: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              767: {
                slidesPerView: 4,
                spaceBetween: 15,
              },
              991: {
                slidesPerView: 6,
                spaceBetween: 30,
              },
            }}
          >
            {newsData.slice(6).map((item, idx) => (
              <SwiperSlide
                key={item.id}
                className="h-14 p-3 desktop:h-[100px] bg-dashboard-frame rounded-2xl desktop:p-6 flex items-center justify-center max-w-max"
              >
                <a
                  href={item.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="image"
                  key={idx}
                >
                  <img src={item.img} alt="PCO" />
                </a>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default News;
