import pmeex from "@/assets/images/logo/PMEEX.png";
import p2b from "@/assets/images/logo/P2B.png";
import lBank from "@/assets/images/logo/LBANK.png";

const exchangesData = [
  {
    img: pmeex,
    link: "https://www.pmeex.com/trading/pmeusdt",
  },
  {
    img: p2b,
    link: "https://p2pb2b.com/trade/PME_USDT/",
  },
  {
    img: lBank,
    link: "https://www.lbank.com/trade/pme_usdt",
  },
];

export default exchangesData;
