import React from "react";
import { useIsMobile } from "@/hooks/useIsMobile";
import { formatDate } from "@/utils/formatDate";
import Table from "@/components/Common/Table";
import { History } from "@/components/Icons";
import MobileTable from "./MobileTable";
import { BaseTableProps } from "../types";
import EmptyPage from "../../components/EmptyPage";

const BaseTable: React.FC<BaseTableProps> = ({ list, isLoading }) => {
  const isMobile = useIsMobile(1300);

  const columns = [
    { header: "ID", key: "id", width: "30px" },
    { header: "Tx Hash", key: "txHash", width: "35%" },
    { header: "Date", key: "date", width: "20%" },
    { header: "PME Amount", key: "pme", width: "12%" },
    { header: "PMG Amount", key: "pmg", width: "12%" },
  ];

  const data =
    list && list.length > 0
      ? list.map((claim) => ({
          id: claim.stake_id,
          txHash: (
            <a
              href={`https://polygonscan.com/tx/${claim.tx_hash}`}
              target="_blank"
              rel="noopener noreferrer"
              className="text-white border-b border-solid border-white pb-1"
            >
              {claim.tx_hash.substring(0, 32)}...
            </a>
          ),
          date: formatDate(claim.date),
          pme: claim.pme_amount,
          pmg: claim.pmg_amount,
        }))
      : [];

  return (
    <div>
      {list && list.length === 0 && !isLoading && (
        <EmptyPage
          icon={<History />}
          title="you don’t have any claimed history"
          info="As soon as you invite your first friend, it will appear here"
        />
      )}
      {isMobile ? (
        list ? (
          <div className="grid grid-cols-12 gap-6">
            {list.map((item) => (
              <div key={item.stake_id} className="col-span-12 md:col-span-6">
                <MobileTable data={item} />
              </div>
            ))}
          </div>
        ) : (
          ""
        )
      ) : (list && list.length > 0) || isLoading ? (
        <Table columns={columns} data={data} isLoading={isLoading} />
      ) : null}
      {isLoading ? (
        <div className="grid grid-cols-12 gap-6">
          {Array.from({ length: 3 }).map((_, index) => (
            <div key={index} className="col-span-12 md:col-span-6">
              <MobileTable isLoading={true} />
            </div>
          ))}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default BaseTable;
