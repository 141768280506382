import React from "react";
import { workData } from "../../consts/workData";

const Work = () => {
  return (
    <section
      className="text-center desktop:pt-[200px] mb-16 desktop:mb-[28rem] relative"
      id="features"
    >
      <div className="container">
        <div data-aos="fade-up" data-aos-duration="800">
          <h3 className="text-subheading-md text-blackshade-200 mb-3">
            How we work
          </h3>
          <h4 className="text-display-sm text-white">
            BECOME OUR <span className="text-gradiant">MEMBER</span>
          </h4>
        </div>
        <div
          className="mt-6 desktop:mt-14 grid grid-cols-12"
          data-aos="fade-up"
          data-aos-duration="900"
        >
          {workData.map((item) => (
            <div
              key={item.id}
              className="col-span-12 lg:col-span-6 desktop:col-span-3 py-4 desktop:py-0 px-3"
            >
              <div className="desktop:h-full">
                <div className="text-display-sm text-gold-800">{item.numb}</div>
                <div className="bg-dashboard-frame frame-effects rounded-3xl p-4 mt-6 desktop:h-[443px]">
                  <div className="gradient-border-box h-[156px] ">
                    <div className="bg-informative-frame-sm w-full h-full rounded-[20px] flex justify-center items-center">
                      <div className="[&>svg]:h-16 [&>svg]:w-16 [&>svg]:text-gold-500">
                        {item.icon}
                      </div>
                    </div>
                  </div>

                  <div className="my-2 text-subheading-md text-white">
                    {item.title}
                  </div>
                  <div className="text-label-lg text-blackshade-200">
                    {item.text}
                  </div>
                </div>
              </div>
              <div className="bg-middle-linear w-full h-[1px] mt-4 desktop:mt-6" />
            </div>
          ))}
        </div>
      </div>
      <div className="overflow-hidden mt-1 desktop:mt-12">
        <div className="scene">
          <div className="bottom">
            <div className="bottom-overlay"></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Work;
