import React from "react";
import background from "@/scss/images/hero-header.png";
import earth from "@/scss/images/earth.png";
import World from "./World";
const HeroHeader = () => {
  return (
    <div
      className="pb-[.5rem]  desktop:mb-0 desktop:pb-[rem]  relative overflow-hidden  "
      // style={{ backgroundImage: `url(${background})` }}
    >
      <img src={background} alt="hero-header" className="h-[450px] desktop:h-[750px] absolute left-0 right-0 w-full" />
      <div className="flex flex-col items-center justify-center h-full pt-[67px] desktop:pt-0">
        <div
          className="mb-[6.5rem] text-center desktop:mt-[250px] desktop:mb-[1.5rem]"
          data-aos="fade-up"
          data-aos-duration="800"
        >
          <h2 className="text-headline-lg desktop:text-display-md text-white">
            WELCOME TO{" "}
            <span className="text-gradiant block desktop:inline-block">
              PCO META EARTH
            </span>
          </h2>
          <p className="max-w-[285px] mx-auto desktop:max-w-max text-label-lg desktop:text-subheading-md text-blackshade-200 desktop:mt-4">
            Revolutionizing Digital Ownership with Polygon Blockchain
          </p>
        </div>
        <div
          className="-mt-[18rem] h-[800px] desktop:h-[980px] desktop:mt-[3rem] w-full"
        >
          <World />
        </div>
      </div>{" "}
      <div className="">
        <div className="star"></div>
        {Array.from({ length: 15 }).map((_, index) => (
          <div className={`meteor-${index + 1}`} key={index}></div>
        ))}
      </div>
    </div>
  );
};

export default HeroHeader;
