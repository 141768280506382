import * as React from "react";
import type { SVGProps } from "react";
const SvgBigArrowRight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path fill="#FFB70F" d="M4 11.25a.75.75 0 0 0 0 1.5zm0 1.5h16v-1.5H4z" />
    <path
      stroke="#FFB70F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m14 6 6 6-6 6"
    />
  </svg>
);
export default SvgBigArrowRight;
