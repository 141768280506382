import * as React from "react";
import type { SVGProps } from "react";
const SvgSuccessStatus = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={64}
    height={64}
    fill="none"
    {...props}
  >
    <path
      fill="#121212"
      d="M42.744 26.744a2 2 0 0 0-2.828-2.828l-11.92 11.919-3.918-3.92a2 2 0 1 0-2.829 2.83l5.334 5.333a2 2 0 0 0 2.828 0z"
    />
    <path
      fill="#17C964"
      d="M42.744 26.744a2 2 0 0 0-2.828-2.828l-11.92 11.919-3.918-3.92a2 2 0 1 0-2.829 2.83l5.334 5.333a2 2 0 0 0 2.828 0z"
    />
    <path
      fill="#121212"
      fillRule="evenodd"
      d="M31.997 3.33C16.165 3.33 3.33 16.165 3.33 31.997s12.835 28.666 28.667 28.666S60.663 47.83 60.663 31.997 47.83 3.33 31.997 3.33M7.33 31.997C7.33 18.374 18.374 7.33 31.997 7.33s24.666 11.044 24.666 24.667S45.62 56.663 31.997 56.663 7.33 45.62 7.33 31.997"
      clipRule="evenodd"
    />
    <path
      fill="#17C964"
      fillRule="evenodd"
      d="M31.997 3.33C16.165 3.33 3.33 16.165 3.33 31.997s12.835 28.666 28.667 28.666S60.663 47.83 60.663 31.997 47.83 3.33 31.997 3.33M7.33 31.997C7.33 18.374 18.374 7.33 31.997 7.33s24.666 11.044 24.666 24.667S45.62 56.663 31.997 56.663 7.33 45.62 7.33 31.997"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSuccessStatus;
