import * as React from "react";
import type { SVGProps } from "react";
const SvgNft = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={27}
    height={30}
    viewBox="0 0 27 30"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.1}
      d="M16.446 1.651a5.27 5.27 0 0 0-5.25 0l.025.043-.025-.043-7.621 4.4A5.26 5.26 0 0 0 .95 10.598v8.8a5.26 5.26 0 0 0 2.625 4.547l7.62 4.4c.81.467 1.718.701 2.626.701a5.25 5.25 0 0 0 2.625-.7l7.621-4.401a5.26 5.26 0 0 0 2.625-4.547v-8.8a5.26 5.26 0 0 0-2.625-4.547zM4.625 7.871l7.62-4.4V3.47a3.15 3.15 0 0 1 3.15 0l7.622 4.4a3.16 3.16 0 0 1 1.575 2.728v.216c-7.468 1.147-13.87 6.174-16.774 13.155l-3.193-1.843a3.16 3.16 0 0 1-1.575-2.728v-8.8c0-1.122.603-2.167 1.575-2.728Zm7.62 18.655-2.59-1.496c2.533-6.34 8.238-10.951 14.937-12.094v6.462a3.16 3.16 0 0 1-1.575 2.728l-7.621 4.4a3.15 3.15 0 0 1-3.15 0ZM9.52 14.713a3.554 3.554 0 0 0 3.55-3.55 3.554 3.554 0 0 0-3.55-3.55 3.554 3.554 0 0 0-3.55 3.55 3.554 3.554 0 0 0 3.55 3.55Zm0-5c.8 0 1.45.65 1.45 1.45s-.65 1.45-1.45 1.45-1.45-.65-1.45-1.45.65-1.45 1.45-1.45Z"
    />
  </svg>
);
export default SvgNft;
