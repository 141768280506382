import * as React from "react";
import type { SVGProps } from "react";
const SvgDollar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8 1.833a6.167 6.167 0 1 0 0 12.333A6.167 6.167 0 0 0 8 1.833M.833 8a7.167 7.167 0 1 1 14.334 0A7.167 7.167 0 0 1 .833 8M8 3.5a.5.5 0 0 1 .5.5v.21c1.087.195 2 1.012 2 2.123a.5.5 0 0 1-1 0c0-.452-.376-.931-1-1.102v2.313c1.087.195 2 1.011 2 2.122s-.913 1.928-2 2.123v.21a.5.5 0 0 1-1 0v-.21c-1.087-.195-2-1.012-2-2.123a.5.5 0 0 1 1 0c0 .453.376.931 1 1.102V8.455c-1.087-.194-2-1.011-2-2.122s.913-1.928 2-2.122V4a.5.5 0 0 1 .5-.5M7.5 5.23c-.624.17-1 .65-1 1.102s.376.931 1 1.102zm1 3.334v2.203c.624-.17 1-.65 1-1.102s-.376-.93-1-1.101"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgDollar;
