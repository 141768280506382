import React from "react";
import phoenix from "@/scss/images/phoenix.png";
import data from "../../consts/aboutData";

const About: React.FC = () => {
 
  return (
    <section id="about" className="-mt-[22rem] desktop:-mt-[28rem]">
      <div className="container">
        <div
          className="w-full mb-6 relative"
          data-aos="fade-up"
          data-aos-duration="800"
        >
          <div className="h-full flex p-4 desktop:gap-[107px] rounded-3xl  desktop:py-10 desktop:px-8  gradient-border-box">
            <div className=" mr-auto">
              <div className="text-headline-md desktop:text-display-md mb-1 desktop:mb-4 pr-[6rem] desktop:pr-0">
                ABOUT <span className="text-gradiant">PCO META EARTH</span>
              </div>
              <p className="text-label-md desktop:text-title-lg text-blackshade-200 desktop:w-[850px]">
                PCO Meta Earth (PME) is a groundbreaking metaverse platform
                built on the Polygon blockchain. Our mission is to create a
                dynamic digital ecosystem where users can fully own, customize,
                and monetize their virtual land through advanced technologies
                such as VR, AR, AI, and blockchain.
              </p>
            </div>
            <img
              src={phoenix}
              alt="PCO"
              className="h-[116px] desktop:h-[327px] desktop:-mt-[2.5rem] absolute right-4 -top-12 desktop:right-8"
            />
          </div>
        </div>
        <div
          className="flex gap-8 desktop:gap-6 flex-wrap"
          data-aos="fade-up"
          data-aos-duration="800"
        >
          {data.map((item, index) => (
            <div
              key={index}
              className="w-full desktop:flex-1 frame-effects bg-informative-frame-sm rounded-2xl py-6 px-3 relative"
            >
              <div className="text-title-lg mb-2 text-white">{item.title}</div>
              <div className="text-title-md text-blackshade-200">
                {item.desc}
              </div>
              <div className="bg-horizontal-linear w-full absolute -bottom-4 desktop:-bottom-6 h-[1px] left-0"></div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default About;
