import axios from "axios";
import axiosRetry from "axios-retry";
import { toast } from "react-toastify";

// Create an instance of Axios with default configuration
export const api = axios.create({
  baseURL: process.env.REACT_APP_API_KEY,
  timeout: 120000, // Request timeout
  headers: {
    "Content-Type": "application/json",
    // "X-CSRFToken": document.querySelector("[name=csrfmiddlewaretoken]").value,
    // Add any headers you need for all requests
  },
});
// Configure axios-retry
axiosRetry(api, {
  retries: Infinity,
  shouldResetTimeout: true,
  retryCondition: (error) => {
    // toast.error(error.message)
    console.log("Retrying due to error:", error); // Log error before retry
    // return error.response && error.response.status >= 500; // Retry only on server errors
  },
  retryDelay: (retryCount) => {
    return 30000; // .5 minute delay between retries
  },
});
