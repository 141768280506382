import { Cartesian3 } from "cesium";

export const parseCoordinates = (input) => {
  const shapes = input.map((str) => {
    const coords = str.split(",").map((coord) => {
      const [x, y] = coord.trim().split(":").map(Number);
      return { x, y };
    });
    return coords;
  });
  return shapes;
};
export const parseTilesData = (data) => {
  // Split the data into individual entries
  const entries = data.split(",").map((entry) => entry.trim());

  // Parse each entry into an object with x and y properties
  const parsedData = entries.map((entry) => {
    const [x, y] = entry.split(":").map(Number); // Convert x and y to numbers
    return { x, y };
  });

  return parsedData;
};
// Convert parsed tiles data to Cartesian coordinates
export const convertToCartesian = (coords) => {
  return coords.map((coord) => Cartesian3.fromDegrees(coord.x, coord.y));
};
const convertToLatLng = (tile) => {
  return {
    lat: tile.y / 100000,
    lng: tile.x / 100000,
  };
};
export const generateMapLink = (metadata) => {
  let formatedArray = [];

  formatedArray = metadata.split(", ").map((coord) => {
    const [lat, lon] = coord.split(":").map(Number);
    return { latitude: lat, longitude: lon };
  });

  // Convert the first tile to lat/lng for demonstration purposes
  // const { lat, lng } = convertToLatLng(tiles[0]);

  return `/lands?lat=${formatedArray[0].latitude}&lng=${formatedArray[0].longitude}`;
};

export const convertTilesToString = (tiles) => {
  return tiles
    .map((tile) => {
      // Use coordinates.minLon and minLat, or choose another coordinate pair
      const lon = tile.centerLon; // Or use cellMaxLon
      const lat = tile.centerLat; // Or use cellMaxLat
      return `${lat}:${lon}`;
    })
    .join(", ");
};
