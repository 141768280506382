const roadMapData = [
  {
    id: 1,
    time: "May 2021",
    title: "Airdrop",
    list: [
      {
        text: "Distribution of initial tokens to early adopters.",
      },
    ],
    positon: "left",
    style: "normal",
  },
  {
    id: 2,
    time: "May 2021",
    title: "pre ICO",
    list: [
      {
        text: "Early investment opportunity before the main ICO launch.",
      },
    ],
    positon: "right",
    style: "normal",
  },
  {
    id: 3,
    time: "May 2022",
    title: "ICO",
    list: [
      {
        text: "Official Initial Coin Offering to the public.",
      },
    ],
    positon: "left",
  },
  {
    id: 4,
    time: "Nov 2023",
    title:"Pmeex",
    list: [
      {
        text: "Launch of the Pmeex exchange platform.",
      },
    ],
    positon: "right",
  },
  {
    id: 5,
    time: "May 2024",
    title:"Swap",
    list: [
      {
        text: "Token swap event converting PCOIN to PME.",
      },
    ],
    positon: "left",
  },
  {
    id: 6,
    time: "May 2024",
    title:"IEO",
    list: [
      {
        text: "Initial Exchange Offering to further expand token reach.",
      },
    ],
    positon: "right",
  },
  {
    id: 7,
    time: "May 2024",
    title:"Metaverse",
    list: [
      {
        text: "Launch of the PCO Meta Earth metaverse platform.",
      },
    ],
    positon: "right",
  },
];

export default roadMapData;
