import Button from "@/components/Common/Button";
import React from "react";

const ActionButtons = ({
  land,
  handleConvertToNFT,
  ComingSoonMessage,
  isLoading,
}) => {
  return (
    <>
      {land?.is_nft && !isLoading ? (
        ""
      ) : (
        <>
          <Button
            onClick={() =>
              handleConvertToNFT(
                land.tiles_coordination,
                land.land_id,
                land.purchasepricetoken,
                land.token_type,
                land.approve_token
              )
            }
            disabled={isLoading}
            model="info"
            size="sm"
          >
            Convert to NFT
          </Button>
          <Button
            model="error"
            size="sm"
            onClick={ComingSoonMessage}
            disabled={isLoading}
          >
            Sell
          </Button>
        </>
      )}
    </>
  );
};

export default ActionButtons;
