import * as React from "react";
import type { SVGProps } from "react";
const SvgSidebarTick = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={24}
    fill="none"
    {...props}
  >
    <path stroke="url(#sidebar-tick_svg__a)" d="M36 12.5H0" />
    <g filter="url(#sidebar-tick_svg__b)">
      <circle cx={36} cy={12} r={4} fill="#FFB70F" />
    </g>
    <g filter="url(#sidebar-tick_svg__c)">
      <circle cx={36} cy={12} r={4} fill="#FFB70F" />
    </g>
    <defs>
      <filter
        id="sidebar-tick_svg__b"
        width={22.8}
        height={22.8}
        x={24.6}
        y={0.6}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur
          result="effect1_foregroundBlur_105_888"
          stdDeviation={3.7}
        />
      </filter>
      <filter
        id="sidebar-tick_svg__c"
        width={9.8}
        height={9.8}
        x={31.1}
        y={7.1}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur
          result="effect1_foregroundBlur_105_888"
          stdDeviation={0.45}
        />
      </filter>
      <linearGradient
        id="sidebar-tick_svg__a"
        x1={36}
        x2={0}
        y1={11.5}
        y2={11.5}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C5840B" />
        <stop offset={1} stopColor="#121212" stopOpacity={0} />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgSidebarTick;
