import * as React from "react";
import type { SVGProps } from "react";
const SvgYellowOrbit = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={288}
    height={283}
    fill="none"
    viewBox="0 0 288 288"
    {...props}
  >
    <circle cx={147.5} cy={142.5} r={99} stroke="url(#yellow-orbit_svg__a)" />
    <circle cx={147.5} cy={142.5} r={61} stroke="url(#yellow-orbit_svg__b)" />
    <circle cx={147} cy={142} r={140.5} stroke="url(#yellow-orbit_svg__c)" />
    <g filter="url(#yellow-orbit_svg__d)"className="flashing">
      <circle cx={8} cy={135} r={2} fill="#FFB70F" />
    </g>
    <g filter="url(#yellow-orbit_svg__e)" className="flashing">
      <circle cx={103} cy={100} r={2} fill="#FFB70F" />
    </g>
    <g filter="url(#yellow-orbit_svg__f)"className="flashing">
      <circle cx={190} cy={8} r={2} fill="#FFB70F" />
    </g>
    <g filter="url(#yellow-orbit_svg__g)"className="flashing">
      <circle cx={245} cy={160} r={2} fill="#FFB70F" />
    </g>
    <g filter="url(#yellow-orbit_svg__h)">
      <circle cx={272} cy={79} r={2} fill="#5C5C5C" />
    </g>
    <g filter="url(#yellow-orbit_svg__i)">
      <circle cx={156} cy={44} r={2} fill="#5C5C5C" />
    </g>
    <g filter="url(#yellow-orbit_svg__j)">
      <circle cx={50} cy={40} r={2} fill="#5C5C5C" />
    </g>
    <defs>
      <filter
        id="yellow-orbit_svg__d"
        width={15}
        height={15}
        x={0.5}
        y={127.5}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={2.75} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.896319 0 0 0 0 0.628614 0 0 0 0 0.000328362 0 0 0 1 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_475_8543"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_475_8543"
          result="shape"
        />
      </filter>
      <filter
        id="yellow-orbit_svg__e"
        width={15}
        height={15}
        x={95.5}
        y={92.5}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={2.75} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.896319 0 0 0 0 0.628614 0 0 0 0 0.000328362 0 0 0 1 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_475_8543"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_475_8543"
          result="shape"
        />
      </filter>
      <filter
        id="yellow-orbit_svg__f"
        width={15}
        height={15}
        x={182.5}
        y={0.5}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={2.75} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.896319 0 0 0 0 0.628614 0 0 0 0 0.000328362 0 0 0 1 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_475_8543"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_475_8543"
          result="shape"
        />
      </filter>
      <filter
        id="yellow-orbit_svg__g"
        width={15}
        height={15}
        x={237.5}
        y={152.5}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={2.75} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.896319 0 0 0 0 0.628614 0 0 0 0 0.000328362 0 0 0 1 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_475_8543"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_475_8543"
          result="shape"
        />
      </filter>
      <filter
        id="yellow-orbit_svg__h"
        width={15}
        height={15}
        x={264.5}
        y={71.5}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={2.75} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.36 0 0 0 0 0.36 0 0 0 0 0.36 0 0 0 1 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_475_8543"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_475_8543"
          result="shape"
        />
      </filter>
      <filter
        id="yellow-orbit_svg__i"
        width={15}
        height={15}
        x={148.5}
        y={36.5}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={2.75} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.36 0 0 0 0 0.36 0 0 0 0 0.36 0 0 0 1 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_475_8543"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_475_8543"
          result="shape"
        />
      </filter>
      <filter
        id="yellow-orbit_svg__j"
        width={15}
        height={15}
        x={42.5}
        y={32.5}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={2.75} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.36 0 0 0 0 0.36 0 0 0 0 0.36 0 0 0 1 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_475_8543"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_475_8543"
          result="shape"
        />
      </filter>
      <linearGradient
        id="yellow-orbit_svg__a"
        x1={147.5}
        x2={147.5}
        y1={43}
        y2={242}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#424242" />
        <stop offset={1} stopColor="#262626" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="yellow-orbit_svg__b"
        x1={147.5}
        x2={147.5}
        y1={81}
        y2={204}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#424242" />
        <stop offset={1} stopColor="#262626" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="yellow-orbit_svg__c"
        x1={147}
        x2={147}
        y1={1}
        y2={283}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#424242" />
        <stop offset={1} stopColor="#262626" stopOpacity={0} />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgYellowOrbit;
