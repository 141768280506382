import React from "react";
import Table from "@/components/Common/Table";
import Button from "@/components/Common/Button";
import { useIsMobile } from "@/hooks/useIsMobile";
import ActionButtons from "./ActionButtons";
import MobileTable from "./MobileTable";
import EmptyPage from "../../components/EmptyPage";
import { CartLargeMinimalistic, Earth } from "@/components/Icons";

const LandTable = ({
  userTiles,
  isLoading,
  handleConvertToNFT,
  ComingSoonMessage,
}) => {
  const isMobile = useIsMobile(1300);
  const columns = [
    { header: "Land ID", key: "landId", width: "10%" },
    { header: "Number of Tiles", key: "numberOfTiles", width: "18%" },
    { header: "Purchase Price", key: "purchasePrice", width: "30%" },
    { header: "Actions", key: "actions", width: "380px" },
  ];

  const data = userTiles.map((land, index) => ({
    landId: land.land_id,
    numberOfTiles: land.tiles_count,
    purchasePrice: (
      <div className="flex items-center gap-6">
        <div>
          {land.purchasepriceusd} <span className="fw-bold">USDT</span>
        </div>
        <div>
          {land.purchasepricetoken}{" "}
          <span className="fw-bold">{land.token_type}</span>
        </div>
      </div>
    ),
    actions: (
      <div className="flex gap-3">
        <Button
          model="outlined"
          href={
            land.tiles_coordination
              ? `/lands?lat=${land.tiles_coordination[0].latitude}&lng=${land.tiles_coordination[0].longitude}`
              : "/"
          }
          size="sm"
        >
          Open in map
        </Button>
        <ActionButtons
          land={land}
          handleConvertToNFT={handleConvertToNFT}
          ComingSoonMessage={ComingSoonMessage}
        />
      </div>
    ),
  }));

  return (
    <>
      {(userTiles && userTiles.length > 0) || isLoading ? (
        isMobile ? (
          <div className="flex flex-col gap-4">
            {userTiles.length > 0
              ? userTiles.map((land, index) => (
                  <MobileTable
                    land={land}
                    key={index}
                    handleConvertToNFT={handleConvertToNFT}
                    ComingSoonMessage={ComingSoonMessage}
                    link={
                      land.tiles_coordination
                        ? `/lands?lat=${land.tiles_coordination[0].latitude}&lng=${land.tiles_coordination[0].longitude}`
                        : "/"
                    }
                  />
                ))
              : Array.from({ length: 3 }).map((_, index) => (
                  <MobileTable key={index} isLoading={true} />
                ))}
          </div>
        ) : (
          <Table columns={columns} data={data} isLoading={isLoading} />
        )
      ) : (
        <EmptyPage
          icon={<Earth />}
          title="No land has been purchased yet"
          info="As soon as you purchase your first piece of land, it will appear here"
          action={
            <Button model="main" size="sm" href="/lands">
              <CartLargeMinimalistic /> Buy Land
            </Button>
          }
        />
      )}
    </>
  );
};

export default LandTable;
