import React from "react";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import BaseModal from "../BaseModal";
import img from "@/scss/images/danger-galaxy.png";
interface ConnectWalletModalProps {
  closeModalHandler: () => void;
}

const ConnectWalletModal: React.FC<ConnectWalletModalProps> = ({
  closeModalHandler,
}) => {
  return (
    <BaseModal
      closeModalHandler={closeModalHandler}
      title="Connect Your Wallet"
      footer={
        <div className="connect-btn">
          <ConnectButton chainStatus="none" />
        </div>
      }
    >
      <div>
        <img alt="" src={img} className="mx-auto" />
        <p className="text-blackshade-200 text-label-md text-center -mt-20 max-w-64 mx-auto">
          To continue and purchase land, please connect your wallet.
        </p>
      </div>
    </BaseModal>
  );
};

export default ConnectWalletModal;
