import Button from "@/components/Common/Button";
import {
  Dollar,
  Earth,
  LayersMinimalistic,
  Nft,
  TagHorizontal,
} from "@/components/Icons";
import { useIsMobile } from "@/hooks/useIsMobile";
import TableMobileCard from "@/components/Common/TableMobileCard";

const Loading = () => {
  const userTiles = [
    {
      text: <div className="h-4 bg-blackshade-600 rounded-full w-12"></div>,
      icon: <TagHorizontal />,
      title: "Token/Land Id",
    },
    {
      text: <div className="h-4 bg-blackshade-600 rounded-full w-12"></div>,
      icon: <Dollar />,
      title: "Value",
    },
    {
      text: <div className="h-4 bg-blackshade-600 rounded-full w-[52px]"></div>,
      icon: <LayersMinimalistic />,
      title: "Tiles",
    },
    {
      text: <div className="h-4 bg-blackshade-600 rounded-full w-14"></div>,
      icon: <Nft className="h-4 w-4" />,
      title: "NFT Type",
    },
  ];
  return (
    <div className="md:col-span-6 desktop:col-span-3 col-span-12">
      <div className="bg-blackshade-800 rounded-[20px] p-4 relative">
        <Button
          model="tonal"
          className="absolute right-8 top-8"
          size="xsm"
          disabled
        >
          <Earth className="w-5 h-5" />
        </Button>
        <div class="flex items-center justify-center w-full h-[125px] bg-blackshade-600  rounded  animate-pulse">
          <svg
            class="w-10 h-10 text-gray-200 "
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 18"
          >
            <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
          </svg>
        </div>
        <div className="my-4">
          {userTiles.map((item, index) => (
            <div
              className="flex justify-between items-center animate-pulse"
              key={index}
            >
              <div className="flex items-center text-label-md gap-1 text-blackshade-200 animate-none">
                {item.icon}
                <span className="animate-none">{item.title}</span>
              </div>
              {item.text}
            </div>
          ))}
        </div>
        <div className="flex gap-4 [&>button]:flex-1">
          <Button model="outlined" size="sm" disabled>
            Stake
          </Button>
          <Button disabled model="error" size="sm">
            Sell
          </Button>
        </div>
      </div>
    </div>
  );
};
const CardLoading = () => {
  const isMobile = useIsMobile(1300);

  return (
    <div className="grid grid-cols-12 gap-6">
      {Array.from({ length: 4 }).map((_, index) =>
        isMobile ? (
          <div className="col-span-12">
            <TableMobileCard
              header={
                <>
                  <div className="flex gap-1 items-center pl-1">
                    <TagHorizontal />
                    <div className="text-body-lg">Token/Land ID:</div>
                    <div className="bg-blackshade-600 rounded w-10 h-3 animate-pulse"></div>
                  </div>
                  <Button disabled model="tonal" size="sm">
                    <Earth className="mr-1 h-5 w-5" />
                    Open in map
                  </Button>
                </>
              }
              action={
                <div className="flex gap-4 [&>button]:flex-1">
                  <Button model="outlined" size="sm" disabled>
                    Stake
                  </Button>

                  <Button disabled model="error" size="sm">
                    Sell
                  </Button>
                </div>
              }
            >
              <div className="flex gap-3 flex-wrap">
                <div className="bg-blackshade-600 rounded w-[8rem] h-[80px] animate-pulse"></div>

                <div className="flex-1">
                  <div className="flex justify-between items-center mb-2">
                    <div className="flex gap-1 items-center text-blackshade-200 text-body-md">
                      <Dollar />
                      Value
                    </div>
                    <div className="bg-blackshade-600 rounded w-12 h-3 animate-pulse"></div>
                  </div>
                  <div className="flex justify-between items-center mb-2">
                    <div className="flex gap-1 items-center text-blackshade-200 text-body-md">
                      <LayersMinimalistic /> Tiles
                    </div>
                    <div className="bg-blackshade-600 rounded w-12 h-3 animate-pulse"></div>
                  </div>
                  <div className="flex justify-between items-center mb-2">
                    <div className="flex gap-1 items-center text-blackshade-200 text-body-md">
                      <Nft className="w-4 h-4" />
                      NFT Type
                    </div>
                    <div className="bg-blackshade-600 rounded w-14 h-3 animate-pulse"></div>
                  </div>
                </div>
              </div>
            </TableMobileCard>
          </div>
        ) : (
          <Loading key={index} />
        )
      )}
    </div>
  );
};

export default CardLoading;
