import { useState, useEffect, useContext } from "react";
import {
  useWriteContract,
  useWaitForTransactionReceipt,
  useBalance,
  useAccount,
  useReadContract,
} from "wagmi";
import { parseEther } from "ethers";
import {
  landAbi,
  approveAbi,
  pmeApproveAbi,
  maticTokenAddress,
} from "@/utils/contractConstants";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { api } from "@/utils/axiosInstance.js";
import { convertTilesToString } from "@/utils/landHelpers.js";
import { MapContext } from "../context.jsx";
import BaseModal from "@/components/Common/BaseModal";
import TileInfo from "./components/TileInfo.jsx";
import Button from "@/components/Common/Button";
import Stepper from "@/components/Common/Stepper";
import { Coin, Earth, LayersMinimalistic, Wallet } from "@/components/Icons";
import { useToast } from "@/components/Common/Toast/utils";

const BuyLandModal = ({ closeModalHandler, contractData }) => {
  const {
    tileNumber,
    emptySelectedCells,
    selectedCells,
    setIsPurchased,
    titleInfo,
  } = useContext(MapContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isFetchingLand, setIsFetchingLand] = useState(false);
  const [fetchLandData, setFetchLandData] = useState();
  const [fetchLandError, setFetchLandError] = useState(false);
  const [buttonText, setButtonText] = useState("Confirm");
  const [selectTokenType, setSelectTokenType] = useState("");
  const { addToast } = useToast();

  const { address } = useAccount();
  const queryClient = useQueryClient();
  const [showStepper, setShowStepper] = useState(false);
  const approve = useWriteContract();
  const purchaseLand = useWriteContract();
  const transactionReceipt = useWaitForTransactionReceipt({
    hash: approve.data,
    confirmations: 3,
  });

  const purchasePriceTokenInWei = parseEther(
    contractData.approve_token.toString()
  );

  const userBalance = useBalance({ address });
  const pmbBalance = useBalance({
    address,
    token: process.env.REACT_APP_PMB_CONTRACT_ADDRESS,
  });
  const pmeBalance = useBalance({
    address,
    token: process.env.REACT_APP_PME_CONTRACT_ADDRESS,
  });
  const pmgBalance = useBalance({
    address,
    token: process.env.REACT_APP_PMG_CONTRACT_ADDRESS,
  });
  const maticBalance = useBalance({ address, token: maticTokenAddress });

  const allowance = useReadContract({
    address: process.env.REACT_APP_PMB_CONTRACT_ADDRESS,
    abi: approveAbi,
    functionName: "allowance",
    args: [address, process.env.REACT_APP_LAND_CONTRACT_ADDRESS],
    watch: true,
  });

  // const isAllowed = allowance?.data >= purchasePriceTokenInWei;

  useEffect(() => {
    allowance.refetch();
    queryClient.invalidateQueries({ queryKey: userBalance.queryKey });
  }, []);

  useEffect(() => {
    if (purchaseLand.isSuccess) {
      const redirectHandler = async () => {
        await resetLockHandler();
        addToast("success", "Land Purchase Done! Processing Now.");
        const timeoutId = setTimeout(() => {
          closeModalHandler();
          setIsPurchased(true);
          navigate("/dashboard/purchased-lands");
        }, 15000);

        // Cleanup function to clear timeout if the component unmounts
        return () => clearTimeout(timeoutId);
      };

      redirectHandler();
    }
  }, [purchaseLand.isSuccess]);

  useEffect(() => {
    if (approve.isError) {
      if (approve.error?.cause?.code === 4001) {
        addToast(
          "danger",
          "You rejected the spending cap request."
        );
        // closeModalHandler();
      }
    }
  }, [approve.isError]);

  useEffect(() => {
    if (purchaseLand.error) {
      cancelBuyLand();
      addToast(
        "danger",
        purchaseLand.error?.cause?.shortMessage || "Purchase failed."
      );
      // closeModalHandler();
      setIsLoading(false);
    }
  }, [purchaseLand.error]);

  useEffect(() => {
    if (transactionReceipt.isSuccess && !purchaseLand.isSuccess) {
      fetchBuyLandData();
    }
  }, [transactionReceipt.isSuccess]);

  useEffect(() => {
    if (transactionReceipt.isSuccess) {
      queryClient.invalidateQueries({ queryKey: allowance.queryKey });
    }
  }, [transactionReceipt.isSuccess]);

  useEffect(() => {
    if (transactionReceipt.error) {
      transactionReceipt.refetch();
    }
  }, [transactionReceipt.error]);

  const fetchBuyLandData = async () => {
    setIsFetchingLand(true);
    try {
      const res = await api.post("/buy_land/", {
        tile_count: tileNumber,
        tile_data: convertTilesToString(selectedCells),
        token_type: selectTokenType,
        is_forsale: false,
        user_address: address,
      });

      if (res.data.status === "Error") {
        addToast("danger", res.data.message);

        setFetchLandError(true);
      } else if (res.data.duplicated) {
        addToast("danger", "This land has already been bought.");
      } else if (res.data.reserved) {
        addToast("danger", "This land has already been reserved.");
      } else {
        setFetchLandData(res.data.data);
        purchaseLandHandler(res.data.data);
      }
    } catch (error) {
      addToast("danger", error.response?.data.message);
      setFetchLandError(true);
      if (error.response.status === 423) {
        emptySelectedCells();
        closeModalHandler();
      }
    } finally {
      setIsFetchingLand(false);
    }
  };

  const cancelBuyLand = async () => {
    try {
      await api.delete("/cancel_purchase/", {
        data: {
          tile_data: convertTilesToString(selectedCells),
          user_address: address,
        },
      });
    } catch (error) {
      console.error("Error canceling land purchase:", error);
    }
  };

  const resetLockHandler = () => {
    api.post("/reset_lock/", { user_address: address });
  };

  const purchaseLandHandler = (data) => {
    purchaseLand.writeContract({
      address: process.env.REACT_APP_LAND_CONTRACT_ADDRESS,
      abi: landAbi,
      functionName: "purchaseLand",
      args: [data.tilecount, data.token_type, data.tile_data, data.is_forsale],
    });
  };
  const approveHandler = async () => {
    approve.writeContractAsync({
      address:
        selectTokenType === "PME"
          ? process.env.REACT_APP_PME_CONTRACT_ADDRESS
          : process.env.REACT_APP_PMB_CONTRACT_ADDRESS,
      abi: selectTokenType === "PME" ? pmeApproveAbi : approveAbi,
      functionName: "approve",
      args: [
        process.env.REACT_APP_LAND_CONTRACT_ADDRESS,
        purchasePriceTokenInWei,
      ],
    });
  };
  const buyLandHandler = async () => {
    try {
      // if (
      //   Number(pmbBalance?.data?.formatted) <
      //     contractData.purchase_price_token &&
      //   selectTokenType === "PMB"
      // ) {
      //   addToast(
      //     "danger",
      //     "Your PMB balance is not sufficient to complete the purchase."
      //   );
        // closeModalHandler();
      // } 
       if (
        Number(pmeBalance?.data?.formatted) <
          contractData.purchase_price_token &&
        selectTokenType === "PME"
      ) {
        addToast(
          "danger",
          "Your PME balance is not sufficient to complete the purchase."
        );
        // closeModalHandler();
      } else if (
        Number(pmgBalance?.data?.formatted) <
          contractData.purchase_price_token &&
        selectTokenType === "PMG"
      ) {
        addToast(
          "danger",
          "Your PMG balance is not sufficient to complete the purchase."
        );
        // closeModalHandler();
      } else if (Number(maticBalance?.data?.formatted) < 0.1) {
        addToast(
          "danger",
          "Your Matic balance is not sufficient to complete the purchase."
        );
        closeModalHandler();
      } else {
        setIsLoading(true);
        setButtonText("Loading");
        setShowStepper(true);
        await approveHandler();
      }
    } catch (err) {
      console.error("Error in buyLandHandler:", err);
      setIsLoading(false);
      setButtonText("Confirm");
    }
  };

  const isDisabled =
    isLoading ||
    purchaseLand.isLoading ||
    approve.isLoading ||
    approve.isPending ||
    purchaseLand.isPending ||
    transactionReceipt.isFetching ||
    userBalance.isFetching ||
    isFetchingLand;
  const stepperList = [
    {
      id: 0,
      title: "Token",
      icon: <Coin />,
      status:
        approve.isPending || approve.isError || approve.isSuccess
          ? "active"
          : "in-progress",
    },
    {
      id: 1,
      title: "wallet approval",
      icon: <Wallet />,
      status:
        approve.isPending || transactionReceipt.isFetching
          ? "in-progress"
          : transactionReceipt.isSuccess
          ? "active"
          : approve.isError || transactionReceipt.isError
          ? "failed"
          : "in-active",
      retryHandler: approve.isError
        ? approveHandler
        : transactionReceipt.isError
        ? transactionReceipt.refetch
        : null,
    },
    {
      id: 2,
      title: "Land Detail",
      icon: <Earth />,
      // title: isFetchingLand
      //   ? "Retrieving Land Information"
      //   : fetchLandError
      //   ? "Failed to Retrieve Land Information"
      //   : fetchLandData
      //   ? "Retrieved Land Information"
      //   : "Retrieve Land Information",
      status: isFetchingLand
        ? "in-progress"
        : fetchLandData
        ? "active"
        : fetchLandError
        ? "failed"
        : "in-active",
      retryHandler:
        !fetchLandData && !isFetchingLand && fetchLandError
          ? fetchBuyLandData
          : null,
    },
    {
      id: 3,
      title: "Purchase",
      icon: <LayersMinimalistic />,
      status: purchaseLand.isPending
        ? "in-progress"
        : purchaseLand.isSuccess
        ? "active"
        : purchaseLand.isError
        ? "failed"
        : "in-active",
      retryHandler: purchaseLand.isError
        ? () => purchaseLandHandler(fetchLandData)
        : null,
    },
  ];
  const totalStatusTitle = (() => {
    if (purchaseLand.isPending) return "Waiting for Wallet Confirmation";
    if (purchaseLand.isError) return "Failed to Purchase";
    if (purchaseLand.isSuccess) return "Land Purchase Done!";
    if (isFetchingLand) return "Retrieving Land Information";
    if (fetchLandError) return "Failed to Retrieve Land Information";

    if (approve.isPending) return "Waiting for Wallet Approval";
    if (approve.isError) return "Failed to Approve";
    if (transactionReceipt.isFetching)
      return "Waiting for Transaction Confirmation";
    if (transactionReceipt.isSuccess) return "Approval Completed";

    return "Stake NFT";
  })();

  const totalStatus = (() => {
    if (purchaseLand.isPending) return "in-progress";
    if (purchaseLand.isSuccess) return "active";
    if (purchaseLand.isError) return "failed";
    if (approve.isPending || transactionReceipt.isFetching)
      return "in-progress";
    if (transactionReceipt.isSuccess) return "active";
    if (approve.isError || transactionReceipt.isError) return "failed";

    return "in-active";
  })();
  const totalRetryHandler = purchaseLand.isError
    ? purchaseLandHandler
    : approve.isError
    ? approveHandler
    : transactionReceipt.isError
    ? transactionReceipt.refetch
    : !fetchLandData && !isFetchingLand && fetchLandError
    ? fetchBuyLandData
    : null;
  return (
    <BaseModal
      title="Payment Options"
      closeModalHandler={closeModalHandler}
      footer={
        <div className="flex gap-4 flex-wrap w-full justify-end !flex-col-reverse desktop:!flex-row">
          {totalStatus === "failed" && (
            <Button
              model="ghost"
              size="sm"
              onClick={closeModalHandler}
              className="!w-full desktop:!w-max"
            >
              Cancel
            </Button>
          )}
          <Button
            model="main"
            size="sm"
            className="!w-full desktop:!w-max"
            onClick={
              totalStatus === "failed" ? totalRetryHandler : buyLandHandler
            }
            disabled={
              (isDisabled || selectTokenType === "") && totalStatus !== "failed"
            }
            isLoading={isLoading && totalStatus !== "failed"}
          >
            {totalStatus === "failed" ? "Try Again" : "Confirm"}
          </Button>
        </div>
      }
    >
      {" "}
      <Stepper
        list={stepperList}
        showStepper={showStepper}
        totalStatus={totalStatus}
        totalStatusTitle={totalStatusTitle}
      />
      {showStepper ? null : (
        <>
          {" "}
          <TileInfo
            tile_count={contractData.tile_count}
            tilePrice={titleInfo.tilePrice}
            purchase_price_token={contractData.purchase_price_token}
          />
          <div className="form-select-wrapper mt-6 mb-20">
            <select
              className="form-select mt-2"
              value={selectTokenType}
              onChange={(e) => {
                setSelectTokenType(e.target.value);
              }}
              disabled={isDisabled && selectTokenType}
            >
              <option value={""}>Select Your Token </option>
              {/* <option value={"PMB"}>PMB</option> */}
              <option value={"PME"}>PME</option>
            </select>
          </div>
        </>
      )}
    </BaseModal>
  );
};

export default BuyLandModal;
