import React, { useState, useEffect, useRef } from "react";
import Globe from "react-globe.gl";
import * as d3 from "d3-dsv";
import { useIsMobile } from "@/hooks/useIsMobile";
import GlobeImg from '@/scss/images/globe-3.jpg'
const startLatitude = -49.402488;
const endLatitude = -49.392488;
const startLongitude = 69.556083;
const endLongitude = 69.566083;

const World = () => {
  const globeEl = useRef();
  const [routes, setRoutes] = useState([]);
  const isMobile = useIsMobile(1300);
  useEffect(() => {
    globeEl.current.controls().autoRotate = true;
    globeEl.current.controls().enableZoom = false;
  }, []);

  useEffect(() => {
    // Set custom route data using the specified coordinates
    const customRoutes = [
      {
        srcLat: startLatitude,
        srcLng: startLongitude,
        dstLat: endLatitude,
        dstLng: endLongitude,
        airline: "Custom Route",
      },
    ];
    setRoutes(customRoutes);

    globeEl.current.pointOfView(
      {
        lat: startLatitude,
        lng: startLongitude,
        altitude: isMobile ? 4.4 : 1.4,
      },
      4000
    );
  }, [isMobile]);
  const globeContainerRef = React.useRef(null);

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  useEffect(() => {
    const { offsetWidth, offsetHeight } = globeContainerRef.current;
    setWidth(offsetWidth);
    setHeight(offsetHeight);
    console.log({ offsetWidth, offsetHeight });
  }, [globeContainerRef]);

  return (
    <div
      ref={globeContainerRef}
      style={{ width: "100%", alignContent: "center" }}
      className="h-[800px] desktop:h-[800px] w-full pointer-events-none content-center"
    >
      <Globe
        ref={globeEl}
        globeImageUrl={GlobeImg}
        arcsData={routes}
        arcLabel={(d) => `${d.airline}: Custom Route`}
        arcStartLat={(d) => d.srcLat}
        arcStartLng={(d) => d.srcLng}
        arcEndLat={(d) => d.dstLat}
        arcEndLng={(d) => d.dstLng}
        arcDashLength={0.4}
        arcDashGap={0.2}
        arcDashAnimateTime={1500}
        arcsTransitionDuration={0}
        arcColor={() => ["rgba(0, 255, 0, 0.3)", "rgba(255, 0, 0, 0.3)"]}
        pointsData={[]} // No airports data if not needed
        backgroundColor="rgba(0, 0, 0, 0)"
        animateIn={false}
        showAtmosphere={false}
        width={width}
        height={height}
      />
    </div>
  );
};

export default World;
