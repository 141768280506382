import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import logo from "@/assets/images/logo/logorec.png";
import Button from "@/components/Common/Button";
import {
  CartLargeMinimalistic,
  Close,
  Dashboard,
  HomeAngle,
  Stars,
} from "@/components/Icons";
import SidebarNav from "../Sidebar/SidebarNav";
import { useLocation } from "react-router-dom";

const Header: React.FC = () => {
  const [isNavOpen, setIsNavOpen] = useState<boolean>(false);
  const location = useLocation();
  const toggleMenuHandler = () => {
    setIsNavOpen((prev) => !prev);
  };
  useEffect(() => {
    setIsNavOpen(false);
  }, [location]);

  return (
    <div id="header" className="dashboard-header">
      <div className="dashboard-header__left">
        <div className="brand">
          <Link to="/">
            <img src={logo} alt="logo" />
          </Link>
        </div>
        <div className="active-connect-btn">
          <ConnectButton chainStatus="none" />
        </div>
      </div>

      <div className="dashboard-header__right">
        <Button
          model="main"
          size={"md"}
          href="/lands"
          className="hidden desktop:flex"
        >
          <CartLargeMinimalistic /> Buy Land
        </Button>
        <Button
          model="tonal"
          size="md"
          className="!hidden desktop:!flex"
          href="/"
        >
          <HomeAngle />
        </Button>
        <Button
          model="tonal"
          size="sm"
          className="!flex desktop:!hidden w-10 h-10 !items-center relative z-[65] px-0"
          onClick={toggleMenuHandler}
        >
          {isNavOpen ? (
            <Close className="w-6 h-6" />
          ) : (
            <Dashboard className="w-4 h-4" />
          )}
        </Button>
      </div>
      <div
        className={`dashbpard-sidebar__nav z-[70] ${
          isNavOpen ? "translate-x-0" : "-translate-x-[100%]"
        }`}
      >
        <SidebarNav />
        <Stars />
      </div>
      {isNavOpen && (
        <div className="bg-blackshade-900 opacity-[0.88] z-[60] fixed inset-0" />
      )}
    </div>
  );
};

export default Header;
