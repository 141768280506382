import React from "react";
import Input from "@/components/Common/Input";
import { Copy } from "@/components/Icons";
import { copyToClipboard } from "@/utils/copyToClipboard.js";
import Accordion from "@/components/Common/Accordion";

const InfoBox = () => {
  return (
    <div className="bg-dashboard-frame fram-effects rounded-[20px] p-3 md:p-6">
      <Accordion
        header={
          <h3 className="text-white text-title-lg md:text-subheading-md text-left">
            How to Import an NFT into Your Wallet
          </h3>
        }
      >
        <div className="mt-3 flex flex-col gap-2 text-body-md md:text-label-md text-white">
          <div>
            To manually import an NFT into your wallet, open your wallet and go
            to the "NFT" tab.
          </div>
          <div>Click on "Import NFTs" or "Add Token."</div>
          <div>Enter the token address:</div>
          <div className="my-2 md:my-3 md:max-w-[430px]">
            <Input
              defaultValue={process.env.REACT_APP_LAND_TO_NFT_CONTRACT_ADDRESS}
              readOnly
              disabled
              model="lg"
              suffixIcon={
                <div
                  className="text-gold-500 cursor-pointer p-2 bg-blackshade-700 rounded-[10px] h-10"
                  onClick={() =>
                    copyToClipboard(
                      process.env.REACT_APP_LAND_TO_NFT_CONTRACT_ADDRESS
                    )
                  }
                >
                  <Copy />
                </div>
              }
            />
          </div>

          <div>
            in the "Address" field and the Token/Land ID of your NFT in the
            "Token ID" field.
          </div>
          <div>Confirm by clicking "Add" or "Import."</div>
        </div>
      </Accordion>
    </div>
  );
};

export default InfoBox;
