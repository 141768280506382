import { useState } from "react";
import Modal from "./Modal";
import Card from "./Card";
import { useIsMobile } from "@/hooks/useIsMobile";
import MobileTable from "./MobileTable";

const CardContainer = ({ nftData }) => {
  const [openModal, setOpenModal] = useState(false);
  const [nftDetails, setNftDetails] = useState(null);
  const isMobile = useIsMobile(1300);

  const closeModalHandler = () => {
    setOpenModal(false);
  };
  const handleStacking = ({ token_id, image }) => {
    setNftDetails({ token_id, image });
    setOpenModal(true);
  };

  return (
    <div className="grid grid-cols-12 mb-5 gap-6">
      {nftData.map(([id, token]) => (
        <>
          {isMobile ? (
            <div className="col-span-12  md:col-span-6 desktop:col-span-3" key={id}>
              <MobileTable
                tokenId={id}
                tile_count={token.tile_count}
                purchasepriceusd={token.purchasepriceusd}
                image={`data:image/png;base64,${token.picture}`}
                landtype={token.landtype}
                handleStacking={() =>
                  handleStacking({
                    token_id: id,
                    image: `data:image/png;base64,${token.picture}`,
                  })
                }
                coordination={token.tile_data[0]}
                is_staked={token.is_staked}
              />
            </div>
          ) : (
            <div className="col-span-12 md:col-span-6 desktop:col-span-3" key={id}>
              <Card
                tokenId={id}
                tile_count={token.tile_count}
                purchasepriceusd={token.purchasepriceusd}
                image={`data:image/png;base64,${token.picture}`}
                landtype={token.landtype}
                handleStacking={() =>
                  handleStacking({
                    token_id: id,
                    image: `data:image/png;base64,${token.picture}`,
                  })
                }
                coordination={token.tile_data[0]}
                is_staked={token.is_staked}
              />
            </div>
          )}
        </>
      ))}
      {openModal && (
        <Modal closeModalHandler={closeModalHandler} nftDetails={nftDetails} />
      )}
    </div>
  );
};

export default CardContainer;
