import React from "react";
import { AnouncementStaking } from "@/components/Icons";
import Button from "../Button";
import BaseModal from "../BaseModal";

interface AnnouncementModalProps {
  closeModalHandler?: () => void;
}

const AnnouncementModal: React.FC<AnnouncementModalProps> = ({
  closeModalHandler,
}) => {
  return (
    <BaseModal
      footer={
        <Button
          className="w-full md:w-[124px] mx-auto"
          model="main"
          size="sm"
          onClick={closeModalHandler}
        >
          Ok
        </Button>
      }
    >
      <div className="mt-6 mb-8 flex flex-col items-center text-center">
        <AnouncementStaking />
        <h2 className="text-subheading-md mb-2">NFT Staking on PCOLand</h2>
        <p className="text-label-md text-blackshade-200">
          Stake your NFTs after acquiring virtual land to participate in staking
          opportunities.
        </p>
        <div className="rounded-2xl bg-informative-frame-sm frame-effects p-4 w-full mt-8">
          <h3 className="text-title-lg mb-6">Staking Terms</h3>
          <div className="flex gap-6">
            <div className="flex-1 rounded-[20px] shadow-frame bg-golden-linear py-4 px-3 text-black">
              <div className="text-subheading-sm">1 Year</div>
              <div className="text-label-lg">36% APR</div>
            </div>
            <div className="flex-1 rounded-[20px] shadow-frame bg-golden-linear py-4 px-3 text-black">
              <div className="text-subheading-sm">2 Years</div>
              <div className="text-label-lg">60% APR</div>
            </div>
            <div className="flex-1 rounded-[20px] shadow-frame bg-golden-linear py-4 px-3 text-black">
              <div className="text-subheading-sm">3 Years</div>
              <div className="text-label-lg">84% APR</div>
            </div>
          </div>
        </div>
      </div>
    </BaseModal>
  );
};

export default AnnouncementModal;
