import * as React from "react";
import type { SVGProps } from "react";
const SvgWalletMoney = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={28}
    fill="none"
    viewBox="0 0 30 28"
    {...props}
  >
    <path
      d="M13.3355 19.4773V22.184C13.3355 24.4774 11.2022 26.3307 8.57552 26.3307C5.94886 26.3307 3.80217 24.4774 3.80217 22.184V19.4773M13.3355 19.4773C13.3355 21.7573 11.2022 23.3973 8.57552 23.3973M13.3355 19.4773L13.3356 15.8097C13.3356 14.6631 12.8023 13.623 11.9489 12.8764C11.0823 12.1297 9.89557 11.6631 8.57557 11.6631C5.93557 11.6631 3.80221 13.5164 3.80221 15.8097L3.80217 19.4773M13.3355 19.4773C13.3355 21.7707 11.2022 23.3973 8.57552 23.3973M3.80217 19.4773C3.80217 21.7707 5.93552 23.3973 8.57552 23.3973M3.80217 19.4773C3.80217 21.7573 5.94886 23.3973 8.57552 23.3973M1.66663 10.9964V8.32975C1.66663 4.70309 3.8533 2.16975 7.25329 1.74308C7.59996 1.68975 7.95996 1.66309 8.33329 1.66309H20.3333C20.68 1.66309 21.0133 1.6764 21.3333 1.72974C24.7733 2.12974 27 4.67642 27 8.32975V10.2631H24.2266C23.4799 10.2631 22.7999 10.5564 22.3066 11.0631C21.7466 11.6097 21.4266 12.3964 21.5066 13.2364C21.6266 14.6764 22.9466 15.7297 24.3866 15.7297H27V17.6631C27 21.6631 24.3333 24.3298 20.3333 24.3298H17M13.3334 15.8119C13.3334 16.4785 13.1468 17.0918 12.8268 17.6252C12.0401 18.9185 10.4267 19.7319 8.56008 19.7319C6.69341 19.7319 5.08006 18.9052 4.2934 17.6252C3.9734 17.0918 3.7868 16.4785 3.7868 15.8119C3.7868 14.6652 4.32011 13.6385 5.17344 12.8919C6.04011 12.1319 7.22673 11.6786 8.54673 11.6786C9.86673 11.6786 11.0534 12.1452 11.9201 12.8919C12.8001 13.6252 13.3334 14.6652 13.3334 15.8119ZM28.3333 11.6254V14.3722C28.3333 15.1055 27.7467 15.7054 27 15.7321H24.3866C22.9466 15.7321 21.6267 14.6788 21.5067 13.2388C21.4267 12.3988 21.7466 11.6121 22.3066 11.0654C22.8 10.5588 23.48 10.2655 24.2266 10.2655H27C27.7467 10.2921 28.3333 10.8921 28.3333 11.6254Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgWalletMoney;
