import * as React from "react";
import type { SVGProps } from "react";
const SvgInfinity = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={12}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6 1.75a4.25 4.25 0 0 0 0 8.5c.597 0 1.045-.107 1.407-.284.362-.176.679-.442.986-.816.54-.66.983-1.557 1.567-2.741q.175-.356.37-.744l.34-.69c.581-1.181 1.117-2.27 1.777-3.075.41-.501.89-.923 1.49-1.215S15.216.25 16 .25a5.75 5.75 0 1 1-3.45 10.35.75.75 0 0 1 .9-1.2A4.25 4.25 0 1 0 16 1.75c-.597 0-1.045.107-1.407.284-.362.176-.679.442-.986.816-.54.66-.983 1.557-1.567 2.741q-.175.356-.37.744l-.34.69c-.581 1.181-1.117 2.27-1.777 3.076-.41.5-.89.922-1.49 1.214s-1.28.435-2.063.435A5.75 5.75 0 1 1 9.451 1.4a.75.75 0 1 1-.901 1.2A4.23 4.23 0 0 0 6 1.75"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgInfinity;
