import * as React from "react";
import type { SVGProps } from "react";
const SvgUserRounded = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16 1.667a6.333 6.333 0 1 0 0 12.667 6.333 6.333 0 0 0 0-12.667M11.667 8a4.333 4.333 0 1 1 8.667 0 4.333 4.333 0 0 1-8.667 0M16 16.334c-2.719 0-5.23.627-7.095 1.694-1.838 1.05-3.238 2.653-3.238 4.639s1.4 3.59 3.238 4.64C10.77 28.373 13.28 29 16 29c2.72 0 5.23-.627 7.096-1.694 1.838-1.05 3.238-2.652 3.238-4.639s-1.4-3.59-3.238-4.64C21.23 16.962 18.72 16.334 16 16.334m-8.333 6.333c0-.959.69-2.023 2.23-2.903 1.512-.864 3.668-1.43 6.103-1.43 2.436 0 4.592.566 6.104 1.43 1.54.88 2.23 1.944 2.23 2.903s-.69 2.023-2.23 2.903C20.592 26.434 18.436 27 16 27s-4.591-.566-6.103-1.43c-1.54-.88-2.23-1.944-2.23-2.903"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgUserRounded;
