import { useEffect, useState } from "react";
import { useWriteContract, useWaitForTransactionReceipt } from "wagmi";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { stakeNFTAbi, landNFTAbi } from "@/utils/contractConstants";
import StakeTypeSelector from "./StakeTypeSelector";
import Stepper from "@/components/Common/Stepper";
import BaseModal from "@/components/Common/BaseModal";
import { Wallet, WalletMoney } from "@/components/Icons";
import Button from "@/components/Common/Button";
import { useToast } from "@/components/Common/Toast/utils";

const Modal = ({ nftDetails, closeModalHandler }) => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [stakeTypes, setStakeTypes] = useState([]);
  const [showStepper, setShowStepper] = useState(false);
  const [selectedStakeType, setSelectedStakeType] = useState(""); // Added state for selected land type
  const approve = useWriteContract();
  const stake = useWriteContract();
  const { addToast } = useToast();

  const transactionReceipt = useWaitForTransactionReceipt({
    hash: approve.data,
    confirmations: 3,
  });
  const approveHandler = async () => {
    await approve.writeContractAsync({
      address: process.env.REACT_APP_LAND_TO_NFT_CONTRACT_ADDRESS,
      abi: landNFTAbi,
      functionName: "approve",
      args: [
        process.env.REACT_APP_STAKE_NFT_CONTRACT_ADDRESS,
        Number(nftDetails.token_id),
      ],
    });
  };
  const stakeHandler = async () => {
    await stake.writeContractAsync({
      address: process.env.REACT_APP_STAKE_NFT_CONTRACT_ADDRESS,
      abi: stakeNFTAbi,
      functionName: "stake",
      args: [Number(nftDetails.token_id), Number(selectedStakeType)],
    });
  };
  useEffect(() => {
    if (approve.isError) {
      if (approve.error?.cause?.code === 4001) {
        addToast(
          "danger",
          "You rejected the spending cap request."
        );
      }
    }
  }, [approve.isError]);
  useEffect(() => {
    if (transactionReceipt.isSuccess) {
      stakeHandler();
    }
  }, [transactionReceipt.isSuccess]);

  useEffect(() => {
    if (transactionReceipt.error) {
      transactionReceipt.refetch();
    }
  }, [transactionReceipt.error]);

  const convertButtonHandler = async () => {
    setIsLoading(true);
    try {
      setShowStepper(true);
      approveHandler();
    } catch (error) {
      console.error("Error capturing image:", error);
    }
  };
  useEffect(() => {
    if (stake.isSuccess) {
      addToast("success", "Your NFT Has Been Successfully Staked!");
      setTimeout(() => {
        closeModalHandler();
        navigate("/dashboard/my-stakings");
      }, 1000);
    }
  }, [stake.isSuccess]);
  const isBtnDisabled =
    transactionReceipt.isFetching ||
    approve.isPending ||
    isLoading ||
    stake.isPending ||
    stakeTypes.length === 0;
  const stepperList = [
    {
      id: 1,
      title: "Stake Type",
      icon: <WalletMoney />,
      status:
        approve.isFetching ||
        approve.isError ||
        approve.isPending ||
        transactionReceipt.isSuccess ||
        transactionReceipt.isFetching
          ? "active"
          : "in-progress",
    },
    {
      id: 2,
      title: "wallet approval",
      icon: <Wallet />,
      info: approve.isPending
        ? "Waiting for Wallet Approval"
        : approve.isError
        ? "Failed to Approve"
        : transactionReceipt.isFetching
        ? "Waiting for Transaction Confirmation"
        : transactionReceipt.isSuccess
        ? "Approval Completed"
        : "Approve",
      status:
        approve.isPending || transactionReceipt.isFetching
          ? "in-progress"
          : transactionReceipt.isSuccess
          ? "active"
          : approve.isError || transactionReceipt.isError
          ? "failed"
          : "in-active",
      retryHandler: approve.isError
        ? approveHandler
        : transactionReceipt.isError
        ? transactionReceipt.refetch
        : null,
    },
    {
      id: 3,
      title: "Stake NFT",
      icon: <WalletMoney />,

      info: stake.isPending
        ? "Waiting for Wallet Confirmation To Stake"
        : stake.isError
        ? "Failed to Stake NFT"
        : stake.isSuccess
        ? "Staked NFT"
        : "Stake NFT",
      status: stake.isPending
        ? "in-progress"
        : stake.isSuccess
        ? "active"
        : stake.isError
        ? "failed"
        : "in-active",
      retryHandler: stake.isError ? stakeHandler : null,
    },
  ];
  const totalStatusTitle = (() => {
    if (stake.isPending) return "Waiting for Wallet Confirmation To Stake";
    if (stake.isError) return "Failed to Stake NFT";
    if (stake.isSuccess) return "Staked NFT";
    if (approve.isPending) return "Waiting for Wallet Approval";
    if (approve.isError) return "Failed to Approve";
    if (transactionReceipt.isFetching)
      return "Waiting for Transaction Confirmation";
    if (transactionReceipt.isSuccess) return "Approval Completed";

    return "Stake NFT";
  })();

  const totalStatus = (() => {
    if (stake.isPending) return "in-progress";
    if (stake.isSuccess) return "active";
    if (stake.isError) return "failed";
    if (approve.isPending || transactionReceipt.isFetching)
      return "in-progress";
    if (transactionReceipt.isSuccess) return "active";
    if (approve.isError || transactionReceipt.isError) return "failed";

    return "in-active";
  })();
  const totalRetryHandler = stake.isError
    ? stakeHandler
    : approve.isError
    ? approveHandler
    : transactionReceipt.isError
    ? transactionReceipt.refetch
    : null;
  return (
    <BaseModal
      title="Stake Your NFT"
      closeModalHandler={closeModalHandler}
      className="nft-modal"
      footer={
        <div className="flex gap-4 flex-wrap w-full justify-end !flex-col-reverse desktop:!flex-row">
          {totalStatus === "failed" && (
            <Button
              model="ghost"
              size="sm"
              onClick={closeModalHandler}
              className="!w-full desktop:!w-max"
            >
              Cancel
            </Button>
          )}
          <Button
            model="main"
            size="sm"
            className="!w-full desktop:!w-max"
            onClick={
              totalStatus === "failed"
                ? totalRetryHandler
                : convertButtonHandler
            }
            disabled={
              (isBtnDisabled || selectedStakeType === "") &&
              totalStatus !== "failed"
            }
            isLoading={isLoading && totalStatus !== "failed"}
          >
            {totalStatus === "failed" ? "Try Again" : "Confirm"}
          </Button>
        </div>
      }
    >
      <Stepper
        list={stepperList}
        showStepper={showStepper}
        totalStatus={totalStatus}
        totalStatusTitle={totalStatusTitle}
      />

      {!showStepper && (
        <>
          <h3 className="text-label-lg mt-10 mb-6">Stake Type</h3>
          <img
            src={nftDetails.image}
            className="rounded-xl w-full mb-6"
            alt="nft"
          />
          <StakeTypeSelector
            nftDetails={nftDetails}
            selectedStakeType={selectedStakeType}
            setSelectedStakeType={setSelectedStakeType}
            setStakeTypes={setStakeTypes}
            stakeTypes={stakeTypes}
            setIsLoading={setIsLoading}
            isBtnDisabled={isBtnDisabled}
          />
        </>
      )}
    </BaseModal>
  );
};

export default Modal;
