import Button from "@/components/Common/Button";
import {
  HomeAngle,
  UserRounded,
  CartLargeMinimalistic,
  BuyLand,
} from "@/components/Icons";
import React from "react";
import { NavLink } from "react-router-dom";

const Footer: React.FC = () => {
  return (
    <div className="fixed z-50 bottom-0 bg-blackshade-900 h-[64px] base-shaodw pt-3 w-full">
      <div>
        <div className="bg-horizontal-linear h-[1px] absolute top-0 w-1/2 right-0"></div>
        <div className="bg-horizontal-linear h-[1px] absolute top-0 w-1/2 left-0 rotate-180"></div>
        <div className="flex justify-around">
          <NavLink to="/">
            {({ isActive }) => (
              <div className="relative">
                <HomeAngle
                  className={`h-6 2-6 ${
                    isActive ? "text-gold-500" : "text-blackshade-300"
                  }`}
                />
                {isActive && (
                  <div className="w-2 h-2 bg-gold-500 rounded-full blur-[1px] absolute  left-1/2 -translate-x-1/2 -bottom-[26px]"></div>
                )}{" "}
              </div>
            )}
          </NavLink>
          <NavLink to="/dashboard/profile">
            {({ isActive }) => (
              <div className="relative">
                <UserRounded
                  className={`h-6 2-6 ${
                    isActive ? "text-gold-500" : "text-blackshade-300"
                  }`}
                />
                {isActive && (
                  <div className="w-2 h-2 bg-gold-500 rounded-full blur-[1px] absolute  left-1/2 -translate-x-1/2 -bottom-[26px]"></div>
                )}
              </div>
            )}
          </NavLink>
        </div>
        <div className="absolute -top-5 left-1/2 -translate-x-1/2 flex flex-col items-center">
          <Button model="main" size="sm" className="w-12 !h-12" href="/lands">
            <BuyLand />
          </Button>
          <div className="text-body-md text-white mt-1">Buy Land</div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
