import * as React from "react";
import type { SVGProps } from "react";
const SvgEarth = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={30}
    viewBox="0 0 30 30"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.437 6.124A12.3 12.3 0 0 0 2.667 15c0 6.812 5.522 12.334 12.333 12.334q.255 0 .508-.01c-.274-1.216-.311-2.85.524-4.425.87-1.638 2.67-2.349 3.993-2.674a12.3 12.3 0 0 1 2.531-.339h.057c2.23-.024 3.236-.749 3.769-1.44.455-.592.627-1.183.802-1.782l.067-.23q.081-.706.082-1.434c0-3.365-1.348-6.416-3.533-8.641l-.036.134c-.217.79-.567 1.602-.948 2.18-.341.518-1.003 1.04-1.553 1.435-.361.26-.738.48-1.08.678l-.121.07c-.304.175-.578.332-.841.506-.576.38-1.021.79-1.321 1.408a.9.9 0 0 0-.046.654c.099.362.167.77.168 1.184.002.866-.439 1.568-1.005 2.024-.56.452-1.291.71-2.028.702-3.274-.036-5.286-2.693-5.552-5.648-.105-1.175-.62-2.364-1.271-3.403a11.8 11.8 0 0 0-1.729-2.16m1.556-1.275a14 14 0 0 1 1.868 2.372c.743 1.188 1.424 2.683 1.568 4.286.201 2.24 1.617 3.805 3.582 3.827.27.003.55-.097.75-.259.194-.156.261-.32.26-.461 0-.208-.035-.437-.096-.66a2.88 2.88 0 0 1 .175-2.057c.514-1.058 1.274-1.712 2.018-2.204.32-.21.65-.4.948-.572l.12-.068c.34-.196.638-.373.91-.569.575-.413.939-.743 1.05-.911.243-.37.517-.979.69-1.61.137-.499.175-.882.162-1.12A12.28 12.28 0 0 0 15 2.667c-2.602 0-5.017.806-7.007 2.182M29.24 16.654q.094-.814.094-1.654C29.333 7.084 22.916.667 15 .667S.667 7.084.667 15 7.084 29.334 15 29.334c7.28 0 13.293-5.428 14.212-12.458q.036-.128.064-.209zm-3.7 4.755c-.794.291-1.75.464-2.904.476h-.042l-.12.005a10.23 10.23 0 0 0-1.97.277c-1.202.295-2.256.826-2.704 1.67-.608 1.144-.521 2.39-.294 3.242a12.35 12.35 0 0 0 8.035-5.67"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgEarth;
