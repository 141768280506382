import React from "react";
import { ErrorStatus, Orbit, SuccessStatus } from "@/components/Icons";
import { StepperProps } from "./types";

const Stepper: React.FC<StepperProps> = ({
  list,
  showStepper,
  totalStatus,
  totalStatusTitle,
}) => {
  return (
    <div className="stepper-container">
      <div className="line">
        {list.map((item, index) => (
          <div className={`step ${item.status}`} key={item.id}>
            <div
              id={`step-${index + 1}`}
              className="circle-container text-blackshade-900"
            >
              <div
                className={`rounded-full relative z-10 flex items-center justify-center ${
                  item.status === "in-active"
                    ? "bg-blackshade-600"
                    : item.status === "active"
                    ? "bg-golden-linear"
                    : item.status === "in-progress"
                    ? "bg-gold-800"
                    : item.status === "failed"
                    ? "bg-orange-500"
                    : ""
                }`}
              >
                {item.icon}
              </div>
            </div>
            <div className="text-body-sm mt-2">
              <div
                className={`${
                  item.status === "failed" ? "text-orange-500" : "text-white"
                }`}
              >
                {item.title}
              </div>
            </div>
          </div>
        ))}
      </div>{" "}
      {showStepper && (
        <div className="relative my-12">
          <Orbit
            className={`${
              totalStatus === "failed" ? "text-orange-500" : "text-green-500"
            } mx-auto`}
          />
          {totalStatus === "failed" ? (
            <ErrorStatus className="absolute top-1/2 desktop:top-[116px] left-1/2 -translate-x-[45%]" />
          ) : (
            <SuccessStatus className="absolute top-1/2 desktop:top-[116px] left-1/2 -translate-x-[45%]" />
          )}
          <div className="-mt-20 text-center"> {totalStatusTitle}</div>
        </div>
      )}
    </div>
  );
};

export default Stepper;
