import React from "react";
import { Link } from "react-router-dom";
import Button from "@/components/Common/Button";
import { BigArrowRight } from "@/components/Icons";

const BlogItem = (props) => {
  const { item } = props;

  return (
    <div className="gradient-border-box p-4 mt-1 h-[420px]">
      <div className="h-full flex flex-col justify-between">
        <div>
          <div className="mb-4">
            <img
              src={item.img}
              alt="Crybox"
              className=" rounded-[20px] h-[180px] desktop:h-[220px] w-full object-cover"
            />
          </div>
          <div className="text-title-lg desktop:text-subheading-md text-white mb-4">
            <Link to={`/blog/${item.id}`}>{item.title}</Link>
          </div>
        </div>
        <div className="flex justify-between items-center mt-auto">
          <div className="text-title-lg text-gold-800">{item.time}</div>
          <Button model="ghost" size="md" href={`/blog/${item.id}`}>
            <span>Read Now</span>
            <BigArrowRight />
          </Button>
        </div>
      </div>
      <span className="bg-middle-linear absolute -bottom-6 h-[1px] w-full left-0"></span>
    </div>
  );
};

export default BlogItem;
