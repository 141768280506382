const aboutData = [
    {
        id: 1,
        title: 'Full Ownership',
        desc: 'Own virtual land as NFTs on the secure Polygon blockchain.',
        class: 'active'
    },
    {
        id: 2,
        title: 'Immersive Experiences',
        desc: 'Explore and create in a fully immersive VR and AR environment.',
    },
    {
        id: 3,
        title: 'Decentralized Governance',
        desc: 'Participate in platform development through DAO governance.',
    },
    {
        id: 4,
        title: 'Thriving Virtual Economy',
        desc: 'Engage in various monetization opportunities and DeFi activities.',
    },
]

export default aboutData;