import React from "react";
import logo from "@/assets/images/logo/logorec.png";
import {
  Facebook,
  Instagram,
  Linkedin,
  Telegram,
  Twitter,
  Youtube,
} from "@/components/Icons";
import Button from "@/components/Common/Button";
import wave from "@/scss/images/footer-land.png";
const Footer: React.FC = () => {
  const socialLinks = [
    {
      id: 1,
      link: "https://www.facebook.com/pmemetaearth",
      icon: <Facebook />,
    },
    { id: 2, link: "https://x.com/pmemetaearth?mx=2", icon: <Twitter /> },
    { id: 3, link: "https://t.me/pmemetaearth", icon: <Telegram /> },
    { id: 4, link: "https://youtube.com/@pcometaearth", icon: <Youtube /> },
    {
      id: 5,
      link: "https://www.linkedin.com/company/pmemetaearth",
      icon: <Linkedin />,
    },
    {
      id: 6,
      link: "https://www.instagram.com/pmemetaearth/",
      icon: <Instagram />,
    },
  ];

  return (
    <footer id="contact-us" className="pt-8 desktop:pt-14 pb-10 relative mt-8">
      <span className="bg-horizontal-linear absolute h-[1px] w-full top-0"></span>
      <div className="container">
        <div className="text-center mx-auto flex flex-col items-center">
          <img className="w-14 h-14 mb-3 desktop:mb-4" src={logo} alt="logo" />
          <span className="text-gradiant text-subheading-md desktop:text-headline-md">
            PCO Meta Earth
          </span>
          <p className="mt-3 desktop:mt-4 text-blackshade-200 text-label-lg desktop:text-title-md max-w-[795px]">
            PCOLand is a platform for selling defined metaverse lands all over
            the world. In PCOLand, you can easily buy and sell your land in 3D
            space and enjoy your metaverse world.
          </p>
          <div className="flex justify-center gap-5 desktop:gap-6 mt-6 mb-10 desktop:mb-12 flex-wrap">
            {socialLinks.map((item, index) => (
              <a
                href={item.link}
                key={item.id}
                target="_blank"
                rel="noopener noreferrer"
                className="w-10 h-10 desktop:w-12 desktop:h-12 rounded-full border-solid border !border-gold-500 flex justify-center items-center"
              >
                {item.icon}
              </a>
            ))}
          </div>
          <div className="flex flex-wrap [&>a]:w-full  desktop:[&>a]:w-max">
            <Button model="ghost" size="md" href="/privacy-policy">
              Privacy Policy
            </Button>{" "}
            <Button model="ghost" size="md" href="/terms-conditions">
              Terms & Conditions
            </Button>
          </div>
        </div>{" "}
        <div className="mt-10 desktop:mt-[112px] frame-effects bg-dashboard-frame rounded-[20px] p-4 text-blackshade-200 text-title-md text-center">
          PCO 2024 - ALL rights reserved
        </div>
      </div>
      <img
        src={wave}
        alt=""
        className="absolute left-0 right-0 bottom-0 w-full -z-10 h-[145px] desktop:h-[180px]"
      />
      {/* <Land2/> */}
    </footer>
  );
};

export default Footer;
