import React from "react";
import background from "@/scss/images/profile-banner.png";
import phoenix from "@/scss/images/phoenix.png";
import { motion } from "framer-motion";

interface BannerProps {
  id: string;
}
const Banner: React.FC<BannerProps> = ({ id }) => {
  return (
    <div className="relative">
      <motion.div
        initial={{ y: 10, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: -10, opacity: 0 }}
        transition={{ duration: 0.8 }}
      >
        <img
          src={background}
          alt="background"
          className="w-full absolute top-0 h-[114px] md:h-[161px]"
        />{" "}
        <div className="relative z-1 py-3 px-3 pb-2 md:px-6 md:pt-8 md:!pb-6 ">
          <h4 className="text-blackshade-900 text-subheading-sm md:text-headline-md pr-[5rem]">
            Welcome To PCOLAND!
          </h4>
          <span className="text-blackshade-900 text-label-lg md:text-title-lg mt-2 flex">
            Your Pcoland id: {id}
          </span>
        </div>
      </motion.div>
      <motion.div
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: -10, opacity: 0 }}
        transition={{ duration: 1.2, delay: 0.7 }}
      >
        <div className="relative">
          <img
            src={phoenix}
            alt="phoenix"
            className="absolute -top-[8rem] md:-top-[11rem] -right-7 h-[182px] md:h-[275px]"
          />
        </div>
      </motion.div>
    </div>
  );
};

export default Banner;
