import React from "react";
import { Outlet } from "react-router-dom";

interface ContentProps {
  className?: string;
}

const Content: React.FC<ContentProps> = ({ className }) => {
  return (
    <div className="p-6 desktop:p-8 pb-20 min-h-[84vh]">
      <Outlet />
    </div>
  );
};

export default Content;
