import React from "react";
import SidebarNav from "./SidebarNav";

const Sidebar: React.FC = () => {
  return (
    <div id="sidebar" className="dashbpard-sidebar">
      <SidebarNav />
    </div>
  );
};

export default Sidebar;
