import * as React from "react";
import type { SVGProps } from "react";
const SvgLinkedin = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <g fill="#FFB70F" clipPath="url(#linkedin_svg__a)">
      <path d="M5.588 7.72H.77a.387.387 0 0 0-.387.388v15.478c0 .214.173.387.387.387h4.818a.387.387 0 0 0 .387-.387V8.108a.387.387 0 0 0-.387-.387M3.18.026A3.18 3.18 0 0 0 0 3.202 3.18 3.18 0 0 0 3.18 6.38a3.18 3.18 0 0 0 3.176-3.177A3.18 3.18 0 0 0 3.179.026M17.84 7.336c-1.935 0-3.365.832-4.233 1.777V8.108a.387.387 0 0 0-.387-.387H8.606a.387.387 0 0 0-.387.387v15.478c0 .214.173.387.387.387h4.807a.387.387 0 0 0 .387-.387v-7.658c0-2.58.701-3.586 2.5-3.586 1.96 0 2.115 1.612 2.115 3.719v7.525c0 .214.174.387.387.387h4.81a.387.387 0 0 0 .387-.387v-8.49c0-3.837-.732-7.76-6.159-7.76" />
    </g>
    <defs>
      <clipPath id="linkedin_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgLinkedin;
