import { LandingMenuItem } from "./types";

const Menu: LandingMenuItem[] = [
  {
    path: "/",
    title: "Home",
  },
  {
    path: "/lands",
    title: "Lands",
  },
  {
    path: "/contracts",
    title: "Contracts",
  },
  {
    path: "/#about",
    title: "About",
  },
  {
    path: "/#features",
    title: "Features",
  },
  {
    path: "/#roadmap",
    title: "Roadmap",
  },
  {
    path: "/#faq",
    title: "FAQ",
  },
  {
    path: "https://s1.pco.land/whitepaper.pdf",
    title: "WHITEPAPER",
    isDownloadable: true,
  },
  // {
  //   path: "/#contact-us",
  //   title: "Contact Us",
  // },
];

export default Menu;
