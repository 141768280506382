import * as React from "react";
import type { SVGProps } from "react";
const SvgMultiShaped = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={641}
    height={998}
    fill="none"
    viewBox="0 0 641 998"
    {...props}
  >
    <path
      stroke="#FFB70F"
      d="M769.603-97.02c68.381-31.3 149.733-81.885 216.094-46.505 68.733 36.642 52.823 142.155 90.833 210.138 29.67 53.059 101.62 82.698 106.76 143.269 5.14 60.539-67.98 99.774-81.1 159.096-20.03 90.533 63.47 221.625-14.27 272.154-75.92 49.346-155.615-90.294-245.194-103.516-74.634-11.015-161.855 84.305-218.963 35.008-59.615-51.462 12.601-155.438-8.459-231.324-17.248-62.149-101.475-96.336-100.37-160.824 1.103-64.394 62.269-109.51 105.816-156.96C664.812-24.495 710.349-69.9 769.603-97.021Z"
      clipRule="evenodd"
    />
    <path
      stroke="#FFB70F"
      d="M771.375-86.08c69.432-28.894 152.499-76.61 217.586-38.936 67.409 39.019 47.829 143.913 83.449 213.18 27.79 54.062 98.67 86.195 101.69 146.909 3.02 60.681-71.42 97.34-86.61 156.168-23.17 89.779 55.7 223.705-23.76 271.49-77.594 46.666-152.362-95.67-241.426-112.01-74.203-13.613-164.697 78.605-220.05 27.345-57.783-53.511 18.018-154.903-.381-231.478-15.069-62.713-98.052-99.819-94.697-164.229 3.351-64.316 66.054-107.27 111.23-153.172 45.71-46.444 92.805-90.231 152.969-115.268Z"
      clipRule="evenodd"
      opacity={0.959}
    />
    <path
      stroke="#FFB70F"
      d="M772.768-75.086c70.398-26.452 155.08-71.24 218.812-31.317 66.01 41.347 42.78 145.494 75.95 215.963 25.9 54.998 95.61 89.585 96.51 150.367.9 60.749-74.78 94.788-92 153.051-26.29 88.915 47.85 225.512-33.22 270.495-79.184 43.93-148.939-100.929-237.378-120.367-73.683-16.195-167.34 72.809-220.871 19.648-55.88-55.494 23.413-154.18 7.698-231.35-12.87-63.201-94.508-103.18-88.907-167.434 5.593-64.16 69.757-104.9 116.507-149.196 47.304-44.821 95.898-86.938 156.899-109.86Z"
      clipRule="evenodd"
      opacity={0.917}
    />
    <path
      stroke="#FFB70F"
      d="M773.776-64.049c71.278-23.98 157.472-65.784 219.772-23.662 64.522 43.626 37.672 146.899 68.372 218.482 23.96 55.869 92.42 92.868 91.2 153.644-1.22 60.744-78.04 92.121-97.29 149.747-29.38 87.944 39.96 227.045-42.64 269.171-80.665 41.14-145.323-106.065-233.029-128.578-73.074-18.757-169.78 66.925-221.422 11.928-53.91-57.411 28.779-153.269 15.766-230.941-10.656-63.611-90.849-106.415-83.009-170.434 7.828-63.926 73.375-102.402 121.643-145.04C661.978-2.875 712.012-43.27 773.776-64.049Z"
      clipRule="evenodd"
      opacity={0.876}
    />
    <path
      stroke="#FFB70F"
      d="M774.399-52.983c72.071-21.478 159.671-60.249 220.463-15.978 62.958 45.851 32.528 148.124 60.708 220.735 21.99 56.671 89.12 96.036 85.78 156.733-3.34 60.665-81.21 89.341-102.46 146.26-32.43 86.865 32.01 228.302-52.005 267.519-82.051 38.3-141.532-111.072-228.4-136.632-72.374-21.295-172.012 60.959-221.703 4.193-51.873-59.257 34.111-152.171 23.817-230.25-8.43-63.944-87.08-109.52-77.011-173.227 10.055-63.614 76.904-99.778 126.631-140.706 50.315-41.412 101.728-80.037 164.18-98.647Z"
      clipRule="evenodd"
      opacity={0.835}
    />
    <path
      stroke="#FFB70F"
      d="M774.635-41.903c72.777-18.949 161.677-54.64 220.887-8.274 61.318 48.02 27.338 149.169 52.968 222.72 20 57.403 85.71 99.088 80.26 159.631-5.46 60.511-84.28 86.452-107.5 142.595-35.442 85.681 24.02 229.28-61.315 265.541-83.338 35.413-137.569-115.944-223.492-144.52-71.587-23.808-174.035 54.919-221.715-3.546-49.773-61.032 39.401-150.889 31.838-229.279-6.193-64.2-83.205-112.493-70.918-175.81 12.269-63.224 80.34-97.033 131.464-136.2 51.73-39.631 104.46-76.438 167.523-92.858Z"
      clipRule="evenodd"
      opacity={0.794}
    />
    <path
      stroke="#FFB70F"
      d="M774.484-30.82c73.394-16.399 163.485-48.965 221.041-.56 59.605 50.13 22.115 150.031 45.155 224.431 17.99 58.067 82.21 102.019 74.64 162.335-7.56 60.284-87.24 83.458-112.41 138.757-38.404 84.391 16.01 229.978-70.538 263.239-84.523 32.483-133.439-120.674-218.312-152.231-70.712-26.292-175.845 48.811-221.455-11.282-47.614-62.732 44.642-149.421 39.82-228.028-3.949-64.377-79.229-115.329-64.74-178.178 14.468-62.757 83.677-94.17 136.138-131.53 53.081-37.8 107.064-72.745 170.661-86.954Z"
      clipRule="evenodd"
      opacity={0.752}
    />
    <path
      stroke="#FFB70F"
      d="M773.947-19.751c73.921-13.826 165.094-43.228 220.926 7.155 57.827 52.18 16.867 150.712 37.297 225.871 15.95 58.659 78.6 104.826 68.93 164.841-9.66 59.983-90.1 80.363-117.184 134.749-41.329 83 7.972 230.397-79.685 260.617-85.605 29.514-129.146-125.257-212.866-159.757-69.752-28.744-177.442 42.644-220.928-19.004-45.394-64.356 49.831-147.773 47.755-226.5-1.701-64.475-75.156-118.023-58.482-180.328 16.649-62.214 86.912-91.193 140.645-126.699C654.723 25.27 709.892-7.77 773.947-19.75Z"
      clipRule="evenodd"
      opacity={0.711}
    />
    <path
      stroke="#FFB70F"
      d="M773.023-8.707c74.359-11.238 166.503-37.44 220.542 14.861 55.965 54.167 11.595 151.209 29.395 227.035 13.89 59.18 74.89 107.505 63.13 167.147-11.75 59.609-92.85 77.169-121.813 130.577-44.201 81.507-.074 230.535-88.732 257.678-86.584 26.507-124.697-129.689-207.162-167.09-68.706-31.161-178.821 36.426-220.129-26.703-43.121-65.9 54.957-145.943 55.63-224.694.551-64.496-70.991-120.574-52.153-182.26 18.81-61.595 90.042-88.104 144.981-121.713 55.589-34.006 111.877-65.1 176.311-74.838Z"
      clipRule="evenodd"
      opacity={0.67}
    />
    <path
      stroke="#FFB70F"
      d="M771.715 2.299c74.706-8.636 167.708-31.607 219.889 22.548 54.046 56.088 6.31 151.522 21.456 227.923 11.81 59.629 71.09 110.053 57.26 169.248-13.82 59.163-95.491 73.881-126.301 126.246-47.018 79.915-8.119 230.392-97.67 254.424-87.456 23.47-120.095-133.961-201.204-174.217-67.577-33.54-179.984 30.163-219.063-34.369-40.796-67.365 60.016-143.936 63.437-222.617 2.802-64.436-66.739-122.978-45.76-183.968 20.948-60.901 93.062-84.908 149.14-116.58C649.641 38.893 706.98 9.783 771.715 2.3Z"
      clipRule="evenodd"
      opacity={0.629}
    />
    <path
      stroke="#FFB70F"
      d="M770.023 13.252c74.962-6.024 168.709-25.734 218.968 30.209 52.049 57.939 1.018 151.649 13.489 228.532 9.73 60.005 67.2 112.468 51.32 171.144-15.88 58.644-98.013 70.503-130.631 121.761-49.779 78.225-16.155 229.968-106.491 250.861-88.221 20.403-115.346-138.072-195.001-181.134-66.365-35.877-180.927 23.863-217.73-41.993-38.419-68.748 65.003-141.754 71.168-220.267 5.049-64.3-62.407-125.232-39.312-185.453 23.061-60.133 95.969-81.609 153.118-111.303 57.825-30.046 116.146-57.137 181.102-62.357Z"
      clipRule="evenodd"
      opacity={0.588}
    />
    <path
      stroke="#FFB70F"
      d="M767.95 24.14c75.127-3.404 169.504-19.832 217.781 37.831 49.999 59.721-4.275 151.593 5.5 228.865 7.63 60.308 63.239 114.744 45.319 172.83-17.92 58.054-100.413 67.04-134.8 117.128-52.478 76.44-24.17 229.264-115.18 246.991-88.88 17.312-110.457-142.012-188.561-187.828-65.072-38.172-181.65 17.534-216.132-49.566-35.997-70.047 69.911-139.399 78.812-217.649 7.29-64.085-57.998-127.334-32.816-186.713 25.146-59.291 98.758-78.209 156.909-105.891 58.839-28.01 118.07-53.05 183.168-55.999Z"
      clipRule="evenodd"
      opacity={0.546}
    />
    <path
      stroke="#FFB70F"
      d="M765.499 34.948c75.199-.78 170.093-13.903 216.327 45.41 47.884 61.429-9.563 151.35-2.49 228.916 5.52 60.537 59.204 116.881 39.254 174.306-19.927 57.394-102.686 63.496-138.8 112.353-55.114 74.562-32.157 228.281-123.73 242.821-89.429 14.199-105.433-145.781-181.891-194.295-63.7-40.419-182.151 11.185-214.27-57.079-33.53-71.26 74.733-136.874 86.359-214.766 9.522-63.791-53.519-129.28-26.279-187.743 27.199-58.379 101.427-74.716 160.509-100.352 59.78-25.939 119.849-48.895 185.011-49.571Z"
      clipRule="evenodd"
      opacity={0.505}
    />
    <path
      stroke="#FFB70F"
      d="M762.671 45.664c75.181 1.845 170.475-7.959 214.611 52.932 45.708 63.063-14.839 150.925-10.477 228.69 3.404 60.693 55.085 118.876 33.15 175.57-21.921 56.664-104.842 59.873-142.64 107.44-57.682 72.594-40.104 227.02-132.129 238.355-89.871 11.07-100.281-149.371-174.999-200.524-62.251-42.618-182.43 4.821-212.148-64.522-31.023-72.387 79.465-134.183 93.802-211.621 11.742-63.42-48.974-131.07-19.711-188.547 29.22-57.393 103.973-71.13 163.913-94.688 60.65-23.837 121.483-44.684 186.628-43.085Z"
      clipRule="evenodd"
      opacity={0.464}
    />
    <path
      stroke="#FFB70F"
      d="M759.472 56.275c75.071 4.468 170.648-2.004 212.633 60.389 43.485 64.62-20.098 150.315-18.453 228.186 1.284 60.775 50.898 120.726 27.003 176.62-23.885 55.864-106.868 56.177-146.302 102.397-60.181 70.535-48.003 225.481-140.367 233.598-90.203 7.927-95.008-152.781-167.895-206.51-60.726-44.764-182.487-1.549-209.767-71.886-28.478-73.426 84.099-131.328 101.131-208.219 13.948-62.971-44.371-132.699-13.119-189.12 31.205-56.338 106.392-67.458 167.118-88.91 61.444-21.705 122.967-40.416 188.018-36.545Z"
      clipRule="evenodd"
      opacity={0.422}
    />
    <path
      stroke="#FFB70F"
      d="M755.904 66.768c74.869 7.085 170.614 3.953 210.396 67.773 41.2 66.098-25.331 149.522-26.405 227.403-.838 60.782 46.657 122.429 20.823 177.455-25.821 54.996-108.764 52.413-149.787 97.228-62.606 68.393-55.843 223.669-148.435 228.557-90.424 4.774-89.617-156.003-160.585-212.243-59.126-46.856-182.322-7.917-207.13-79.163-25.898-74.375 88.631-128.313 108.336-204.563 16.137-62.446-39.713-134.166-6.511-189.462 33.152-55.215 108.682-63.704 170.119-83.024 62.164-19.548 124.303-36.1 189.179-29.96Z"
      clipRule="evenodd"
      opacity={0.381}
    />
    <path
      stroke="#FFB70F"
      d="M751.972 77.13c74.576 9.694 170.373 9.905 207.902 75.075 38.869 67.495-30.533 148.547-34.325 226.342-2.958 60.717 42.357 123.983 14.617 178.074-27.724 54.061-110.526 48.585-153.089 91.941-64.954 66.166-63.614 221.584-156.32 223.238-90.535 1.615-84.119-159.035-153.08-217.718-57.456-48.892-181.935-14.275-204.242-86.344-23.286-75.233 93.056-125.141 115.409-200.657 18.307-61.845-35.005-135.471.105-189.574 35.06-54.025 110.839-59.872 172.914-77.036 62.808-17.367 125.487-31.74 190.109-23.34Z"
      clipRule="evenodd"
      opacity={0.34}
    />
    <path
      stroke="#FFB70F"
      d="M747.681 87.349c74.192 12.29 169.923 15.844 205.155 82.284 36.49 68.811-35.699 147.391-42.203 225.007-5.076 60.576 38.004 125.385 8.394 178.475-29.595 53.061-112.155 44.699-156.205 86.543-67.224 63.859-71.309 219.229-164.016 217.646-90.537-1.545-78.517-161.874-145.388-222.928-55.715-50.867-181.326-20.615-201.104-93.419-20.647-76 97.366-121.818 122.341-196.507 20.455-61.168-30.256-136.61 6.721-189.455 36.924-52.768 112.861-55.968 175.497-70.954 63.376-15.165 126.518-27.342 190.808-16.692Z"
      clipRule="evenodd"
      opacity={0.299}
    />
    <path
      stroke="#FFB70F"
      d="M743.035 97.411c73.719 14.872 169.267 21.765 202.159 89.394 34.066 70.043-40.821 146.056-50.03 223.397-7.187 60.362 33.605 126.636 2.16 178.659-31.428 51.996-113.647 40.758-159.13 81.039-69.412 61.474-78.916 216.607-171.511 211.79-90.428-4.704-72.82-164.516-137.52-227.866-53.905-52.781-180.496-26.932-197.721-100.381C213.46 476.769 333 435.098 360.567 361.325c22.576-60.417-25.471-137.582 13.328-189.105 38.743-51.447 114.746-51.994 177.866-64.786 63.867-12.943 127.396-22.91 191.274-10.023Z"
      clipRule="evenodd"
      opacity={0.258}
    />
    <path
      stroke="#FFB70F"
      d="M738.042 107.306c73.155 17.435 168.404 27.659 198.916 96.394 31.601 71.189-45.894 144.542-57.796 221.515-9.289 60.075 29.164 127.731-4.077 178.626-33.223 50.867-115 36.766-161.861 75.436-71.515 59.014-86.428 213.72-178.799 205.675-90.208-7.857-67.034-166.957-129.483-232.527-52.03-54.629-179.446-33.214-194.098-107.22-15.294-77.255 105.627-114.729 135.752-187.494 24.671-59.593-20.654-138.388 19.92-188.525 40.515-50.064 116.49-47.958 180.018-58.539 64.28-10.707 128.118-18.45 191.508-3.341Z"
      clipRule="evenodd"
      opacity={0.216}
    />
    <path
      stroke="#FFB70F"
      d="M732.706 117.02c72.502 19.978 167.336 33.519 195.431 103.278 29.097 72.248-50.91 142.852-65.492 219.362-11.38 59.714 24.689 128.672-10.308 178.375-34.979 49.677-116.213 32.731-164.395 69.741-73.531 56.483-93.834 210.574-185.868 199.31-89.879-11.001-61.166-169.195-121.289-236.904-50.092-56.412-178.178-39.456-190.238-113.929-12.589-77.742 109.566-110.972 142.212-182.642 26.736-58.695-15.811-139.024 26.488-187.715 42.237-48.619 118.093-43.863 181.952-52.221 64.614-8.456 128.683-13.967 191.507 3.345Z"
      clipRule="evenodd"
      opacity={0.175}
    />
    <path
      stroke="#FFB70F"
      d="M727.035 126.541c71.76 22.497 166.064 39.339 191.707 110.036 26.558 73.22-55.865 140.988-73.107 216.943-13.458 59.281 20.183 129.455-16.528 177.907-36.691 48.426-117.284 28.655-166.728 63.961-75.458 53.882-101.126 207.171-192.711 192.702-89.44-14.131-55.224-171.227-112.948-240.993-48.092-58.126-176.692-45.651-186.145-120.498-9.868-78.134 113.372-107.082 148.499-177.568 28.769-57.727-10.949-139.492 33.023-186.676 43.908-47.116 119.552-39.716 183.663-45.84 64.871-6.196 129.093-9.467 191.275 10.026Z"
      clipRule="evenodd"
      opacity={0.134}
    />
    <path
      stroke="#FFB70F"
      d="M721.034 135.86c70.931 24.986 164.59 45.11 187.75 116.659 23.987 74.102-60.751 138.952-80.633 214.259-15.518 58.775 15.653 130.08-22.727 177.222-38.358 47.116-118.213 24.544-168.859 58.103-77.292 51.216-108.294 203.516-199.318 185.859-88.893-17.244-49.215-173.05-104.469-244.788-46.035-59.769-174.991-51.789-181.826-126.921-7.136-78.431 117.04-103.059 154.606-172.277 30.765-56.688-6.075-139.789 39.517-185.41 45.526-45.555 120.865-35.519 185.151-39.402 65.047-3.928 129.345-4.956 190.808 16.696Z"
      clipRule="evenodd"
      opacity={0.092}
    />
    <path
      stroke="#FFB70F"
      d="M714.712 144.963c70.016 27.447 162.916 50.827 183.565 123.14 21.385 74.894-65.564 136.748-88.063 211.315-17.559 58.197 11.104 130.547-28.897 176.32-39.979 45.749-118.997 20.404-170.784 52.175-79.032 48.487-115.331 199.613-205.683 178.79-88.237-20.336-43.146-174.662-95.862-248.284-43.921-61.34-173.077-57.866-177.287-133.19-4.393-78.632 120.566-98.912 160.525-166.777 32.725-55.579-1.193-139.915 45.964-183.918 47.088-43.938 122.031-31.279 186.413-32.916 65.145-1.656 129.439-.439 190.109 23.345Z"
      clipRule="evenodd"
      opacity={0.051}
    />
    <path
      stroke="#FFB70F"
      d="M708.08 153.839c69.016 29.874 161.043 56.482 179.155 129.472 18.759 75.595-70.295 134.377-95.383 208.113-19.58 57.549 6.541 130.855-35.033 175.205-41.552 44.325-119.637 16.238-172.501 46.183-80.677 45.699-122.227 195.465-211.798 171.502-87.473-23.403-37.024-176.061-87.138-251.479-41.754-62.834-170.953-63.87-172.53-139.296-1.647-78.737 123.944-94.644 166.246-161.073 34.645-54.403 3.692-139.871 52.355-182.201 48.593-42.268 123.048-27.001 187.449-26.39 65.162.618 129.375 4.078 189.178 29.964Z"
      clipRule="evenodd"
      opacity={0.01}
    />
  </svg>
);
export default SvgMultiShaped;
