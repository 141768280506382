import React from "react";
import Button from "@/components/Common/Button";
import stars from "@/scss/images/stars.png";
import notFound from "@/scss/images/404.png";
import wave from "@/scss/images/hero-bg.png";

const Error: React.FC = () => {
  return (
    <div>
      <img
        src={stars}
        alt="stars"
        className="absolute h-[400px] desktop:h-[500px] top-0 -z-10 w-screen"
      />
      <div className="flex flex-col items-center p-6 text-center">
        <img src={notFound} alt="404" className="h-[255px] desktop:h-[383px]" />
        <h3 className="text-display-sm desktop:text-display-md text-white mb-2 desktop:mb-3 -mt-14 desktop:-mt-24">
          Page Not Found
        </h3>
        <div className="text-title-lg desktop:text-subheading-md text-blackshade-200">
          We Can’t Find The Page You Are Looking For
        </div>
        <Button model="main" size="lg" className="mt-8" href="/">
          Go Back Home
        </Button>
      </div>
      <img
        src={wave}
        alt="wave"
        className="absolute bottom-0 left-0 right-0 -z-10 w-full h-[200px] desktop:h-[241px] object-cover  desktop:object-fill "
      />
    </div>
  );
};

export default Error;
