import React, { useEffect } from "react";
import { useSignMessage, useAccount } from "wagmi";
import { useAuth } from "@/context/authProvider";
import BaseModal from "../BaseModal";
import Button from "@/components/Common/Button";
import img from "@/scss/images/sign-modal.png";
const SignModal: React.FC = () => {
  const { showSignModal, setShowSignModal, handleDisconnection, verifyUser } =
    useAuth();
  const closeModalHandler = () => {
    setShowSignModal(false);
    handleDisconnection();
  };

  const { address, isDisconnected } = useAccount();
  const { signMessage, data: signature, isSuccess, isError } = useSignMessage();

  useEffect(() => {
    if (isError) {
      closeModalHandler();
    }
  }, [isError]);

  useEffect(() => {
    if (isSuccess) {
      setShowSignModal(false);
      verifyUser(signature);
    }
  }, [isSuccess, signature]);

  useEffect(() => {
    if (isDisconnected) {
      closeModalHandler();
    }
  }, [isDisconnected]);

  return showSignModal ? (
    <BaseModal
      closeModalHandler={closeModalHandler}
      title="Sign Message"
      footer={
        <Button
          model="main"
          size="sm"
          onClick={() => signMessage({ message: address || "" })}
        >
          Sign
        </Button>
      }
    >
      <div>
        <img src={img} alt="sign" className="mx-auto" />
        <p className="text-label-md text-blackshade-200 text-center">
          To proceed, please sign the message using your wallet. This will
          verify your identity and allow us to perform secure actions on your
          behalf.
        </p>
      </div>
    </BaseModal>
  ) : null;
};

export default SignModal;
