import React from "react";
import { forwardRef, ReactNode } from "react";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  value?: string | number;
  label?: string;
  prefixIcon?: ReactNode;
  suffixIcon?: ReactNode;
  showError?: boolean;
  inputClassName?: string;
  model?: "lg" | "md";
  msg?: ReactNode;
  isError?: boolean;
}

const Input = forwardRef<HTMLInputElement, InputProps>(function Input(
  {
    value,
    label,
    prefixIcon,
    suffixIcon,
    showError = false,
    inputClassName,
    model,
    msg,
    isError,
    ...props
  },
  ref
) {
  return (
    <div className="relative">
      <div className="absolute left-4  top-1/2 -translate-y-1/2 [&>svg]:h-6 [&>svg]:w-6 [&>svg]:text-blackshade-400">
        {prefixIcon}
      </div>
      <input
        type="text"
        id="floating_outlined"
        className={`base-input  peer 
           ${suffixIcon ? "pr-12" : "pr-2.5"}
          ${prefixIcon ? "pl-12" : "pl-2.5"} ${inputClassName ?? ""} ${
          label ? "pt-[28px]" : "pt-2"
        } ${model === "lg" ? "h-14" : "h-12"}  ${isError ? "isError" : ""}`}
        placeholder=" "
        {...props}
        value={value}
      />
      <div className="absolute right-4 top-1/2 -translate-y-1/2 [&>svg]:h-6 [&>svg]:w-6">
        {suffixIcon}
      </div>
      <label
        htmlFor="floating_outlined"
        className={`absolute text-sm   duration-300 transform 
          -translate-y-0 scale-100 top-2 z-10 origin-[0]  
          peer-focus:text-blackshade-300  peer-placeholder-shown:scale-100 
          peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-6 peer-focus:scale-100 peer-focus:-translate-y-4 
           start-1 ${prefixIcon ? "!px-11 " : "px-2.5"} ${
          isError ? "text-red-600" : "text-blackshade-300"
        }`}
      >
        {label}
      </label>
      {msg && (
        <div className="absolute -bottom-6 left-5 text-body-md">{msg}</div>
      )}
    </div>
  );
});

export default Input;
