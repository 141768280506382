import React, { ReactNode } from "react";
import TableMobileCard from "@/components/Common/TableMobileCard";

interface data {
  number: number;
  name: string;
  status: ReactNode;
}

interface MobileTableProps {
  data?: data;
  isLoading?: boolean;
}
const MobileTable: React.FC<MobileTableProps> = ({ data, isLoading }) => {
  return (
    <TableMobileCard
      header={
        <>
          <div className="flex gap-1 items-center pl-1">
            <div className="text-body-lg">Number:</div>
            <span className="text-label-sm">
              {isLoading ? (
                <div className="bg-blackshade-600 rounded w-12 h-3 animate-pulse"></div>
              ) : (
                data?.number.toString()
              )}
            </span>
          </div>
        </>
      }
    >
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-1 text-blackshade-200 text-body-md">
          <span>Name</span>
        </div>
        <div className="text-white text-body-lg">
          {isLoading ? (
            <div className="bg-blackshade-600 rounded w-14 h-3 animate-pulse"></div>
          ) : (
            data?.name
          )}
        </div>
      </div>
      <div className="flex justify-between items-center mt-2">
        <div className="flex items-center gap-1 text-blackshade-200 text-body-md">
          <span>Status</span>
        </div>
        <div className="text-white text-body-lg">
          {isLoading ? (
            <div className="bg-blackshade-600 rounded w-12 h-3 animate-pulse"></div>
          ) : (
            data?.status
          )}
        </div>
      </div>
    </TableMobileCard>
  );
};

export default MobileTable;
