import React from "react";
import TableMobileCard from "@/components/Common/TableMobileCard";
import {
  Dollar,
  Earth,
  LayersMinimalistic,
  Nft,
  TagHorizontal,
} from "@/components/Icons";
import Button from "@/components/Common/Button";

interface MobileTableProps {
  landtype: string;
  purchasepriceusd: string;
  tile_count: string;
  tokenId: string;
  image: string;
  coordination: { latitude: string; longitude: string };
  is_staked: boolean;
  handleStacking: () => void;
}
const MobileTable: React.FC<MobileTableProps> = ({
  tokenId,
  image,
  tile_count,
  landtype,
  purchasepriceusd,
  coordination,
  is_staked,
  handleStacking,
}) => {
  return (
    <TableMobileCard
      header={
        <>
          <div className="flex gap-1 items-center pl-1">
            <TagHorizontal />
            <div className="text-body-lg">Token/Land ID:</div>
            <span className="text-label-sm">{tokenId}</span>
          </div>
          <Button
            href={
              coordination
                ? `/lands?lat=${coordination.latitude}&lng=${coordination.longitude}`
                : "/"
            }
            model="tonal"
            size="sm"
          >
            <Earth className="mr-1 h-5 w-5" />
            Open in map
          </Button>
        </>
      }
      action={
        <div className="flex gap-4 [&>button]:flex-1">
          <Button
            model="outlined"
            size="sm"
            disabled={is_staked}
            onClick={handleStacking}
          >
            Stake
          </Button>
          {is_staked ? (
            <Button disabled model="error" size="sm">
              Sell
            </Button>
          ) : (
            <a
              className="button error flex-1"
              target="_blank"
              rel="noopener noreferrer"
              href={`https://opensea.io/assets/matic/${process.env.REACT_APP_LAND_TO_NFT_CONTRACT_ADDRESS}/${tokenId}`}
            >
              Sell
            </a>
          )}
        </div>
      }
    >
      <div className="flex gap-3 flex-wrap">
        <img
          src={image}
          alt="Stake"
          className="object-fit-cover d-block rounded-2xl w-[162px] h-[80px] md:w-[45%]"
        />
        <div className="flex-1">
          <div className="flex justify-between items-center mb-2">
            <div className="flex gap-1 items-center text-blackshade-200 text-body-md">
              <Dollar />
              Value
            </div>
            <div className="text-white text-body-lg">{purchasepriceusd}</div>
          </div>
          <div className="flex justify-between items-center mb-2">
            <div className="flex gap-1 items-center text-blackshade-200 text-body-md">
              <LayersMinimalistic /> Tiles
            </div>
            <div className="text-white text-body-lg">{tile_count}</div>
          </div>
          <div className="flex justify-between items-center mb-2">
            <div className="flex gap-1 items-center text-blackshade-200 text-body-md">
              <Nft className="w-4 h-4" />
              NFT Type
            </div>
            <div className="text-white text-body-lg">{landtype}</div>
          </div>
        </div>
      </div>
    </TableMobileCard>
  );
};

export default MobileTable;
