import { create } from "zustand";

const landStore = (set) => ({
  userTiles: null,
  allTiles: null,
  setUserTiles: (userTiles) => set({ userTiles }),
  setAllTile: (allTiles) => set({ allTiles }),
});

export const useLandStore = create(landStore);
