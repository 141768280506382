import React from "react";
import { TableProps } from "./types";

const Table: React.FC<TableProps> = ({ columns, data, isLoading }) => {
  return (
    <div className="container mx-auto px-4">
      <ul className="base-table">
        {/* Table Header */}
        <li className="base-table__header">
          {columns.map((col) => (
            <div
              key={col.key}
              className="col col-1 basis-1/1"
              style={{ flexBasis: col.width || "auto" }}
            >
              {col.header}
            </div>
          ))}
        </li>
        {/* Table Rows */}
        {isLoading
          ? Array.from({ length: 4 }).map((_, index) => (
              <li className="base-table__row" key={index}>
                <div
                  role="status"
                  className="animate-pulse flex justify-between items-center w-full"
                >
                  {columns.map((col, colIndex) => (
                    <div
                      style={{ flexBasis: col.width || "auto" }}
                      key={colIndex}
                    >
                      <div className="h-4 bg-blackshade-600 rounded-full w-1/2"></div>
                    </div>
                  ))}
                </div>
              </li>
            ))
          : data.map((row, rowIndex) => (
              <li key={rowIndex} className="base-table__row">
                {columns.map((col) => (
                  <div
                    key={col.key}
                    className="col col-1 basis-1/1"
                    style={{ flexBasis: col.width || "auto" }}
                    data-label={col.header}
                  >
                    {row[col.key]}
                  </div>
                ))}
              </li>
            ))}
      </ul>
    </div>
  );
};

export default Table;
