import * as React from "react";
import type { SVGProps } from "react";
const SvgTagHorizontal = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={15}
    height={13}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.109.167H7.93c.72 0 1.298 0 1.772.047.49.048.906.15 1.298.374.391.224.693.534.99.935.29.39.594.894.977 1.528l.48.794c.32.529.58.958.756 1.33.184.39.296.754.296 1.159 0 .404-.112.769-.296 1.157-.177.374-.436.803-.756 1.332l-.48.794c-.383.633-.688 1.138-.976 1.527-.298.401-.6.71-.99.935-.393.225-.81.326-1.3.374-.473.047-1.05.047-1.771.047H6.109c-1.26 0-2.257 0-3.037-.108-.805-.112-1.453-.348-1.962-.874-.507-.524-.732-1.187-.839-2.01C.167 8.702.167 7.674.167 6.37v-.073c0-1.305 0-2.333.104-3.137.107-.824.332-1.487.839-2.01C1.619.622 2.267.386 3.072.274 3.852.167 4.85.167 6.109.167m-2.9 1.099c-.687.096-1.087.275-1.38.578-.296.306-.473.726-.566 1.445-.095.732-.096 1.695-.096 3.045s0 2.312.096 3.044c.093.72.27 1.139.566 1.445.293.303.693.483 1.38.578.704.098 1.632.1 2.938.1h1.758c.753 0 1.28-.001 1.7-.043.406-.04.671-.116.899-.247.229-.13.434-.324.684-.663.257-.346.539-.81.937-1.47l.454-.75c.336-.557.569-.942.72-1.264.148-.311.201-.525.201-.73s-.053-.42-.2-.73c-.152-.322-.385-.708-.721-1.264l-.454-.751c-.398-.659-.68-1.123-.937-1.47-.25-.338-.455-.532-.684-.663-.228-.13-.493-.207-.9-.247-.418-.041-.946-.042-1.699-.042H6.147c-1.306 0-2.234.001-2.938.099M4 2.83a.5.5 0 0 1 .5.5v6.004a.5.5 0 0 1-1 0V3.33a.5.5 0 0 1 .5-.5"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgTagHorizontal;
