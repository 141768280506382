import { YellowOrbit } from "@/components/Icons";
import React, { ReactNode } from "react";

interface EmptyPageProps {
  title: string;
  info: string;
  icon: ReactNode;
  action?: ReactNode;
}

const EmptyPage: React.FC<EmptyPageProps> = ({ title, info, icon, action }) => {
  return (
    <div className="bg-dashboard-frame frame-effects py-8 px-6 md:!p-8 rounded-2xl flex justify-center items-center">
      <div className="text-center flex flex-col items-center text-white">
        <div className="relative">
          <YellowOrbit className="w-full h-full" />
          <span className="text-gold-500 [&>svg]:h-14 [&>svg]:w-14 absolute  top-[40%] lg:top-[116px] left-1/2 -translate-x-[45%]">
            {icon}
          </span>
        </div>
        <div className="-mt-[4rem] lg:-mt-[5.5rem] text-title-lg md:text-subheading-md ">
          {title}
        </div>
        <div className="text-body-lg md:text-label-md mt-2">{info}</div>
        {action && <div className="mt-4"> {action}</div>}
      </div>
    </div>
  );
};

export default EmptyPage;
