import * as React from "react";
import type { SVGProps } from "react";
const SvgDangerCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={22}
    viewBox="0 0 22 22"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M11 5.25a.75.75 0 0 1 .75.75v6a.75.75 0 0 1-1.5 0V6a.75.75 0 0 1 .75-.75M11 16a1 1 0 1 0 0-2 1 1 0 0 0 0 2"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M.25 11C.25 5.063 5.063.25 11 .25S21.75 5.063 21.75 11 16.937 21.75 11 21.75.25 16.937.25 11M11 1.75a9.25 9.25 0 1 0 0 18.5 9.25 9.25 0 0 0 0-18.5"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgDangerCircle;
