import React from "react";
import { useAccount } from "wagmi";
import { useRefereesDataQuery } from "@/queries/referees";
import { HexAddress } from "@/types/address";
import Table from "@/components/Common/Table";
import { useIsMobile } from "@/hooks/useIsMobile";
import MobileTable from "./components/MobileTable";
import EmptyPage from "../components/EmptyPage";
import { UsersGroupRounded } from "@/components/Icons";

const Referees: React.FC = () => {
  const { address } = useAccount();
  const { data: refereesData, isLoading } = useRefereesDataQuery(
    address as HexAddress
  );

  const isMobile = useIsMobile(1300);

  const columns = [
    { header: "Number", key: "number", width: "10%" },
    { header: "Name", key: "name", width: "50%" },
    { header: "Status", key: "status", width: "5%" },
  ];

  const data =
    refereesData && refereesData.length > 0
      ? refereesData.map((referee, index) => ({
          number: index + 1,
          name: referee,
          status: <div className="text-green-400">Active</div>,
        }))
      : [];
  return (
    <div id="tableHeadOptions" className="min-h-screen">
      {refereesData?.length === 0 && (
        <EmptyPage
          icon={<UsersGroupRounded />}
          title="No one has been invited yet"
          info="As soon as you invite your first friend, it will appear here"
        />
      )}
      {isMobile ? (
        refereesData ? (
          <div className="grid grid-cols-12">
            {data.map((item, index) => (
              <div className="col-span-12 desktop:col-span-3 py-4">
                <MobileTable data={item} key={index} />
              </div>
            ))}
          </div>
        ) : (
          <div className="flex flex-col gap-4">
            {Array.from({ length: 3 }).map((_, index) => (
              <MobileTable key={index} isLoading={true} />
            ))}
          </div>
        )
      ) : (refereesData && refereesData.length > 0) || isLoading ? (
        <Table columns={columns} data={data} isLoading={isLoading} />
      ) : null}
    </div>
  );
};

export default Referees;
