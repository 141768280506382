import React from "react";
import { ToastProps } from "./types";
import BaseModal from "../BaseModal";
import Button from "../Button";
import {
  ErrorStatus,
  InfoStatus,
  Orbit,
  SuccessStatus,
  Close,
} from "@/components/Icons";
const Toast: React.FC<ToastProps> = ({
  id,
  type,
  message,
  onClose,
  ctaLabel,
  onCtaClick,
}) => {
  const onClickHandler = () => {
    if (onCtaClick) {
      onCtaClick();
    } else {
      onClose(id, message);
    }
  };
  const iconClass =
    "absolute top-1/2 desktop:top-[116px] left-1/2 -translate-x-[45%]";
  return (
    <BaseModal
      footer={
        <Button
          className="w-full desktop:w-max"
          model="main"
          size="sm"
          onClick={onClickHandler}
        >
          {ctaLabel ?? "Got It"}
        </Button>
      }
    >
      {ctaLabel ? (
        <Button
          model="ghost"
          size="sm"
          className="px-2 absolute right-4 top-4"
          onClick={() => onClose(id, message)}
        >
          <Close />
        </Button>
      ) : null}
      <div className="relative my-12">
        <Orbit
          className={`${
            type === "danger"
              ? "text-orange-500"
              : type === "info"
              ? "text-gold-500"
              : "text-green-500"
          } mx-auto`}
        />
        {type === "danger" ? (
          <ErrorStatus className={iconClass} />
        ) : type === "info" ? (
          <InfoStatus className={iconClass} />
        ) : (
          <SuccessStatus className={iconClass} />
        )}
        <div className="-mt-20 text-center"> {message}</div>
      </div>
    </BaseModal>
  );
};
export default Toast;
