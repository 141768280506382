import * as React from "react";
import type { SVGProps } from "react";
const SvgAltArrowDown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={8}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M.43.512A.75.75 0 0 1 1.489.43L8 6.012 14.512.431a.75.75 0 0 1 .976 1.138l-7 6a.75.75 0 0 1-.976 0l-7-6A.75.75 0 0 1 .43.512"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgAltArrowDown;
