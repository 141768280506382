import React from "react";
import { BigArrowRight } from "@/components/Icons";
import exchangesData from "../../consts/exchangeData";
import stars from "@/scss/images/stars.png";

const Exchanges: React.FC = () => {
  return (
    <section className="text-center mt-[8rem] desktop:mt-[264px] relative">
      <img
        src={stars}
        alt="stars"
        className="absolute -top-[50%] h-[550px] -z-10"
      />
      <div className="container">
        <div data-aos="fade-up" data-aos-duration="800">
          <h3 className="text-label-md desktop:text-subheading-md text-blackshade-200 mb-3">
            Exchanges
          </h3>
          <h4 className="text-headline-md desktop:text-display-sm text-white">
            <span className="text-gradiant">PME</span> Is Now Listed
          </h4>
        </div>

        <div className="grid grid-cols-12 mt-10 desktop:mt-14 gap-8 desktop:gap-4 flex-wrap justify-center">
          {exchangesData.map((item, idx) => (
            <div
              key={idx}
              className="gradient-border-box rounded-3xl p-4 relative col-span-12 mt-3 desktop:mt-0 desktop:col-span-4"
            >
              <div className="bg-informative-frame-sm rounded-[20px] h-[148px] desktop:h-[160px] mb-4 flex items-center justify-center">
                <img
                  src={item.img}
                  alt=""
                  className="h-[54px] desktop:h-[67px]"
                />
              </div>
              <a
                href={item.link}
                target="_blank"
                rel="noopener noreferrer"
                className="mx-auto button ghost md"
              >
                Check Out
                <BigArrowRight />
              </a>
              <span className="bg-middle-linear absolute -bottom-6 h-[1px] w-full left-0"></span>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Exchanges;
