import React from "react";
import { ForwardedRef, forwardRef } from "react";

export interface ButtonProps<
  T =
    | "main"
    | "tonal"
    | "outlined"
    | "ghost"
    | "add"
    | "success"
    | "error"
    | "info"
> extends React.ButtonHTMLAttributes<HTMLButtonElement | HTMLAnchorElement> {
  model: T;
  href?: string;
  className?: string;
  isLoading?: boolean;
  size: "lg" | "md" | "sm";
}

const Button = forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonProps>(
  (
    { children, isLoading, model, className, disabled, size, ...props },
    ref
  ) => {
    const classes = `button ${className ?? ""} ${model} ${size} ${
      disabled || isLoading ? "pointer-events-none cursor-not-allowed" : ""
    }`;
    if (props.href)
      return (
        <a
          className={classes}
          {...props}
          ref={ref as ForwardedRef<HTMLAnchorElement>}
        >
          {children}
        </a>
      );

    return (
      <button
        className={classes}
        {...props}
        onClick={isLoading ? undefined : props.onClick}
        ref={ref as ForwardedRef<HTMLButtonElement>}
        disabled={disabled || isLoading}
      >
        {isLoading ? (
          <ul className="button__loading">
            <li></li>
            <li></li>
            <li></li>
          </ul>
        ) : (
          children
        )}
      </button>
    );
  }
);

export default Button;
