import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useAuth } from "@/context/authProvider";
import logo from "@/assets/images/logo/logorec.png";
import Navigation from "./components/Navigation";
import Button from "@/components/Common/Button";

const Header: React.FC = () => {
  const [scroll, setScroll] = useState<boolean>(false);
  const { isAuthenticated } = useAuth();
  const [menuActive, setMenuActive] = useState<boolean>(false);

  useEffect(() => {
    const handleScroll = () => {
      setScroll(window.scrollY > 300);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleMenuActive = () => {
    setMenuActive(!menuActive);
  };

  return (
    <header className="py-4 relative bg-blackshade-900 shadow-menu">
      <div className="bg-horizontal-linear h-[1px] absolute left-0 bottom-0 w-full" />
      <div className="container">
        <div className="flex items-center">
          <div className="w-14 h-14 mr-10">
            <NavLink to="/">
              <img src={logo} alt="PCO" />
            </NavLink>
          </div>
          <Navigation
            isAuthenticated={isAuthenticated}
            menuActive={menuActive}
            handleMenuActive={handleMenuActive}
          />
          <div className="ml-auto flex items-center gap-4">
            <div
              className={isAuthenticated ? "active-connect-btn" : "connect-btn"}
            >
              <ConnectButton chainStatus="none" />
            </div>
            <Button
              model="tonal"
              size="sm"
              className="desktop:hidden relative z-[22]"
              onClick={handleMenuActive}
            >
              <div
                className={`mobile-menu-button ${menuActive ? "active" : ""}`}
              >
                <span></span>
              </div>
            </Button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
