import * as React from "react";
import type { SVGProps } from "react";
const SvgInfoStatus = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={57}
    height={57}
    fill="none"
    {...props}
  >
    <g fill="#FFB70F">
      <path d="M28.486 15.068c.966 0 1.75.784 1.75 1.75v14a1.75 1.75 0 1 1-3.5 0v-14c0-.967.783-1.75 1.75-1.75M28.486 40.151a2.333 2.333 0 1 0 0-4.666 2.333 2.333 0 0 0 0 4.666" />
      <path
        fillRule="evenodd"
        d="M3.402 28.485C3.402 14.632 14.632 3.4 28.486 3.4s25.083 11.23 25.083 25.084-11.23 25.083-25.083 25.083S3.402 42.338 3.402 28.485M28.486 6.9c-11.92 0-21.584 9.664-21.584 21.584s9.664 21.583 21.584 21.583 21.583-9.663 21.583-21.583S40.406 6.9 28.486 6.9"
        clipRule="evenodd"
      />
    </g>
  </svg>
);
export default SvgInfoStatus;
