import React from "react";
import { termsAndConditions } from "./consts";
import { MultiShaped } from "@/components/Icons";
import termsImg from "@/scss/images/terms.png";
const TermsAndConditions = () => {
  return (
    <div className=" desktop:mt-20 relative overflow-x-hidden desktop:overflow-visible">
      <section className="container pt-8">
        <div className="flex">
          <div className="w-full desktop:max-w-[60%]">
            <article data-aos="fade-in" data-aos-duration="800">
              <div className="pr-[10rem] desktop:pr-14">
                <div className="text-headline-md desktop:text-display-md mb-2 desktop:mb-3 flex flex-wrap items-center">
                  <span className="text-gradiant mr-2">PCOLAND</span>
                  Terms & Conditions
                </div>
                <span className="text-label-lg desktop:text-subheading-md text-blackshade-300">
                  Effective Date: 24 SEP 2024
                </span>
              </div>
              <div className="mt-4 desktop:mt-10 flex flex-col gap-10">
                <div className="absolute bg-vertical-linear w-[1px] h-full -top-[13rem] -left-[2.75rem] bottom-0 hidden desktop:block"></div>

                {termsAndConditions.map((term,index) => (
                  <div
                    key={index}
                    className="bg-dashboard-frame rounded-3xl frame-effects p-4 desktop:p-6"
                    data-aos="zoom-in"
                    data-aos-duration="800"
                  >
                    <div className="absolute w-3 h-3 shadow-dot bg-gold-500 rounded-full -left-[20px] desktop:-left-[3.1rem] top-1/2 -translate-y-1/2" />
                    <h5 className="text-white text-title-lg desktop:text-headline-sm mb-3">
                      {term.title}
                    </h5>
                    <ul>
                      {term.items.map((item, index) => (
                        <li key={index} className="mt-3">
                          <div
                            className="text-blackshade-200 text-label-md desktop:text-title-md [&>h3]:mb-3 [&>h3]:text-title-sm desktop:[&>h3]:text-subheading-sm [&>h3]:text-white"
                            dangerouslySetInnerHTML={{ __html: item }}
                          ></div>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </article>
          </div>
          <div>
            <MultiShaped className="absolute top-0 desktop:!-top-[12rem] -right-[13rem] desktop:right-0 h-[360px] desktop:h-[980px] -z-10 desktop:z-10" />
            <img
              src={termsImg}
              alt=""
              className="absolute right-4 desktop:right-[130px] h-[110px] desktop:h-[250px] z-20"
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default TermsAndConditions;
