import React from "react";
import menus from "@/config/app-menu";
import { NavLink } from "react-router-dom";
import { useAuth } from "@/context/authProvider.js";
import { SidebarTick, Dots } from "@/components/Icons";
import avatar from "@/scss/images/Avatarpicture.png";

const SidebarNav: React.FC = () => {
  const { user } = useAuth();

  return (
    <div className="pb-20 desktop:pb-0">
      <div className="mx-auto flex flex-col justify-center items-center mt-10">
        <img src={avatar} alt="" />
        <Dots className="absolute -z-10 w-[187px] h-[191px] -top-[14px]" />
        <div className="text-subheading-md text-white mt-2">
          {user?.username}
        </div>
      </div>
      <div className="dashbpard-sidebar__menu">
        {menus.map((menu, i) => (
          <div key={i}>
            <NavLink
              to={menu.path as string}
              className="dashbpard-sidebar__menu--item"
            >
              {({ isActive }) => (
                <>
                  {isActive && <SidebarTick className="absolute left-0" />}
                  {menu.icon}
                  <div>{menu.title}</div>
                </>
              )}
            </NavLink>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SidebarNav;
