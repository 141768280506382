import * as React from "react";
import type { SVGProps } from "react";
const SvgTwitter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#FFB70F"
      d="M18.326 1.904H21.7l-7.37 8.424L23 21.79h-6.789l-5.317-6.952L4.81 21.79H1.434l7.883-9.01L1 1.904h6.961l4.806 6.355zm-1.184 17.867h1.87L6.945 3.817H4.94z"
    />
  </svg>
);
export default SvgTwitter;
