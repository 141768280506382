import * as React from "react";
import type { SVGProps } from "react";
const SvgCopy = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={22}
    viewBox="0 0 20 22"
    fill="none"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M4 18a3 3 0 0 1-3-3V9c0-3.771 0-5.657 1.172-6.828S5.229 1 9 1h4a3 3 0 0 1 3 3m-6 17h3c2.828 0 4.243 0 5.121-.879C19 19.243 19 17.828 19 15v-5c0-2.828 0-4.243-.879-5.121C17.243 4 15.828 4 13 4h-3c-2.828 0-4.243 0-5.121.879C4 5.757 4 7.172 4 10v5c0 2.828 0 4.243.879 5.121C5.757 21 7.172 21 10 21Z"
    />
  </svg>
);
export default SvgCopy;
