import { Navigate } from "react-router-dom";
import { useAuth } from "@/context/authProvider";
import Loading from "@/components/Common/Loading";

const ProtectedLayout = ({ children }) => {
  const { isAuthenticated, isFetchingUserData, isLoading } = useAuth();

  if (isFetchingUserData || isLoading) {
    return <Loading />;
  }
  return isAuthenticated && !isLoading ? children : <Navigate to="/" />;
};
export default ProtectedLayout;
