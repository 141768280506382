import React from "react";
import circleLine from "@/scss/images/circle-lined.png";

const Loading = () => {
  return (
    <div className="w-full bg-blackshade-900 shadow-frame rounded-lg py-3 text-center absolute top-20 left-1/2 -translate-x-1/2 right-6 z-10 max-w-[364px] desktop:w-[364px] overflow-hidden">
      <span className="text-label-lg text-blackshade-200 animate-text-pulse">
        Loading data on map
      </span>
      <img
        src={circleLine}
        alt=""
        className="absolute rotate-180 -right-[65%] top-1/2 animate-right-line-move"
      />
      <img
        src={circleLine}
        alt=""
        className="absolute -left-[65%] top-1/2 animate-left-line-move"
      />
    </div>
  );
};

export default Loading;
